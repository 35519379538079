import * as actionTypes from "./actionTypes";
import {
  getDigitalWarehouseAPI,
  deleteDigitalWarehouseItemAPI,
  createDigitalWarehouseItemAPI,
  modifyDigitalWarehouseItemAPI,
  downloadDigitalWarehouseItemApi,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";
import { downloadBlob } from "@icarius-utils/download";
import { getFileExtension, getFileName } from "@icarius-utils/fileUpload";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "DUPLICATED_DATA":
      errorString = 'Ya existe un archivo con el mismo nombre en esa fecha y carpeta/subcarpeta';
      break;
    case "IN_USE":
      errorString = 'No es posible eliminar el archivo porque está en uso';
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getDigitalWarehouseAction = (query) => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getDigitalWarehouseAPI(query);

    let data = response.data && response.data.data;
    let permissionList = response.data && response.data.permissionsList;
    let typeList = response.data && response.data.typeLists;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { data, permissionList, typeList },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteDigitalWarehouseItemAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteDigitalWarehouseItemAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Archivo eliminado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createDigitalWarehouseItemAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let formData = new FormData();
    formData.append('FILES', dataToSend.file);
    formData.append('type', dataToSend.type);
    formData.append('description', dataToSend.description);
    formData.append('groupCode', dataToSend.groupCode || "");
    formData.append('folder', dataToSend.folder);
    formData.append('sfolder', dataToSend.sfolder);
    formData.append('year', dataToSend.year);
    formData.append('month', dataToSend.month);
    formData.append('permissions', dataToSend.permission);

    let response = await createDigitalWarehouseItemAPI(formData);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Archivo creado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const modifyDigitalWarehouseItemAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let formData = new FormData();
    formData.append('FILES', dataToSend.file);
    formData.append('type', dataToSend.type);
    formData.append('description', dataToSend.description);
    formData.append('groupCode', dataToSend.groupCode || "");
    formData.append('folder', dataToSend.folder);
    formData.append('sfolder', dataToSend.sfolder);
    formData.append('year', dataToSend.year);
    formData.append('month', dataToSend.month);
    formData.append('permissions', dataToSend.permission);
    formData.append('internalCode', dataToSend.internalCode);

    let response = await modifyDigitalWarehouseItemAPI(formData);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Archivo actualizado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const downloadDigitalWarehouseItemAction = (code) => async (dispatch) => {
  dispatch({ type: actionTypes.DOWNLOAD });
  try {
    let response = await downloadDigitalWarehouseItemApi(code);
    let title = response.headers["x-content-namefile"];

    if (response.status !== 200) {
      dispatch({ type: actionTypes.DOWNLOAD_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DOWNLOAD_FULFILLED,
    });

    downloadBlob(response.data, getFileExtension(title), getFileName(title));

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DOWNLOAD_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};
