import React from "react";
import { Chart } from "react-chartjs-2";
import { meritoDemerito, randomColors } from "@icarius-utils/colors";

class VerticalBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidUpdate() {
    this.myChart.update();
  }

  componentDidMount() {
    let informacion = {
      labels: Object.keys(this.props.data.data),
      datasets: [
        {
          pointHoverRadius: 5,
          pointRadius: 3,
          fontColor: "#00FF00",
          pointHitRadius: 10,
          data: Object.values(this.props.data.data),
          borderColor:
            this.props.data.type === "type" ? meritoDemerito.strokes : randomColors.strokes,
          backgroundColor:
            this.props.data.type === "type" ? meritoDemerito.fills : randomColors.fills,
          borderWidth: 1,
        },
      ],
    };

    const options = {
      responsive: true,
      title: {
        display: true,
        fontColor: this.props.theme === "dark" ? "#FFF" : "black",
        fontSize: 12,
        text: this.props.data.title,
      },
      maintainAspectRatio: false,
      legend: {
        display: false,
        position: "bottom",
        labels: {
          fontColor: this.props.theme === "dark" ? "#FFF" : "black",
          fontSize: 12,
        },
      },
      layout: {
        padding: {
          bottom: 0,
          top: 0,
        },
      },
      tooltips: {
        displayColors: false,
        mode: "single",
        callbacks: {
          label: function(tooltipItem, data) {
            return `${data.labels[tooltipItem.index]}: ${data.datasets[0].data[tooltipItem.index]}`;
          },
        },
      },
    };

    this.myChart = new Chart(this.canvasRef.current, {
      type: this.props.type,
      options: options,
      data: informacion,
    });
  }

  render() {
    return (
      <div
        className={"container h-100 sm:h-100 relative flex flex-col justify-center items-center"}>
        <div className="principal chart-wrapper">
          <div
            style={{ height: 300 }}
            className="container  h-100 sm:h-100  justify-between items-center">
            <canvas ref={this.canvasRef} />
          </div>
        </div>
      </div>
    );
  }
}

export default VerticalBarChart;
