import * as actionTypes from "./actionTypes";

const initialState = {
  rows: [],
  dateFormat: "yyyy/mm/dd",
  isLoading: false,
  validationData: null,
  calendarList: null,
  employees: [],
  exitTypes: [],
  templates: [],
  thirdPartyFiles: [],
  groups: [],
  approvationParameters: [],
  automaticProcessesEnabled: true,
  accountingEntriesData: null,
  isLoadingAccountingEntries: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CLEAR_STATE:
      return initialState;

    case actionTypes.GET_ROWS:
      return { ...state, isLoading: true };
    case actionTypes.GET_ROWS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        rows: action.payload.rows,
        exitTypes: action.payload.exitTypes,
        dateFormat: action.payload.dateFormat,
        validationData: action.payload.validationData,
        calendarList: action.payload.calendarList,
        templates: action.payload.templates,
        thirdPartyFiles: action.payload.thirdPartyFiles,
        groups: action.payload.groups,
        automaticProcessesEnabled: action.payload.automaticProcessesEnabled,
        approvationParameters: action.payload.approvationParameters,
      };
    case actionTypes.GET_ROWS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_EMPLOYEES:
      return { ...state, isLoading: true };
    case actionTypes.GET_EMPLOYEES_FULFILLED:
      return {
        ...state,
        isLoading: false,
        employees: action.payload.employees,
      };
    case actionTypes.GET_EMPLOYEES_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.CREATE_PROCESS:
      return { ...state, isLoading: true };
    case actionTypes.CREATE_PROCESS_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.CREATE_PROCESS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.MODIFY_PROCESS:
      return { ...state, isLoading: true };
    case actionTypes.MODIFY_PROCESS_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.MODIFY_PROCESS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DELETE_PROCESS:
      return { ...state, isLoading: true };
    case actionTypes.DELETE_PROCESS_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.DELETE_PROCESS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.CALC_PROCESS_TRACEABILITY:
      return { ...state, isLoading: true };
    case actionTypes.CALC_PROCESS_TRACEABILITY_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.CALC_PROCESS_TRACEABILITY_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GENERATE:
      return { ...state, isLoading: true };
    case actionTypes.GENERATE_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.GENERATE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.EXECUTE:
      return { ...state, isLoading: true };
    case actionTypes.EXECUTE_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.EXECUTE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_ACCOUNTING_ENTRIES_ROWS:
      return { ...state, isLoadingAccountingEntries: true };
    case actionTypes.GET_ACCOUNTING_ENTRIES_ROWS_FULFILLED:
      return { ...state, isLoadingAccountingEntries: false, accountingEntriesData: action.payload.data };
    case actionTypes.GET_ACCOUNTING_ENTRIES_ROWS_REJECTED:
      return { ...state, isLoadingAccountingEntries: false };

    case actionTypes.DOWNLOAD:
      return { ...state, isLoading: true };
    case actionTypes.DOWNLOAD_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.DOWNLOAD_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.APPROVE_PROCESS:
      return { ...state, isLoading: true };
    case actionTypes.APPROVE_PROCESS_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.APPROVE_PROCESS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.CLEAR_EMPLOYEES:
      return { ...state, employees: [] };

    default:
      return state;
  }
}
