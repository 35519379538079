import paths from "@icarius-localization/paths";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";

export default [
    {
        pages: [paths.digitalWarehouse],
        config: {
            headerName: 'Grupo',
            field: "groupString",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.digitalWarehouse],
        config: {
            headerName: 'Carpeta',
            field: "folder",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.digitalWarehouse],
        config: {
            headerName: 'Subcarpeta',
            field: "sfolder",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.digitalWarehouse],
        config: {
            headerName: 'Año',
            field: "year",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.digitalWarehouse],
        config: {
            headerName: 'Mes',
            field: "monthString",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.digitalWarehouse],
        config: {
            headerName: 'Tipo de archivo',
            field: "typeString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.digitalWarehouse],
        config: {
            headerName: 'Archivo',
            field: "file",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.digitalWarehouse],
        config: {
            headerName: 'Descripción',
            field: "description",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.digitalWarehouse],
        config: {
            headerName: 'Usuario de carga',
            field: "uploader",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.digitalWarehouse],
        config: {
            headerName: 'Fecha de carga',
            field: "dateUpload",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: [paths.digitalWarehouse],
        config: {
            headerName: 'Hora de carga',
            field: "timeUpload",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.digitalWarehouse],
        config: {
            headerName: 'Permisos miembros del grupo',
            field: "permissionsString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.myDigitalWarehouse],
        config: {
            headerName: 'Carpeta',
            field: "folder",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.myDigitalWarehouse],
        config: {
            headerName: 'Subcarpeta',
            field: "sfolder",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.myDigitalWarehouse],
        config: {
            headerName: 'Año',
            field: "year",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.myDigitalWarehouse],
        config: {
            headerName: 'Mes',
            field: "monthString",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.myDigitalWarehouse],
        config: {
            headerName: 'Tipo de archivo',
            field: "typeString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.myDigitalWarehouse],
        config: {
            headerName: 'Archivo',
            field: "file",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.myDigitalWarehouse],
        config: {
            headerName: 'Descripción',
            field: "description",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.myDigitalWarehouse],
        config: {
            headerName: 'Fecha de carga',
            field: "dateUpload",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: [paths.myDigitalWarehouse],
        config: {
            headerName: 'Hora de carga',
            field: "timeUpload",
            filter: "agTextColumnFilter",
        }
    },
]