import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { createProcessAction, modifyProcessAction } from "../../actions";
import ProcessForm from "./processForm";
import { getLocalizedString } from "@icarius-localization/strings";
import ConfirmationDialog from "../dialogs/confirmationDialog";
import CommonPage from "@icarius-common/commonPage";
import paths from "@icarius-localization/paths";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";

const ProcessFormContainer = (props) => {

  const { isLoading, data, handleClose } = props;

  const [confirmEditDialogIsOpen, setConfirmEditDialogIsOpen] = useState(false);
  const [dataToModify, setDataToModify] = useState(null); //para los modales de confirmacion

  const dispatch = useDispatch();

  const isEdit = Boolean(data);

  const createProcess = useCallback((dataToSend) => {
    setConfirmEditDialogIsOpen(false);
    dispatch(createProcessAction(dataToSend))
      .then((resp) => {
        if (resp.status === 200) {
          handleClose(true)
        }
      });
  }, [dispatch, handleClose])

  const modifyProcess = useCallback((dataToSend) => {
    setConfirmEditDialogIsOpen(false);
    dispatch(modifyProcessAction(dataToSend))
      .then((resp) => {
        if (resp.status === 200) {
          handleClose(true)
        }
      });
  }, [dispatch, handleClose])

  const handleModifyProcess = useCallback((dataToSend) => {
    if (data["id_estado"] === "P") {
      setDataToModify(dataToSend);
      setConfirmEditDialogIsOpen(true);
    } else {
      modifyProcess(dataToSend);
    }
  }, [data, modifyProcess])

  const handleCloseConfirmModify = () => {
    setConfirmEditDialogIsOpen(false);
    setDataToModify(null);
  }

  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={() => handleClose(false)}
      type={"goBack"}
    />

  return (
    <CommonPage
      columnDefPage={paths.currentProcesses}
      title={`${getLocalizedString(isEdit ? "processEdit" : "processNew")} ${isEdit ? `- ${data["Descripción"]}` : ""}`}
      menuItems={[goBackItem]}
      isLoading={isLoading}
      isNotGridPage
    >
      <ProcessForm
        data={data}
        handleClose={handleClose}
        createProcess={createProcess}
        modifyProcess={handleModifyProcess}
      />
      <ConfirmationDialog //confirm modify
        open={confirmEditDialogIsOpen}
        handleClose={handleCloseConfirmModify}
        handleConfirm={() => modifyProcess(dataToModify)}
        title={getLocalizedString("atention")}
        text={getLocalizedString("processModifyConfirm")}
      />
    </CommonPage>
  )
}
export default ProcessFormContainer;
