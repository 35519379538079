import paths from "@icarius-localization/paths";

export default [
    {
        pages: [paths.digitalFolderSettings],
        config: {
            headerName: 'Código del cargo',
            field: "code",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.digitalFolderSettings],
        config: {
            headerName: 'Nombre del cargo',
            field: "valueCargo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.digitalFolderSettings],
        config: {
            headerName: 'Instancia',
            field: "valueInstanciaLaboral",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.digitalFolderSettings],
        config: {
            headerName: 'Documento obligatorio',
            field: "valueTipoDocumento",
            filter: "agSetColumnFilter",
        }
    },
]