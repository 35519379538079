import * as actionTypes from "./actionTypes";
import {
  getConsentTypessAPI,
  deleteConsentTypeAPI,
  createConsentTypeAPI,
  updateConsentTypeAPI,
  uploadConsentTypeAPI,
  downloadConsentTypeApi,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";
import { downloadBlob } from "@icarius-utils/download";
import { getFileExtension, getFileName } from "@icarius-utils/fileUpload";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "DUPLICATED_DATA":
      errorString = 'No puede existir más de un tipo de consentimiento que comparta el mismo código o nombre';
      break;
    case "DUPLICATED_CODE":
      errorString = 'No puede existir más de un tipo de consentimiento que comparta el mismo código o nombre';
      break;
    case "IN_USE":
      errorString = 'No es posible eliminar el tipo de consentimiento porque está en uso';
      break;
    case "IN_USE_ONBOARDING":
      errorString = 'El consentimiento está en uso en los materiales y recursos de Onboarding, no puede ser eliminado';
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getConsentTypessAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getConsentTypessAPI();

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;
    let instanceList = response.data && response.data.instanceList;
    let typeList = response.data && response.data.typeList;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { data, instanceList, typeList },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteConsentTypeAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteConsentTypeAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Tipo de consentimiento eliminado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createConsentTypeAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let formData = new FormData();
    formData.append('FILE', dataToSend.template);
    formData.append('code', dataToSend.code);
    formData.append('name', dataToSend.name);
    formData.append('text', dataToSend.text);
    formData.append('link', dataToSend.link);
    formData.append('image', dataToSend.image);
    formData.append('enableAlert', dataToSend.enableAlert);
    formData.append('instance', dataToSend.instance);
    formData.append('type', dataToSend.type);

    let response = await createConsentTypeAPI(formData);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Tipo de consentimiento creado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};

export const updateConsentTypeAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let formData = new FormData();
    formData.append('FILE', dataToSend.template);
    formData.append('code', dataToSend.code);
    formData.append('name', dataToSend.name);
    formData.append('text', dataToSend.text);
    formData.append('link', dataToSend.link);
    formData.append('image', dataToSend.image);
    formData.append('enableAlert', dataToSend.enableAlert);
    formData.append('instance', dataToSend.instance);
    formData.append('type', dataToSend.type);
    formData.append('internalCode', dataToSend.internalCode);
    
    let response = await updateConsentTypeAPI(formData);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Tipo de consentimiento actualizado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};

export const uploadConsentTypeAction = (file, code) => async (dispatch) => {
  dispatch({ type: actionTypes.UPLOAD_DOCUMENT });
  try {
    let formData = new FormData();
    formData.append('FILE', file);
    formData.append('code', code);

    let response = await uploadConsentTypeAPI(formData);
    let data = response.data && response.data.data;
    let status = response?.data?.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.UPLOAD_DOCUMENT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPLOAD_DOCUMENT_FULFILLED,
      payload: { data }
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPLOAD_DOCUMENT_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const downloadConsentTypeAction = (code) => async (dispatch) => {
  dispatch({ type: actionTypes.DOWNLOAD_DOCUMENT });
  try {
    let response = await downloadConsentTypeApi(code);
    let title = response.headers["x-content-namefile"];

    if (response.status !== 200) {
      dispatch({ type: actionTypes.DOWNLOAD_DOCUMENT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DOWNLOAD_DOCUMENT_FULFILLED,
    });

    downloadBlob(response.data, getFileExtension(title), getFileName(title));

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DOWNLOAD_DOCUMENT_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};
