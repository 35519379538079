import React, { useEffect, useMemo, useRef, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { CloseIcon } from "@icarius-icons";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import TabMenu from "@icarius-common/tabMenu";
import CommonPage from "@icarius-common/commonPage";
import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  Grid,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";

const StyledDialog = withStyles({
  paper: {
    minWidth: "90%",
  },
})(Dialog);

const PAGES = [
  'accountingEntriesAnalytics',
  'accountingEntriesCollaborator',
  'accountingEntriesToExport',
  'accountingEntriesAnalyticsByCenter',
  'accountingEntriesAnalyticsByProject',
  'accountingEntriesAnalyticsByConcept'
]

const ViewButton = ({ title, onClick, isSelected }) => {
  const color = useSelector(getAppColor);

  return (
    <Button
      style={{
        background: isSelected ? color : '',
        height: 36,
        marginRight: 5,
      }}
      variant="contained"
      className='whiteText'
      onClick={onClick}
    >
      {title}
    </Button>
  )
}

const AccountingEntriesDialog = (props) => {

  const {
    open,
    data,
    handleClose,
  } = props;

  const ownGridRef = useRef();
  const [optionToShow, setOptionToShow] = useState(0);

  useEffect(() => {
    if (ownGridRef.current) {
      ownGridRef.current.columnApi.applyColumnState({
        state: [{ colId: [0, 2, 3, 4, 5].includes(optionToShow) ? 'Crédito' : 'Apellidos y nombres', sort: 'asc' }],
        defaultState: { sort: null },
      });
    }
  }, [optionToShow])

  const handleChangeAnalyticsView = (number) => {
    setOptionToShow((prev) => prev === number ? 0 : number);
  }

  const viewButton1 = () => (
    <ViewButton
      title={'Por cuenta y centro de costo'}
      isSelected={optionToShow === 3}
      onClick={() => handleChangeAnalyticsView(3)}
    />
  )

  const viewButton2 = () => (
    <ViewButton
      title={'Por cuenta y proyecto'}
      isSelected={optionToShow === 4}
      onClick={() => handleChangeAnalyticsView(4)}
    />
  )

  const viewButton3 = () => (
    <ViewButton
      title={'Por cuenta y concepto'}
      isSelected={optionToShow === 5}
      onClick={() => handleChangeAnalyticsView(5)}
    />
  )

  const customItems = [0, 3, 4, 5].includes(optionToShow) ? [viewButton1, viewButton2, viewButton3] : null;

  const rowData = useMemo(() => {
    if (optionToShow === 1) return data.collaborators;
    if (optionToShow === 2) return data.toExport;
    return data.analytics;
  }, [optionToShow, data])

  return (
    <StyledDialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth={true}
      maxWidth={"md"}>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogTitle style={{ cursor: 'move', paddingBottom: 0 }} id="draggable-dialog-title">
        {'Asientos contables'}
      </DialogTitle>
      <DialogContent>
        <Grid container direction="row" alignItems="center" style={{ paddingBottom: 10 }}>
          <Grid container direction="row" alignItems="center" style={{ paddingBottom: 10 }}>
            <TabMenu
              options={[
                'Asiento contable analítico',
                'Cuadratura por colaborador',
                'Asiento contable para exportar',
              ]}
              optionSelected={optionToShow}
              onClick={(value) => setOptionToShow(value)}
            />
            <CommonPage
              key={optionToShow} // para que no se buguee con las columnas que se repiten entre las distintas columnDef
              ownGridRef={ownGridRef}
              gridTitle={'Asientos contables'}
              columnDefPage={PAGES[optionToShow]}
              rowData={rowData}
              menuItems={customItems}
              gridHeight={"calc(100vh - 245px)"}
              ignoreProcessesbyPath={PAGES[optionToShow] !== 'accountingEntriesToExport'}
              pathToUseForProcess={PAGES[optionToShow]}
              codeFieldName={'Código de proceso'}
              hasSelectAll
            />
          </Grid>
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
}

export default AccountingEntriesDialog;