import * as actionTypes from "./actionTypes";

const initialState = {
  loadingSet: false,
  loadingRows: false,
  loadingDelete: false,
  loadingManagersEmployees: false,
  delegationsRows: [],
  managersEmployers: [],
  allEmployees: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_DELEGATION_ROWS:
      return { ...state, loadingRows: true };
    case actionTypes.GET_DELEGATION_ROWS_FULFILLED:
      return { ...state, loadingRows: false, delegationsRows: action.payload.delegations };
    case actionTypes.GET_DELEGATION_ROWS_REJECTED:
      return { ...state, loadingRows: false };

    case actionTypes.DELETE_REQUEST:
      return { ...state, loadingDelete: true };
    case actionTypes.DELETE_REQUEST_FULFILLED:
      const arrResultante = state.delegationsRows.filter(item => item.code !== action.payload);
      return { ...state, loadingDelete: false, delegationsRows: arrResultante };
    case actionTypes.DELETE_REQUEST_REJECTED:
      return { ...state, loadingDelete: false };

    case actionTypes.GET_MANAGERS_EMPLOYERS:
      return { ...state, loadingManagersEmployees: true };
    case actionTypes.GET_MANAGERS_EMPLOYERS_FULFILLED:
      return { ...state, loadingManagersEmployees: false, managersEmployers: action.payload.managersEmployers, allEmployees: action.payload.allEmployees };
    case actionTypes.GET_MANAGERS_EMPLOYERS_REJECTED:
      return { ...state, loadingManagersEmployees: false };

    case actionTypes.SET_DELEGATION:
      return { ...state, loadingSet: true };
    case actionTypes.SET_DELEGATION_FULFILLED:
      let newArray = [...state.delegationsRows];
      newArray.push(action.payload);
      return { ...state, loadingSet: false, delegationsRows: newArray };
    case actionTypes.SET_DELEGATION_REJECTED:
      return { ...state, loadingSet: false };
    default:
      return state;
  }
}
