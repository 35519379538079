import { useState } from "react";
import * as yup from 'yup';

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback, invalidFileCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === 'instance') {
        return isCreate ? "-" : data[fieldName];
      }

      if (fieldName === 'template') return [];

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      'code',
      'name',
      'text',
      'link',
      'image',
      'enableAlert',
      'instance',
      'type',
      'template',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(() => createInitialData());

  const dataIsValid = async () => {

    const codeRegex = /^[A-Z]+[A-Z-]*$/;

    let schema = yup.object().shape({
      code: yup.string().matches(codeRegex).required(),
      name: yup.string().required(),
      text: yup.string().required(),
      link: yup.string(),
      image: yup.string(),
      enableAlert: yup.string().required(),
      instance: yup.string().required(),
      type: yup.string()
        .when('instance', {
          is: (value) => value !== '-',
          then: yup.string().required()
        }),
    });

    return await schema.validate(formData, { abortEarly: false })
      .then((castData) => castData)
      .catch(err => {
        if (err instanceof yup.ValidationError) {
          err.inner.forEach(error => {
            console.log(error.message);
          });
          return false;
        }
      });
  }

  const fileIsValid = () => {
    if (data?.template) return true; // si ya tenia archivo, tomarlo valido
    if (formData.instance && formData.instance !== '-' && (!formData.template || !formData.template.length)) return false;
    return true;
  }

  const submit = async () => {
    if (await dataIsValid()) {
      if (fileIsValid()) {
        const dataToSend = {
          code: formData.code,
          name: formData.name,
          text: formData.text,
          link: formData.link,
          image: formData.image,
          enableAlert: formData.enableAlert,
          instance: formData.instance,
          type: formData.type,
          template: formData.template?.length ? formData.template[0] : null,
        }

        isCreate ? createCallback(dataToSend) : modifyCallback(dataToSend);
        return true;
      } else {
        invalidFileCallback();
        return false;
      }
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const formatValue = (value, fieldName) => {
    if (fieldName === "code") {
      return value.length > 0 ? value.replace(/\s/g, "").toUpperCase() : "";
    }

    return value;
  }

  const setFormValue = (value, fieldName) => {
    if (fieldName === 'instance') {
      setFormData({
        ...formData,
        [fieldName]: formatValue(value, fieldName),
        type: '',
      });
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: formatValue(value, fieldName)
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
