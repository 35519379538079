import * as actionTypes from "./actionTypes";

const initialState = {
  loading: false,
  genericFiles: [],
  cargos: [],
  instanciasLaborales: [],
  tipos: [],
  rowsByPosition: [],
  colaboratorFiles: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_GENERIC:
    case actionTypes.GET_BY_POSITION:
    case actionTypes.GET_COLABORATOR_FILES:
    case actionTypes.UPDATE_COLABORATOR_FILES:
    case actionTypes.UPDATE_GENERIC:
    case actionTypes.UPDATE_FOR_POSITION:
    case actionTypes.ADD_FOR_POSITION:
    case actionTypes.DELETE_FOR_POSITION:
      return { ...state, loading: true };

    case actionTypes.GET_GENERIC_FULFILLED:
    case actionTypes.UPDATE_GENERIC_FULFILLED:
      return {
        ...state,
        loading: false,
        genericFiles: action.payload,
      };

    case actionTypes.GET_GENERIC_REJECTED:
    case actionTypes.GET_BY_POSITION_REJECTED:
    case actionTypes.GET_COLABORATOR_FILES_REJECTED:
    case actionTypes.UPDATE_GENERIC_REJECTED:
    case actionTypes.UPDATE_COLABORATOR_FILES_REJECTED:
    case actionTypes.UPDATE_FOR_POSITION_REJECTED:
    case actionTypes.ADD_FOR_POSITION_REJECTED:
    case actionTypes.DELETE_FOR_POSITION_REJECTED:
      return { ...state, loading: false };

    case actionTypes.GET_BY_POSITION_FULFILLED:
      const { cargos, instanciasLaborales, tipos, rowsByPosition } = action.payload;
      return { ...state, loading: false, cargos, instanciasLaborales, tipos, rowsByPosition };

    case actionTypes.GET_COLABORATOR_FILES_FULFILLED:
    case actionTypes.UPDATE_COLABORATOR_FILES_FULFILLED:
      return {
        ...state,
        loading: false,
        colaboratorFiles: action.payload,
      };

    case actionTypes.UPDATE_FOR_POSITION_FULFILLED:
    case actionTypes.ADD_FOR_POSITION_FULFILLED:
    case actionTypes.DELETE_FOR_POSITION_FULFILLED:
      return {
        ...state,
        loading: false,
        rowsByPosition: action.payload,
      };

    default:
      return state;
  }
}
