import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getCurrentProcessesAction, deleteProcessAction } from "../../actions";

const useCurrentProcessesCRUD = () => {

  const dispatch = useDispatch();

  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const [createEditDialogIsOpen, setCreateEditDialogIsOpen] = useState(false);
  const [codesToDelete, setCodesToDelete] = useState(null);
  const [selectedProcess, setSelectedProcess] = useState(null);

  const handleOpenCreateDialog = () => {
    setCreateEditDialogIsOpen(true);
  }

  const handleOpenEditDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedOneRow"), severity: "warning" }));
      return;
    }

    setCreateEditDialogIsOpen(true);
    setSelectedProcess(selectedRows[0]);
  };

  const handleCloseEditDialog = useCallback((refetch) => {
    setCreateEditDialogIsOpen(false);
    setSelectedProcess(null);
    refetch && dispatch(getCurrentProcessesAction());
  }, [dispatch])

  const handleOpenDeleteDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }

    if (selectedRows.some(process => process.existsDocsSigned)) {
      dispatch(openSnackbarAction({
        msg: "El proceso de cálculo no puede eliminarse porque ya se han generados documentos con firma digital para dicho proceso",
        severity: "warning"
      }));
      return;
    }

    setDeleteDialogIsOpen(true);
    setCodesToDelete(selectedRows.map(item => item["Código de proceso"]));
  }

  const handleCloseDeleteDialog = () => {
    setDeleteDialogIsOpen(false);
    setCodesToDelete(null);
  }

  const handleDelete = () => {
    dispatch(deleteProcessAction({ codes: codesToDelete }))
      .then(() => {
        handleCloseDeleteDialog();
        dispatch(getCurrentProcessesAction());
      })
  }

  const crudData = {
    deleteDialogIsOpen,
    createEditDialogIsOpen,
    selectedProcess,
  }

  const crudFunctions = {
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleCloseEditDialog,
    handleOpenDeleteDialog,
    handleCloseDeleteDialog,
    handleDelete,
  }

  return {
    crudData,
    crudFunctions,
  }
}

export default useCurrentProcessesCRUD;
