import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";
import {
	numberComparatorMax2,
	numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.consentPublication],
        config: {
            headerName: "Código de empleado",
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.consentPublication],
        config:
        {
            headerName: "Apellidos y nombres",
            field: "Apellidos y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.consentPublication],
        config:
        {
            headerName: "Tipo de consentimiento",
            field: "Tipo de consentimiento string",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.consentPublication],
        config: {
            headerName: 'Fecha de publicación',
            field: "Fecha de publicación",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.consentPublication],
        config: {
            headerName: 'Días transcurridos sin recepcionar',
            field: "Días transcurridos sin recepcionar",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.consentPublication],
        config:
        {
            headerName: "Consentimiento recepcionado",
            field: "Consentimiento recepcionado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.consentPublication],
        config: {
            headerName: 'Fecha de recepción',
            field: "Fecha de recepción",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.consentPublication],
        config:
        {
            headerName: "Referencia de publicación",
            field: "Referencia de publicación",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.consentPublication],
        config: {
            headerName: 'Fecha de contratación',
            field: "Fecha de contratación",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.consentPublication],
        config: {
            headerName: 'Email',
            field: "Email",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.consentPublication],
        config: {
            headerName: 'Celular',
            field: "Celular",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.consentPublication],
        config: {
            headerName: 'Personal de planta',
            field: "Personal de planta",
            filter: "agSetColumnFilter",
        }
    },
    {

        pages: [paths.consentPublication],
        config: {
            headerName: 'Cargo',
            field: "Cargo",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.consentPublication],
        config: {
            headerName: getLocalizedString("fType"),
            field: "Tipo de funcionario",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.consentPublication],
        config: {
            headerName: "Lugar de trabajo",
            field: "Lugar de trabajo",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.consentPublication],
        config: {
            headerName: 'Obra o Faena',
            field: "Obra o Faena",
            filter: "agSetColumnFilter",
        }
    },
]