import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";

export default [
    {
        pages: [paths.digitalFolderAnalysis],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "CODIGO DE EMPLEADO",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.digitalFolderAnalysis],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "APELLIDO Y NOMBRE",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.digitalFolderAnalysis],
        config:
        {
            headerName: getLocalizedString("state"),
            field: "ESTADO",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.digitalFolderAnalysis],
        config: {
            headerName: getLocalizedString("plantPersonnel"),
            field: "PERSONAL DE PLANTA",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalFolderAnalysis],
        config: {
            headerName: getLocalizedString("incomeDate"),
            field: "FECHA DE INGRESO",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.digitalFolderAnalysis],
        config: {
            headerName: getLocalizedString("outcomeDate"),
            field: "FECHA DE EGRESO",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.digitalFolderAnalysis],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "TIPO DE CONTRATO",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalFolderAnalysis],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "LUGAR DE TRABAJO",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalFolderAnalysis],
        config: {
            headerName: getLocalizedString("position"),
            field: "CARGO",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalFolderAnalysis],
        config: {
            headerName: getLocalizedString("digitalFolderFechadecarga"),
            field: "FECHA DE CARGA",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.digitalFolderAnalysis],
        config: {
            headerName: getLocalizedString("digitalFolderHoradecarga"),
            field: "HORA DE CARGA",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.digitalFolderAnalysis],
        config: {
            headerName: getLocalizedString("digitalFolderInstancia"),
            field: "INSTANCIA",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalFolderAnalysis],
        config: {
            headerName: getLocalizedString("digitalFolderTipodedocumentooarchivo"),
            field: "TIPO DE DOCUMENTO O ARCHIVO",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalFolderAnalysis],
        config: {
            headerName: getLocalizedString("digitalFolderTextodescriptivo"),
            field: "TEXTO DESCRIPTIVO",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.digitalFolderAnalysis],
        config: {
            headerName: getLocalizedString("digitalFolderNombredelarchivo"),
            field: "NOMBRE DEL ARCHIVO",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.digitalFolderAnalysis],
        config: {
            headerName: getLocalizedString("digitalFolderFechadevencimiento"),
            field: "FECHA DE VENCIMIENTO",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.digitalFolderAnalysis],
        config: {
            headerName: getLocalizedString("digitalFolderResponsabledelacarga"),
            field: "RESPONSABLE DE LA CARGA",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalFolderAnalysis],
        config: {
            headerName: getLocalizedString("digitalFolderSituacion"),
            field: "SITUACION",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalFolderAnalysisNotAvailable],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "CODIGO DE EMPLEADO",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.digitalFolderAnalysisNotAvailable],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "APELLIDO Y NOMBRE",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.digitalFolderAnalysisNotAvailable],
        config:
        {
            headerName: getLocalizedString("state"),
            field: "ESTADO",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.digitalFolderAnalysisNotAvailable],
        config: {
            headerName: getLocalizedString("plantPersonnel"),
            field: "PERSONAL DE PLANTA",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalFolderAnalysisNotAvailable],
        config: {
            headerName: getLocalizedString("incomeDate"),
            field: "FECHA DE INGRESO",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.digitalFolderAnalysisNotAvailable],
        config: {
            headerName: getLocalizedString("outcomeDate"),
            field: "FECHA DE EGRESO",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.digitalFolderAnalysisNotAvailable],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "TIPO DE CONTRATO",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalFolderAnalysisNotAvailable],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "LUGAR DE TRABAJO",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalFolderAnalysisNotAvailable],
        config: {
            headerName: getLocalizedString("position"),
            field: "CARGO",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalFolderAnalysisNotAvailable],
        config: {
            headerName: getLocalizedString("digitalFolderInstancia"),
            field: "INSTANCIA",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalFolderAnalysisNotAvailable],
        config: {
            headerName: getLocalizedString("digitalFolderTipodedocumentooarchivoFaltante"),
            field: "TIPO DE DOCUMENTO O ARCHIVO",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
]