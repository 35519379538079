import { useState } from "react";
import { getTomorrow, getNextDay, dateIsAfterToday, formatDate } from "@icarius-utils/date";

const useHandleForm = (managersEmployers, endDateIsValid, createCallback, invalidDataCallback) => {

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if(fieldName === "date"){
        return getTomorrow();
      }
      if(fieldName === "endDate"){
        return getNextDay(getTomorrow());
      }
      return "";
    }

    const fieldNames = ["date", "endDate", "manager"];
    
    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName),
      })      
    }, {});
  }

  const [ formData, setFormData ] = useState(createInitialData());

  const dataIsValid = () => {
    const { manager, date } = formData;
    if (manager && endDateIsValid(formData) && dateIsAfterToday(date)) {
      return true;
    }
    return false;
  }

  const submit = () => {
    if(dataIsValid()){
      const request = {
        delegation_code: formData.manager,
        delegation_email: managersEmployers.find(item => item.code === formData.manager).email,
        start_date: formatDate(formData.date),
        end_date: formatDate(formData.endDate),
      }
      createCallback(request);
      return true;
    }else{
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { formData, setFormValue, submit };
}

export default useHandleForm;
