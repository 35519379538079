import React from "react";
import { TextField, Grid } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { makeStyles } from "@material-ui/core/styles";

const gridStyle = { height: 75, padding: "0px 10px" };

const useStyles = makeStyles({
  disabled: {
    color: "var(--mainText)",
  }
});

const DisabledTextfield = ({ value, label }) => {
  const classes = useStyles();
  return (
    <TextField
      disabled
      fullWidth
      label={label}
      value={value}
      InputProps={{ classes, disableUnderline: true }}
    />
  )
}

const Details = (props) => {

  const { data, empOrigin, empDest } = props;

  return (
    <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
      <Grid container item xs={12}>
        {
          empOrigin &&
          <Grid container item alignItems="center" xs={12} sm={12} md={4} style={gridStyle}>
            <DisabledTextfield label={getLocalizedString("delegatorName")} value={empOrigin.name} />
          </Grid>
        }
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
          <DisabledTextfield label={getLocalizedString("delegatorCode")} value={data.code_emp} />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
          <DisabledTextfield label={getLocalizedString("delegatorEmail")} value={data.email} />
        </Grid>
        {
          empDest &&
          <Grid container item alignItems="center" xs={12} sm={12} md={4} style={gridStyle}>
            <DisabledTextfield label={getLocalizedString("delegatedName")} value={empDest.name} />
          </Grid>
        }
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
          <DisabledTextfield label={getLocalizedString("delegatedCode")} value={data.delegation_code} />
        </Grid> 
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
          <DisabledTextfield label={getLocalizedString("delegatedEmail")} value={data.delegation_email} />
        </Grid> 
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
          <DisabledTextfield label={getLocalizedString("delegationDateFrom")} value={data.start_date} />
        </Grid> 
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
          <DisabledTextfield label={getLocalizedString("delegationDateTo")} value={data.end_date} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Details;
