import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import {
    dateClassRule,
    dateFormatter,
    periodFormatter,
    dateValueGetter,
    periodValueGetter,
    dateFilterParams,
    periodFilterParams,
    dateComparator,
    periodComparator,
} from "@icarius-table/date";
import {
    numberComparatorMax2,
    numberComparatorMax3,
    numberFormatterMin2,
    numberFormatterMin0Max3,
    numberFilterParams,
    numberFilterParamsWithThreeDigits,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.currentProcesses],
        config: {
            headerName: getLocalizedString("processCode"),
            field: "Código de proceso",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.currentProcesses],
        config: {
            headerName: getLocalizedString("processDescription"),
            field: "Descripción",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.currentProcesses],
        config: {
            headerName: getLocalizedString("processType"),
            field: "Tipo de proceso",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.currentProcesses],
        config: {
            headerName: getLocalizedString("processSubtype"),
            field: "Tipo de subproceso",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.currentProcesses],
        config: {
            headerName: getLocalizedString("processDateFrom"),
            field: "Fecha desde",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: [paths.currentProcesses],
        config: {
            headerName: getLocalizedString("processDateTo"),
            field: "Fecha hasta",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: [paths.currentProcesses],
        config: {
            headerName: getLocalizedString("processPeriod"),
            field: "Período",
            filter: "agTextColumnFilter",
            comparator: periodComparator,
            filterParams: periodFilterParams,
            valueFormatter: periodFormatter,
            valueGetter: periodValueGetter,
        }
    },
    {
        pages: [paths.currentProcesses],
        config: {
            headerName: getLocalizedString("processPaymentDate"),
            field: "Fecha de pago",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: [paths.currentProcesses],
        config: {
            headerName: 'Calendario de tiempos',
            field: "calendarString",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.currentProcesses],
        config: {
            headerName: getLocalizedString("processStatus"),
            field: "Estado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.currentProcesses],
        config: {
            headerName: getLocalizedString("processDateLast"),
            field: "Fecha último proceso",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: [paths.currentProcesses],
        config: {
            headerName: getLocalizedString("processTimeLast"),
            field: "Hora último proceso",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.currentProcesses],
        config: {
            headerName: "Colaboradores procesados",
            field: "Colaboradores procesados",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax3,
            filterParams: numberFilterParamsWithThreeDigits,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max3,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.currentProcesses],
        config: {
            headerName: "Proceso publicado",
            field: "Proceso publicado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['currentProcessesAutomatic'],
        config: {
            headerName: getLocalizedString("processCode"),
            field: "Código de proceso",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ['currentProcessesAutomatic'],
        config: {
            headerName: getLocalizedString("processDescription"),
            field: "Descripción",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['currentProcessesAutomatic'],
        config: {
            headerName: getLocalizedString("processType"),
            field: "Tipo de proceso",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['currentProcessesAutomatic'],
        config: {
            headerName: 'Proceso por defecto',
            field: "Proceso por defecto",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['currentProcessesAutomatic'],
        config: {
            headerName: getLocalizedString("processSubtype"),
            field: "Tipo de subproceso",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['currentProcessesAutomatic'],
        config: {
            headerName: getLocalizedString("processDateFrom"),
            field: "Fecha desde",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: ['currentProcessesAutomatic'],
        config: {
            headerName: getLocalizedString("processDateTo"),
            field: "Fecha hasta",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: ['currentProcessesAutomatic'],
        config: {
            headerName: getLocalizedString("processPeriod"),
            field: "Período",
            filter: "agTextColumnFilter",
            comparator: periodComparator,
            filterParams: periodFilterParams,
            valueFormatter: periodFormatter,
            valueGetter: periodValueGetter,
        }
    },
    {
        pages: ['currentProcessesAutomatic'],
        config: {
            headerName: getLocalizedString("processPaymentDate"),
            field: "Fecha de pago",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: ['currentProcessesAutomatic'],
        config: {
            headerName: 'Calendario de tiempos',
            field: "calendarString",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['currentProcessesAutomatic'],
        config: {
            headerName: getLocalizedString("processStatus"),
            field: "Estado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['currentProcessesAutomatic'],
        config: {
            headerName: getLocalizedString("processDateLast"),
            field: "Fecha último proceso",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: ['currentProcessesAutomatic'],
        config: {
            headerName: getLocalizedString("processTimeLast"),
            field: "Hora último proceso",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['currentProcessesAutomatic'],
        config: {
            headerName: "Colaboradores procesados",
            field: "Colaboradores procesados",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax3,
            filterParams: numberFilterParamsWithThreeDigits,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max3,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ['currentProcessesAutomatic'],
        config: {
            headerName: "Proceso publicado",
            field: "Proceso publicado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.currentProcessesEmployees],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.currentProcessesEmployees],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "Apellidos y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.currentProcessesEmployees],
        config:
        {
            headerName: getLocalizedString("state"),
            field: "Estado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.currentProcessesEmployees],
        config: {
            headerName: getLocalizedString("internalNumber"),
            field: "Número interno",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.currentProcessesEmployees],
        config:
        {
            headerName: "Personal de planta",
            field: "Personal de planta",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.currentProcessesEmployees],
        config: {
            headerName: getLocalizedString("incomeDate"),
            field: "Fecha de contratación",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: [paths.currentProcessesEmployees],
        config: {
            headerName: getLocalizedString("contractEndDate"),
            field: "Fecha de egreso",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: [paths.currentProcessesEmployees],
        config: {
            headerName: getLocalizedString("fType"),
            field: "Tipo de funcionario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.currentProcessesEmployees],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "Tipo de contrato",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.currentProcessesEmployees],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "Lugar de trabajo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.currentProcessesEmployees],
        config: {
            headerName: "Obra o Faena",
            field: "Obra o Faena",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.currentProcessesEmployees],
        config: {
            headerName: getLocalizedString("paymentLocation"),
            field: "Lugar de pago",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.currentProcessesEmployees],
        config: {
            headerName: getLocalizedString("position"),
            field: "Cargo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.currentProcessesEmployees],
        config: {
            headerName: getLocalizedString("function"),
            field: "Función",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.currentProcessesEmployees],
        config: {
            headerName: getLocalizedString("category"),
            field: "Categoría",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.currentProcessesEmployees],
        config: {
            headerName: getLocalizedString("management"),
            field: "Gerencia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.currentProcessesEmployees],
        config: {
            headerName: getLocalizedString("department"),
            field: "Departamento",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.currentProcessesEmployees],
        config: {
            headerName: getLocalizedString("laborUnion"),
            field: "Sindicato",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByConcept'],
        config: {
            headerName: 'Asiento',
            field: 'Asiento',
            filter: "agSetColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByConcept'],
        config: {
            headerName: 'Cuenta contable',
            field: 'Cuenta contable',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByConcept'],
        config: {
            headerName: 'Desc. de la Cuenta contable',
            field: 'Desc. de la Cuenta contable',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByConcept'],
        config: {
            headerName: 'Código de concepto',
            field: 'Código de concepto',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByConcept'],
        config: {
            headerName: 'Nombre del concepto',
            field: 'Nombre del concepto',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByConcept'],
        config: {
            headerName: 'Apellidos y nombres',
            field: "Apellidos y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByConcept'],
        config: {
            headerName: 'Desc. Centro de costo',
            field: 'Desc. Centro de costo',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByConcept'],
        config: {
            headerName: 'Desc. del Proyecto',
            field: 'Desc. del Proyecto',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByConcept'],
        config: {
            headerName: 'Débito',
            field: 'Débito',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByConcept'],
        config: {
            headerName: 'Crédito',
            field: 'Crédito',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByConcept'],
        config: {
            headerName: 'Desc. Centro de costo 2',
            field: 'Desc. Centro de costo 2',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByConcept'],
        config: {
            headerName: 'Desc. Centro de costo 3',
            field: 'Desc. Centro de costo 3',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByConcept'],
        config: {
            headerName: 'Desc. Centro de costo 4',
            field: 'Desc. Centro de costo 4',
            filter: "agTextColumnFilter",
        }
    },

    {
        pages: ['accountingEntriesAnalyticsByConcept'],
        config: {
            headerName: 'Desc. Centro de costo 5',
            field: 'Desc. Centro de costo 5',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByConcept'],
        config: {
            headerName: 'Información detallada',
            field: 'Información detallada',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByConcept'],
        config: {
            headerName: 'Valores para cabecera',
            field: 'Valores para cabecera',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByConcept'],
        config: {
            headerName: 'Valores para detalle',
            field: 'Valores para detalle',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByProject'],
        config: {
            headerName: 'Asiento',
            field: 'Asiento',
            filter: "agSetColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByProject'],
        config: {
            headerName: 'Cuenta contable',
            field: 'Cuenta contable',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByProject'],
        config: {
            headerName: 'Desc. de la Cuenta contable',
            field: 'Desc. de la Cuenta contable',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByProject'],
        config: {
            headerName: 'Débito',
            field: 'Débito',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByProject'],
        config: {
            headerName: 'Crédito',
            field: 'Crédito',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByProject'],
        config: {
            headerName: 'Proyecto',
            field: 'Proyecto',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByProject'],
        config: {
            headerName: 'Desc. del Proyecto',
            field: 'Desc. del Proyecto',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByProject'],
        config: {
            headerName: 'Código de concepto',
            field: 'Código de concepto',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByProject'],
        config: {
            headerName: 'Nombre del concepto',
            field: 'Nombre del concepto',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByProject'],
        config: {
            headerName: 'Centro de costo',
            field: 'Centro de costo',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByProject'],
        config: {
            headerName: 'Desc. Centro de costo',
            field: 'Desc. Centro de costo',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByProject'],
        config: {
            headerName: 'Centro de costo 2',
            field: 'Centro de costo 2',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByProject'],
        config: {
            headerName: 'Desc. Centro de costo 2',
            field: 'Desc. Centro de costo 2',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByProject'],
        config: {
            headerName: 'Centro de costo 3',
            field: 'Centro de costo 3',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByProject'],
        config: {
            headerName: 'Desc. Centro de costo 3',
            field: 'Desc. Centro de costo 3',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByProject'],
        config: {
            headerName: 'Centro de costo 4',
            field: 'Centro de costo 4',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByProject'],
        config: {
            headerName: 'Desc. Centro de costo 4',
            field: 'Desc. Centro de costo 4',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByProject'],
        config: {
            headerName: 'Centro de costo 5',
            field: 'Centro de costo 5',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByProject'],
        config: {
            headerName: 'Desc. Centro de costo 5',
            field: 'Desc. Centro de costo 5',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByProject'],
        config: {
            headerName: 'Información detallada',
            field: 'Información detallada',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByProject'],
        config: {
            headerName: 'Valores para cabecera',
            field: 'Valores para cabecera',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByProject'],
        config: {
            headerName: 'Valores para detalle',
            field: 'Valores para detalle',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByCenter'],
        config: {
            headerName: 'Asiento',
            field: 'Asiento',
            filter: "agSetColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByCenter'],
        config: {
            headerName: 'Cuenta contable',
            field: 'Cuenta contable',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByCenter'],
        config: {
            headerName: 'Desc. de la Cuenta contable',
            field: 'Desc. de la Cuenta contable',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByCenter'],
        config: {
            headerName: 'Débito',
            field: 'Débito',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByCenter'],
        config: {
            headerName: 'Crédito',
            field: 'Crédito',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByCenter'],
        config: {
            headerName: 'Centro de costo',
            field: 'Centro de costo',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByCenter'],
        config: {
            headerName: 'Desc. Centro de costo',
            field: 'Desc. Centro de costo',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByCenter'],
        config: {
            headerName: 'Código de concepto',
            field: 'Código de concepto',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByCenter'],
        config: {
            headerName: 'Nombre del concepto',
            field: 'Nombre del concepto',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByCenter'],
        config: {
            headerName: 'Centro de costo 2',
            field: 'Centro de costo 2',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByCenter'],
        config: {
            headerName: 'Desc. Centro de costo 2',
            field: 'Desc. Centro de costo 2',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByCenter'],
        config: {
            headerName: 'Centro de costo 3',
            field: 'Centro de costo 3',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByCenter'],
        config: {
            headerName: 'Desc. Centro de costo 3',
            field: 'Desc. Centro de costo 3',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByCenter'],
        config: {
            headerName: 'Centro de costo 4',
            field: 'Centro de costo 4',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByCenter'],
        config: {
            headerName: 'Desc. Centro de costo 4',
            field: 'Desc. Centro de costo 4',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByCenter'],
        config: {
            headerName: 'Centro de costo 5',
            field: 'Centro de costo 5',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByCenter'],
        config: {
            headerName: 'Desc. Centro de costo 5',
            field: 'Desc. Centro de costo 5',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByCenter'],
        config: {
            headerName: 'Proyecto',
            field: 'Proyecto',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByCenter'],
        config: {
            headerName: 'Desc. del Proyecto',
            field: 'Desc. del Proyecto',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByCenter'],
        config: {
            headerName: 'Información detallada',
            field: 'Información detallada',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByCenter'],
        config: {
            headerName: 'Valores para cabecera',
            field: 'Valores para cabecera',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalyticsByCenter'],
        config: {
            headerName: 'Valores para detalle',
            field: 'Valores para detalle',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalytics'],
        config: {
            headerName: 'Asiento',
            field: 'Asiento',
            filter: "agSetColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ['accountingEntriesAnalytics'],
        config: {
            headerName: 'Cuenta contable',
            field: 'Cuenta contable',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalytics'],
        config: {
            headerName: 'Desc. de la Cuenta contable',
            field: 'Desc. de la Cuenta contable',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalytics'],
        config: {
            headerName: 'Código de empleado',
            field: 'Código de empleado',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalytics'],
        config: {
            headerName: 'Apellidos y nombres',
            field: "Apellidos y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalytics'],
        config: {
            headerName: 'Referencia 1',
            field: 'Referencia 1',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalytics'],
        config: {
            headerName: 'Referencia 2',
            field: 'Referencia 2',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalytics'],
        config: {
            headerName: 'Débito',
            field: 'Débito',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ['accountingEntriesAnalytics'],
        config: {
            headerName: 'Crédito',
            field: 'Crédito',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ['accountingEntriesAnalytics'],
        config: {
            headerName: 'Centro de costo',
            field: 'Centro de costo',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalytics'],
        config: {
            headerName: 'Desc. Centro de costo',
            field: 'Desc. Centro de costo',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalytics'],
        config: {
            headerName: 'Centro de costo 2',
            field: 'Centro de costo 2',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalytics'],
        config: {
            headerName: 'Desc. Centro de costo 2',
            field: 'Desc. Centro de costo 2',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalytics'],
        config: {
            headerName: 'Centro de costo 3',
            field: 'Centro de costo 3',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalytics'],
        config: {
            headerName: 'Desc. Centro de costo 3',
            field: 'Desc. Centro de costo 3',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalytics'],
        config: {
            headerName: 'Centro de costo 4',
            field: 'Centro de costo 4',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalytics'],
        config: {
            headerName: 'Desc. Centro de costo 4',
            field: 'Desc. Centro de costo 4',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalytics'],
        config: {
            headerName: 'Centro de costo 5',
            field: 'Centro de costo 5',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalytics'],
        config: {
            headerName: 'Desc. Centro de costo 5',
            field: 'Desc. Centro de costo 5',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalytics'],
        config: {
            headerName: 'Proyecto',
            field: 'Proyecto',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalytics'],
        config: {
            headerName: 'Desc. del Proyecto',
            field: 'Desc. del Proyecto',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalytics'],
        config: {
            headerName: 'Información detallada',
            field: 'Información detallada',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalytics'],
        config: {
            headerName: 'Valores para cabecera',
            field: 'Valores para cabecera',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalytics'],
        config: {
            headerName: 'Valores para detalle',
            field: 'Valores para detalle',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalytics'],
        config: {
            headerName: 'Código de concepto',
            field: 'Código de concepto',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesAnalytics'],
        config: {
            headerName: 'Nombre del concepto',
            field: 'Nombre del concepto',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesToExport'],
        config: {
            headerName: 'Código de proceso',
            field: 'Código de proceso',
            filter: "agSetColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ['accountingEntriesToExport'],
        config: {
            headerName: 'Descripción del proceso',
            field: 'Descripción del proceso',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesToExport'],
        config: {
            headerName: 'Asiento',
            field: 'Asiento',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesToExport'],
        config: {
            headerName: 'Cuenta contable',
            field: 'Cuenta contable',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesToExport'],
        config: {
            headerName: 'Código de empleado',
            field: 'Código de empleado',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesToExport'],
        config: {
            headerName: 'Apellidos y nombres',
            field: "Apellidos y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesToExport'],
        config: {
            headerName: 'Referencia 1',
            field: 'Referencia 1',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesToExport'],
        config: {
            headerName: 'Referencia 2',
            field: 'Referencia 2',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesToExport'],
        config: {
            headerName: 'Débito',
            field: 'Débito',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ['accountingEntriesToExport'],
        config: {
            headerName: 'Crédito',
            field: 'Crédito',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ['accountingEntriesToExport'],
        config: {
            headerName: 'Centro de costo',
            field: 'Centro de costo',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesToExport'],
        config: {
            headerName: 'Centro de costo 2',
            field: 'Centro de costo 2',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesToExport'],
        config: {
            headerName: 'Centro de costo 3',
            field: 'Centro de costo 3',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesToExport'],
        config: {
            headerName: 'Centro de costo 4',
            field: 'Centro de costo 4',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesToExport'],
        config: {
            headerName: 'Centro de costo 5',
            field: 'Centro de costo 5',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesToExport'],
        config: {
            headerName: 'Proyecto',
            field: 'Proyecto',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesToExport'],
        config: {
            headerName: 'Información detallada',
            field: 'Información detallada',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesToExport'],
        config: {
            headerName: 'Valores para cabecera',
            field: 'Valores para cabecera',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesToExport'],
        config: {
            headerName: 'Valores para detalle',
            field: 'Valores para detalle',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesToExport'],
        config: {
            headerName: 'Código de concepto',
            field: 'Código de concepto',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesToExport'],
        config: {
            headerName: 'Nombre del concepto',
            field: 'Nombre del concepto',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesCollaborator'],
        config: {
            headerName: 'Asiento',
            field: 'Asiento',
            filter: "agSetColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ['accountingEntriesCollaborator'],
        config: {
            headerName: 'Código de empleado',
            field: 'Código de empleado',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesCollaborator'],
        config: {
            headerName: 'Apellidos y nombres',
            field: "Apellidos y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingEntriesCollaborator'],
        config: {
            headerName: 'Débito',
            field: 'Débito',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ['accountingEntriesCollaborator'],
        config: {
            headerName: 'Crédito',
            field: 'Crédito',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ['accountingEntriesCollaborator'],
        config: {
            headerName: 'Diferencia',
            field: 'Diferencia',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
]