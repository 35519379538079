import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { createConsentTypeAction, updateConsentTypeAction } from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getGalleryImagesAction, uploadImageAction, deleteImageAction } from "@icarius-common/galleryDialog/actions";
import { getGalleryImages } from "@icarius-common/galleryDialog/selectors";
import useHandleForm from "./useHandleForm";
import { ImagePlaceholder } from "@icarius-icons";
import { getAppColor } from "src/app/selectors";
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import GalleryDialog from "@icarius-common/galleryDialog/components/index";
import { getTypeList, getInstanceList } from "../../selectors";
import { withStyles } from "@material-ui/core/styles";
import useSetImageIndex from "@icarius-common/galleryDialog/components/useSetImageIndex";
import FileList from "@icarius-common/fileUpload/fileList";
import FileInput from "@icarius-common/fileUpload/fileInput";

const gridStyle = { height: 75, padding: "0px 10px" };

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },

  cssFocused: {
    color: "var(--mainText) !important",
  },

  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});

const Form = (props) => {

  const { data, handleClose, classes } = props;

  const dispatch = useDispatch();
  const images = useSelector(getGalleryImages);
  const color = useSelector(getAppColor);
  const types = useSelector(getTypeList);
  const instances = useSelector(getInstanceList);

  const [imageIndex, setImageIndex] = useSetImageIndex(data, images);
  const [imagesDialogIsOpen, setImagesDialogIsOpen] = useState(false);
  const [failedDocuments, setFailedDocuments] = useState(null);

  useEffect(() => {
    dispatch(getGalleryImagesAction('news'));
  }, [dispatch])

  const create = (dataToSend) => {
    setFailedDocuments(null);
    dispatch(createConsentTypeAction(dataToSend))
      .then((resp) => {
        if (resp?.status === 200) {
          handleClose();
        } else {
          if (resp?.data?.invalidFiles) {
            setFailedDocuments(resp.data.invalidFiles)
          }
        }
      });
  }

  const modify = (dataToSend) => {
    setFailedDocuments(null);
    dispatch(updateConsentTypeAction({ internalCode: data.internalCode, ...dataToSend }))
      .then((resp) => {
        if (resp?.status === 200) {
          handleClose();
        } else {
          if (resp?.data?.invalidFiles) {
            setFailedDocuments(resp.data.invalidFiles)
          }
        }
      });
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const openValidationFileError = () => {
    dispatch(openSnackbarAction({ msg: 'La plantilla es obligatoria cuando hay instancia seleccionada', severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit,
  } = useHandleForm(data, create, modify, openValidationError, openValidationFileError);

  const handleOpenGallery = () => {
    setImagesDialogIsOpen(true);
  }

  const uploadImage = (file) => {
    setImageIndex(null);
    dispatch(uploadImageAction(file, 'news'));
  }

  const deleteImage = (filename) => {
    setImageIndex(null);
    dispatch(deleteImageAction(filename, 'news'));
  }

  const handleSelectImage = (index) => {
    setFormValue(index !== null ? images[index].img : "", 'image');
    setImageIndex(index);
  }

  const typeArrToUse = (formData.instance && formData.instance !== '-') ? types.find(item => item.key === formData.instance).values : [];

  return (
    <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* codigo */}
          <TextField
            disabled={!Boolean(isCreate)}
            required
            fullWidth
            margin={"none"}
            label={'Código'}
            onChange={(e) => setFormValue(e.target.value, "code")}
            value={formData.code}
            inputProps={{ maxLength: 8 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* nombre */}
          <TextField
            required
            fullWidth
            margin={"none"}
            label={'Nombre'}
            value={formData.name}
            onChange={(e) => setFormValue(e.target.value, "name")}
            inputProps={{ maxLength: 40 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} style={{ ...gridStyle, height: '' }}> {/* texto */}
          <TextField
            required
            fullWidth
            margin={"none"}
            variant="outlined"
            label={'Texto'}
            onChange={(e) => setFormValue(e.target.value, "text")}
            value={formData.text}
            multiline
            rows={25}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              }
            }}
            inputProps={{ spellCheck: 'false' }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* Link o Enlace */}
          <TextField
            fullWidth
            margin={"none"}
            label={"Link"}
            value={formData.link}
            onChange={(e) => setFormValue(e.target.value, "link")}
            inputProps={{ maxLength: 254 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* enableAlert */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-enable`}>{'Habilitar envío de alerta'}</InputLabel>
            <Select
              value={formData.enableAlert}
              labelId={`label-enable`}
              id={`select-enable`}
              onChange={(e) => setFormValue(e.target.value, "enableAlert")}
              margin={"none"}
            >
              {
                [{ key: "Y", value: "Si" }, { key: "N", value: "No" }].map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* instancee */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel id={`label-instance`}>{'Instancia laboral en carpeta'}</InputLabel>
            <Select
              value={formData.instance}
              labelId={`label-instance`}
              id={`select-instance`}
              onChange={(e) => setFormValue(e.target.value, "instance")}
              margin={"none"}
            >
              {
                instances?.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* type */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel required={formData.instance !== "-"} id={`label-type`}>{'Tipo de archivo en carpeta'}</InputLabel>
            <Select
              disabled={formData.instance === "-"}
              value={formData.type}
              labelId={`label-type`}
              id={`select-type`}
              onChange={(e) => setFormValue(e.target.value, "type")}
              margin={"none"}
            >
              {
                typeArrToUse?.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* template */}
          <FileInput
            acceptedFormats={['.html']}
            handleChange={(files) => setFormValue(files, 'template')}
            id={`poll${data?.code || 'create'}`}
            preSubmitCallback={() => setFailedDocuments(null)}
          />
          <FileList
            files={formData.template}
            filesWithError={failedDocuments}
            handleClick={() => setFormValue(null, 'template')}
          />
          {
            Boolean(data?.template && !formData.template?.length) &&
            <Grid item xs={12} style={{ marginTop: 5 }}>
              <Typography className="whiteText" style={{ fontSize: 16 }}>
                {`Plantilla cargada actualmente: ${data?.template}`}
              </Typography>
            </Grid>
          }
          {
            Boolean(!data?.template && !formData.template?.length) &&
            <Grid item xs={12} style={{ marginTop: 5 }}>
              <Typography className="whiteText" style={{ fontSize: 16 }}>
                {'Plantilla cargada actualmente: Sin plantilla cargada'}
              </Typography>
            </Grid>
          }
        </Grid>
        <Grid container item alignItems='center' xs={12} style={{ height: 200, padding: "10px 10px", paddingBottom: 20 }}>
          <Grid container item xs={12} style={{ width: 220, height: 140, display: "flex", alignItems: "center", justifyContent: "center" }}>
            {
              imageIndex !== null ?
                <img
                  style={{ maxWidth: 220, maxHeight: 140 }}
                  src={images && images.length > 0 ? IMAGES_ENDPOINT + images[imageIndex].img : ""}
                  alt={"imageNotFound"}
                />
                :
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 220, height: 140, backgroundColor: color }}>
                  <ImagePlaceholder fontSize="small" style={{ fill: "var(--icons)" }} />
                </div>
            }
          </Grid>
          <div style={{ marginTop: 10, width: "100%", display: 'flex', justifyContent: 'center' }} onClick={handleOpenGallery}>
            <Typography variant="h6" className="whiteText" style={{ cursor: "pointer", fontWeight: 400, fontSize: 16, textDecoration: "underline" }}>
              {getLocalizedString("groupImagesTitle")}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>
        <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
      </Grid>
      {
        imagesDialogIsOpen &&
        <GalleryDialog
          imagePreselected={imageIndex}
          open={imagesDialogIsOpen}
          handleClose={() => setImagesDialogIsOpen(false)}
          handleSubmit={(img) => handleSelectImage(img)}
          handleUploadImage={uploadImage}
          handleDeleteUserImage={deleteImage}
        />
      }
    </Grid >
  );
}

export default withStyles(styles)(Form);
