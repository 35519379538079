import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Grid,
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
  TextField,
  Typography
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTransition from "@icarius-common/dialogTransition";
import { withStyles } from "@material-ui/core/styles";
import PaperDraggable from "@icarius-common/paperDraggable";

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },

  cssFocused: {
    color: "var(--mainText) !important",
  },

  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});

const CalcProcessTraceability = (props) => {
  const { open, data, calcProcessName, handleClose, classes } = props;

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth
      maxWidth={"md"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {`Trazabilidad del proceso de cálculo - ${calcProcessName}`}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent style={{ paddingTop: 0 }}>
        <Grid container item xs={12} >
          <Grid container item alignItems="center" xs={12} style={{ padding: "10px" }}> {/* Trazabilidad del proceso de cálculo */}
            <TextField
              fullWidth
              variant="outlined"
              multiline
              rows={25}
              margin={"none"}
              value={data}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                }
              }}
              inputProps={{ spellCheck: 'false' }}
            />
          </Grid>
        </Grid>

      </DialogContent>
      <Grid container item alignItems="center" xs={12} style={{ padding: "0px 30px" }}>
        <Typography
          style={{ color: "red", fontWeight: "bold" }}>
          {"Ctrl+F para buscar texto dentro del Trace"}
        </Typography>
      </Grid>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString("close")} />
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(CalcProcessTraceability);
