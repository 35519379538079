import { useState } from "react";
import { useDispatch } from "react-redux";
import { CLEAR_EMPLOYEES } from "../../actionTypes";
import { getCalcProcessTraceabilityAction } from "../../actions";
import { getLocalizedString } from "@icarius-localization/strings";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const useCalcProcessTraceability = () => {

  const dispatch = useDispatch();

  const [calcProcessTraceabilityDialogIsOpen, setCalcProcessTraceabilityDialogIsOpen] = useState(false);
  const [calcProcessTraceabilityDialogData, setCalcProcessTraceabilityDialogData] = useState(null);
  const [calcProcessName, setCalcProcessName] = useState("");

  const handleOpenCalcProcessTraceabilityDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();

    //Si no hay uno seleccionado, warning
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedOneRow"), severity: "warning" }));
      return;
    }

    //Traigo la trazabilidad del proceso
    dispatch(getCalcProcessTraceabilityAction(selectedRows[0]["Código de proceso"]))
      .then(res => {
        if (res.status === 200) {
          const data = res.data.data;
          if (data) {
            setCalcProcessTraceabilityDialogData(data);
            setCalcProcessName(selectedRows[0]["Descripción"]);
            setCalcProcessTraceabilityDialogIsOpen(true);
          } else {
            dispatch(openSnackbarAction({
              msg: "No existe trace del proceso de cálculo para visualizar",
              severity: "error"
            }));
          }
        }
      })
  }

  const handleCloseCalcProcessTraceabilityDialog = () => {
    setCalcProcessTraceabilityDialogIsOpen(false);
    setCalcProcessTraceabilityDialogData(null);
    setCalcProcessName("");
    dispatch({ type: CLEAR_EMPLOYEES });
  }

  return {
    calcProcessTraceabilityDialogIsOpen,
    calcProcessTraceabilityDialogData,
    calcProcessName,
    handleOpenCalcProcessTraceabilityDialog,
    handleCloseCalcProcessTraceabilityDialog
  }
}

export default useCalcProcessTraceability;
