import React from "react";
import { useDispatch } from "react-redux";
import { Typography, Grid, MenuItem, FormControl, InputLabel, Select } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { setDelegationInServer } from "../actions";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { getNextDay, getTomorrow } from "@icarius-utils/date";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import useHandleForm from "./useHandleForm";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const gridStyle = { height: 80, padding: "0px 10px", marginBottom: 10 };

const DelegationForm = (props) => {

  const { managersEmployers, handleClose } = props;
  const dispatch = useDispatch();

  const endDateIsValid = (values) => {
    return values.endDate !== "" && (values.endDate) > (values.date)
  }

  const createRequest = delegation => {
    dispatch(setDelegationInServer(delegation));
    handleClose();
  };

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const { formData, setFormValue, submit } = useHandleForm(managersEmployers, endDateIsValid, createRequest, openValidationError);

  return (
    <Grid container item xs={12} direction="row" style={{ paddingTop: 20 }}>
      <Grid container item xs={12}>        
        <Grid container item alignItems="center" xs={12} md={6} lg={4} style={gridStyle}>
          <FormControl style={{ width: "100%", paddingBottom: 33 }}>
            <InputLabel required id={`label-manager-new}`}>{getLocalizedString("addressee")}</InputLabel>
            <Select
              value={formData.manager}
              labelId={`label-manager-new`}
              id={`select-addressee-new`}
              onChange={(e) => setFormValue(e.target.value, "manager")}
              margin={"none"}
            >
              {
                managersEmployers.map(item =>
                  <MenuItem
                    className={"whiteText"}
                    key={item.code}
                    value={item.code}>
                    {item.name}
                  </MenuItem>
                )
              }
            </Select>
          </FormControl>                    
        </Grid>
        <Grid container item alignItems="center" xs={12} md={6} lg={4} style={gridStyle}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <DatePicker
              required
              style={{ paddingBottom: 33 }}
              fullWidth
              label={getLocalizedString("startDate")}
              variant="inline"              
              format="dd/MM/yyyy"
              value={formData.date}
              disablePast
              minDate={getTomorrow()}
              onChange={(date) => setFormValue(date, "date")}
              invalidDateMessage={""}
            />
          </MuiPickersUtilsProvider>
        </Grid>   
        <Grid container item alignItems="center" xs={12} md={6} lg={4} style={gridStyle}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <DatePicker
              required
              fullWidth
              label={getLocalizedString("endDate")}
              variant="inline"              
              format="dd/MM/yyyy"
              value={formData.endDate}
              disablePast
              minDate={getNextDay(formData.date)}
              onChange={(date) => setFormValue(date, "endDate")}
              invalidDateMessage={""}
              minDateMessage={""}
            />
          </MuiPickersUtilsProvider>
          <Typography
            className={!endDateIsValid(formData) ? "errorColor" : "whiteText"}
            style={{ paddingTop: 5 }}
            variant="caption"
            gutterBottom>
            {getLocalizedString("delegationEndDateValidation")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>        
        <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
      </Grid>      
    </Grid>
  );
}

export default DelegationForm;
