import React from "react";
import { useDispatch } from "react-redux";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DynamicInput from "@icarius-common/dynamicInputs";
import { createContractorAction, modifyContractorAction } from "../../actions";
import useHandleForm from "./useHandleForm";

const Form = (props) => {

  const {
    data,
    types,
    userFieldsDefinition,
    handleClose,
  } = props;

  const dispatch = useDispatch();

  const create = (dataToSend) => {
    dispatch(createContractorAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    dispatch(modifyContractorAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    fieldsWithError,
    setFormValue,
    submit,
  } = useHandleForm(data, userFieldsDefinition, create, modify, openValidationError);

  return (
    <Grid container item xs={12} direction="row" justify="center" style={{ paddingTop: 20 }}>
      <Grid container item xs={12} spacing={2}>
        <Grid container item alignItems="center" xs={12} sm={6} md={3}>
          <TextField
            disabled={!Boolean(isCreate)}
            required
            fullWidth
            margin={"none"}
            label={getLocalizedString("contractorCode")}
            onChange={(e) => setFormValue(e.target.value, "code")}
            value={formData.code}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={3}>
          <TextField
            required
            fullWidth
            margin={"none"}
            label={getLocalizedString("contractorName")}
            value={formData.name}
            onChange={(e) => setFormValue(e.target.value, "name")}
            inputProps={{ maxLength: 40 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={12} md={6}>
          <TextField
            required
            fullWidth
            margin={"none"}
            label={getLocalizedString("contractorAddress")}
            value={formData.address}
            onChange={(e) => setFormValue(e.target.value, "address")}
            inputProps={{ maxLength: 250 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={3}>
          <TextField
            fullWidth
            margin={"none"}
            label={getLocalizedString("contractorContactName")}
            value={formData.contactName}
            onChange={(e) => setFormValue(e.target.value, "contactName")}
            inputProps={{ maxLength: 60 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={3}>
          <TextField
            fullWidth
            margin={"none"}
            label={getLocalizedString("contractorContactMail")}
            value={formData.contactMail}
            onChange={(e) => setFormValue(e.target.value, "contactMail")}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={3}>
          <TextField
            fullWidth
            margin={"none"}
            label={getLocalizedString("contractorContactPhone")}
            value={formData.contactPhone}
            onChange={(e) => setFormValue(e.target.value, "contactPhone")}
            inputProps={{ maxLength: 20 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={3}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-type-${data ? data.internalCode : "new"}`}>
              {getLocalizedString("contractorType")}
            </InputLabel>
            <Select
              value={formData.type}
              labelId={`label-type-${data ? data.internalCode : "new"}`}
              id={`select-type-${data ? data.internalCode : "new"}`}
              onChange={(e) => setFormValue(e.target.value, "type")}
              margin={"none"}
            >
              {
                types?.map((item) => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        {
          userFieldsDefinition?.map((fieldConfig) => {
            return (
              <Grid
                container item alignItems="center" xs={12} md={6}
                key={fieldConfig.name}
              >
                <DynamicInput
                  fieldConfig={fieldConfig}
                  label={fieldConfig.name}
                  value={formData[fieldConfig.name]}
                  required={fieldConfig.required}
                  error={Boolean(fieldsWithError?.includes(fieldConfig.name))}
                  handleChange={setFormValue}
                />
              </Grid>
            )
          })
        }
      </Grid>
      <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>
        <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
      </Grid>
    </Grid>
  );
}

export default Form;