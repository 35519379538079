import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { getGenericFilesForDigitalFolderAction, updateGenericFilesForDigitalFolderAction } from "../../actions";
import { getGenericFiles } from "../../selectors";
import GenericSection from "./genericSection";
import SectionTitle from "../sectionTitle";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { Grid } from "@material-ui/core";

const useHandleMandatoryKeys = (initialValue) => {
    const [ mandatoryKeys, setMandatoryKeys ] = useState({});

    const changeMandatoryKey = (key, value) => {
        const newMandatoryKeys = {...mandatoryKeys};
        newMandatoryKeys[key] = value;
        setMandatoryKeys(newMandatoryKeys);
    }

    //con el initialValues armar el objeto con los campos siendo las key de los files y el value si es obligatorio o no
    useEffect(() => {
        const auxKeys = {};
        initialValue.forEach(instance => instance.values.forEach(file => auxKeys[file.keyTipoDocumento] = file.oblig));
        setMandatoryKeys(auxKeys);
    }, [initialValue])

    return { mandatoryKeys, changeMandatoryKey };
}

const GenericsContainer = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getGenericFilesForDigitalFolderAction())
    }, [dispatch]);

    const genericFiles = useSelector(getGenericFiles);
    const { mandatoryKeys, changeMandatoryKey } = useHandleMandatoryKeys(genericFiles);

    const handleSubmit = () => {
        const fileData = { data: formatFileData() };        
        dispatch(updateGenericFilesForDigitalFolderAction(fileData));
    }

    const formatFileData = () => {
        const fileData = [];

        genericFiles.forEach(instance => {
            instance.values.forEach(file => {
                fileData.push({
                    keyInstanciaLaboral: file.keyInstanciaLaboral,
                    keyTipoDocumento: file.keyTipoDocumento,
                    oblig: mandatoryKeys[file.keyTipoDocumento],
                })
            })
        });

        return fileData;
    }

    if(genericFiles.length < 1) return null;

    return <>
        <SectionTitle title={getLocalizedString("generics")} />
        {
            genericFiles.map(instance => (
                <GenericSection
                    key={instance.key}
                    instance={instance}
                    mandatoryKeys={mandatoryKeys}
                    handleClick={changeMandatoryKey}
                />
            ))
        }
        <Grid container justify="flex-end">
            <div style={{ marginRight: 30, marginTop: 10 }}>
                <ButtonDialogAction onClick={handleSubmit} isAccept text={getLocalizedString("save")} />
            </div>
        </Grid>
    </>
}

export default GenericsContainer;
