import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getDelegationsAPI, getManagersEmployersBySocietyAPI, deleteDelegationAPI, setDelegationAPI } from "@icarius-connection/api";
import * as actionTypes from "./actionTypes";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";

export const setDelegationInServer = (delegation) => async (dispatch) => {
  dispatch({ type: actionTypes.SET_DELEGATION });
  try {
    let response = await setDelegationAPI(delegation);
    let status = response.data && response.data.status;

    if (status === "OK") {
      dispatch({
        type: actionTypes.SET_DELEGATION_FULFILLED,
        payload: response.data.data,
      });
      return response;
    } else {
      if (response.data.status === "ERROR_OVERLAP") {
        dispatch(openSnackbarAction({ msg: getLocalizedString("delegationAlready"), severity: "error" }));
      } else {
        dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      }
      dispatch({ type: actionTypes.SET_DELEGATION_REJECTED });
      return;
    }
  } catch (e) {
    dispatch({ type: actionTypes.SET_DELEGATION_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getDelegationsRowsFromServer = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_DELEGATION_ROWS });
  try {
    let response = await getDelegationsAPI();

    let delegations = response.data && response.data.delegations;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_DELEGATION_ROWS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_DELEGATION_ROWS_FULFILLED,
      payload: { delegations },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_DELEGATION_ROWS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getManagersEmployersFromServer = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_MANAGERS_EMPLOYERS });
  try {
    let response = await getManagersEmployersBySocietyAPI();

    let managersEmployers = response.data && response.data.usuarios;
    let allEmployees = response.data && response.data.allEmployees;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_MANAGERS_EMPLOYERS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_MANAGERS_EMPLOYERS_FULFILLED,
      payload: { managersEmployers, allEmployees },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_MANAGERS_EMPLOYERS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const deleteDelegationAction = (delegation) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.DELETE_REQUEST });

    let response = await deleteDelegationAPI({ Code: delegation });

    if (response.data.status === "OK") {
      dispatch({ type: actionTypes.DELETE_REQUEST_FULFILLED, payload: delegation });
    }

    dispatch({ type: actionTypes.DELETE_REQUEST_REJECTED });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_REQUEST_FULFILLED, payload: delegation });
    dispatch({ type: actionTypes.DELETE_REQUEST_REJECTED });
  }
};