import React from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import { useLocation } from "react-router-dom";
import UploadFilesDialog from "./uploadFilesDialog/index";
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import useDigitalWarehouse from "./useDigitalWarehouse";

const DigitalWarehouse = ({ history }) => {

  const location = useLocation();
  const groupCode = location.search.substring(location.search.indexOf('=') + 1);

  const {
    data,
    isLoading,
    state,
    handlers
  } = useDigitalWarehouse(groupCode);

  const dataTreeButton = (gridRef) => (
    <CustomIconButton
      title={state.treeViewIsOpen ? "Ver como lista" : "Ver como árbol"}
      onClick={() => handlers.handleGrouping(gridRef)}
    >
      <AccountTreeIcon />
    </CustomIconButton>
  )

  const previewButton = (gridRef) => (
    <CustomIconButton
      title={'Previsualizar'}
      onClick={() => handlers.handlePreview(gridRef)}
      type={"preview"}
    />
  )

  const createButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={() => handlers.handleOpenCreateDialog(gridRef)}
      type={"add"}
    />
  )

  const modifyButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(gridRef)}
      type={"edit"}
    />
  )

  const deleteButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />
  )

  const downloadButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("download")}
      onClick={() => handlers.handleDownload(gridRef)}
      type={"download"}
    />
  )

  const goBackButton = () => (
    groupCode &&
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={() => history.push(paths.myGroups)}
      type={"goBack"}
    />
  )

  const getTitle = () => {
    if (!groupCode) return 'Mi bodega digital';

    if (location?.state?.name) {
      return `Bodega digital del grupo: ${location.state.name}`;
    }
    return 'Bodega digital';
  }

  return (
    <CommonPage
      isLoading={isLoading}
      title={getTitle()}
      gridTitle={getTitle()}
      columnDefPage={groupCode ? paths.digitalWarehouse : paths.myDigitalWarehouse}
      rowData={data}
      menuItems={[goBackButton, createButton, modifyButton, dataTreeButton, previewButton, downloadButton, deleteButton]}
      hasExpand
      hasHelp
    >
      {
        (state.createDialogIsOpen || state.editDialogIsOpen) &&
        <UploadFilesDialog
          open={(state.createDialogIsOpen || state.editDialogIsOpen)}
          isEdit={state.editDialogIsOpen}
          loading={isLoading}
          data={state.selectedDocument}
          groupCode={groupCode}
          handleClose={handlers.handleCloseCreateEditDialog}
        />
      }
    </CommonPage>
  )
}

export default DigitalWarehouse;