import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoading, getData } from "../selectors";
import { RESET_STATE } from "../actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import {
  getDigitalWarehouseAction,
  deleteDigitalWarehouseItemAction,
  downloadDigitalWarehouseItemAction
} from "../actions";
import { previewFromURL } from "@icarius-utils/download";
import { RESOURCES_ENDPOINT } from "@icarius-connection/endpoints";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getLocalizedString } from "@icarius-localization/strings";

const useDigitalWarehouse = (groupCode) => {

  const [treeViewIsOpen, setTreeViewIsOpen] = useState(false);
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null); //seleccionado para editar o borrar

  const dispatch = useDispatch();
  const data = useSelector(getData);
  const isLoading = useSelector(getLoading);

  useEffect(() => {
    dispatch(getDigitalWarehouseAction(groupCode ? `?groupCode=${groupCode}` : ''));
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch, groupCode])

  const handleOpenDeleteDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: "¿Desea eliminar el archivo?",
      onConfirm: () => handleDelete(documents[0]),
    }));
  }

  const handleDownload = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    dispatch(downloadDigitalWarehouseItemAction({ internalCode: documents[0].internalCode, groupCode: documents[0].groupCode }));
  }

  const handleOpenCreateDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length > 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro o ninguno', severity: "warning" }));
      return;
    }

    setSelectedDocument(documents.length === 1 ? documents[0] : null);
    setCreateDialogIsOpen(true);
  }

  const handleOpenEditDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    if (!documents[0]?.canEdit) {
      dispatch(openSnackbarAction({ msg: 'No puede editar este registro porque no tiene permiso de edición', severity: "warning" }));
      return;
    }

    setSelectedDocument(documents[0]);
    setEditDialogIsOpen(true);
  }

  const handleCloseCreateEditDialog = () => {
    setSelectedDocument(null);
    setCreateDialogIsOpen(false);
    setEditDialogIsOpen(false);
  }

  const handleGrouping = (gridRef) => {
    if (!treeViewIsOpen) {
      gridRef.columnApi.applyColumnState({
        state: [
          {
            aggFunc: null,
            colId: "ag-Grid-AutoColumn",
            flex: null,
            hide: false,
            pivot: false,
            pivotIndex: null,
            rowGroup: false,
            rowGroupIndex: null,
            sort: "asc",
            sortIndex: 0,
            width: 400,
          },
          {
            colId: 'groupString',
            rowGroup: true,
            hide: true,
          },
          {
            colId: 'folder',
            rowGroup: true,
          },
          {
            colId: 'sfolder',
            rowGroup: true,
          },
          {
            colId: 'year',
            rowGroup: true,
          },
          {
            colId: 'monthString',
            rowGroup: true,
          },
          {
            colId: 'file',
            rowGroup: true,
          }
        ]
      });
      gridRef.api.expandAll();
      gridRef.api.forEachNode(node => {
        if (node.level === 4) {
          node.setExpanded(false);
        }
      });
    } else {
      gridRef.columnApi.applyColumnState({
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          hide: false,
          sort: null,
          rowGroup: null,
          pivot: null,
          pinned: null
        }
      });
    }
    setTreeViewIsOpen(prev => !prev);
  }

  const handleDelete = (document) => {
    dispatch(deleteDigitalWarehouseItemAction({ internalCode: document.internalCode, groupCode: document.groupCode }));
  }

  const handlePreview = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    previewFromURL(RESOURCES_ENDPOINT + documents[0].filePath);
  }

  const state = {
    treeViewIsOpen,
    editDialogIsOpen,
    createDialogIsOpen,
    selectedDocument,
  }

  const handlers = {
    handleOpenDeleteDialog,
    handleDownload,
    handleGrouping,
    handleDelete,
    handlePreview,
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleCloseCreateEditDialog,
  }

  return {
    data,
    isLoading,
    state,
    handlers
  }
}

export default useDigitalWarehouse;