import React, { useState } from "react";
import { Typography, Grid, Checkbox } from "@material-ui/core";
import { ExpandMore, ExpandLess } from "@icarius-icons";
import { getAppColor } from "src/app/selectors";
import { useSelector } from "react-redux";

const GenericSection = ({ instance, mandatoryKeys, handleClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { name, values } = instance;
  const color = useSelector(getAppColor);

  return (
    <div style={{ paddingTop: 10, paddingBottom: 10 }}>
      <Grid container style={{ cursor: "pointer " }} onClick={() => setIsOpen(prev => !prev)}>
        <Grid item xs={11}>
          <Typography style={{ color: color, fontWeight: 800, fontSize: "1.1rem" }}>{name}</Typography>
        </Grid>
        <Grid container item xs={1} justify="center">
          {
            isOpen ? <ExpandLess htmlColor={color} /> : <ExpandMore htmlColor={color} />
          }
        </Grid>
      </Grid>
      <div>
        {
          isOpen ?
            <Grid container style={{ paddingTop: 5 }}>
              {
                values.map(item => {
                  const { keyTipoDocumento, valueTipoDocumento } = item;
                  const isMandatory = mandatoryKeys[keyTipoDocumento];

                  return (
                    <Grid
                      key={keyTipoDocumento}
                      container
                      alignItems="center"
                      onClick={() => handleClick(keyTipoDocumento, !isMandatory)}
                      style={{ cursor: "pointer" }}
                    >
                      <Grid item xs={11}>
                        <Typography className="whiteText">{valueTipoDocumento}</Typography>
                      </Grid>
                      <Grid container item xs={1} justify="center">
                        <Checkbox checked={isMandatory} />
                      </Grid>
                    </Grid>
                  )
                })
              }
            </Grid>
            : null
        }
      </div>
    </div>
  )
}

export default GenericSection;
