import NAME from "./constants";

export const getLoading = store => store[NAME].loading;

export const getGenericFiles = store => store[NAME].genericFiles;

export const getCargos = store => store[NAME].cargos;
export const getInstanciasLaborales = store => store[NAME].instanciasLaborales;
export const getTipos = store => store[NAME].tipos;
export const getRowsByPosition = store => store[NAME].rowsByPosition;
export const getColaboratorFiles = store => store[NAME].colaboratorFiles;