import React, { useState } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
  Grid,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTransition from "@icarius-common/dialogTransition";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import PaperDraggable from "@icarius-common/paperDraggable";

const gridStyle = { height: 75, padding: "0px 10px" };

const GenerateThirdPartyFileDialog = (props) => {

  const { open, thirdPartyFiles, groups, handleConfirm, handleClose } = props;

  const [groupSelected, setGroupSelected] = useState("");
  const [selected, setSelected] = useState("");
  const dispatch = useDispatch();

  const handleAccept = () => {
    if (!selected) {
      dispatch(openSnackbarAction({ msg: 'Debe ingresar los parámetros que son obligatorios', severity: 'warning' }))
      return;
    }

    handleConfirm(selected);
  }

  const filteredThirdPartyFiles = thirdPartyFiles?.filter(item => item.group === groupSelected);

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {'Seleccione la acción especial que desea ejecutar'}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent style={{ paddingTop: 0 }}>
        <Grid container item xs={12}>
          <Grid container item alignItems="center" xs={12} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-groupSelected`}>{"Grupo"}</InputLabel>
              <Select
                value={groupSelected}
                labelId={`label-groupSelected`}
                id={`select-groupSelected`}
                onChange={(e) => {
                  setGroupSelected(e.target.value);
                  setSelected('');
                }}
                margin={"none"}
              >
                {
                  groups?.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" xs={12} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-selected`}>{"Acción especial"}</InputLabel>
              <Select
                disabled={!groupSelected}
                value={selected}
                labelId={`label-selected`}
                id={`select-selected`}
                onChange={(e) => setSelected(e.target.value)}
                margin={"none"}
              >
                {
                  filteredThirdPartyFiles?.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
        <ButtonDialogAction onClick={handleAccept} isAccept text={getLocalizedString("accept")} />
      </DialogActions>
    </Dialog>
  );
}

export default GenerateThirdPartyFileDialog;
