import NAME from "./constants";

export const GET_ROWS = NAME + "/GET_ROWS";
export const GET_ROWS_FULFILLED = NAME + "/GET_ROWS_FULFILLED";
export const GET_ROWS_REJECTED = NAME + "/GET_ROWS_REJECTED";

export const GET_INSTANCES = NAME + "/GET_INSTANCES";
export const GET_INSTANCES_FULFILLED = NAME + "/GET_INSTANCES_FULFILLED";
export const GET_INSTANCES_REJECTED = NAME + "/GET_INSTANCES_REJECTED";

export const UPLOAD_DOCUMENT = NAME + "/UPLOAD_DOCUMENT";
export const UPLOAD_DOCUMENT_FULFILLED = NAME + "/UPLOAD_DOCUMENT_FULFILLED";
export const UPLOAD_DOCUMENT_REJECTED = NAME + "/UPLOAD_DOCUMENT_REJECTED";

export const CLEAR_STATE = NAME + "/CLEAR_STATE";