import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoading, getData, getColaborators } from "../selectors";
import { RESET_STATE } from "../actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import {
  getConsentPublicationsAction,
  deleteConsentPublicationAction,
} from "../actions";
import { getLoading as getDigitalFolderIsLoading } from "@icarius-pages/annexesPage/selectors";
import { getUserData } from "src/app/selectors";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getLocalizedString } from "@icarius-localization/strings";

const useConsentPublication = (groupCode) => {

  const [digitalFolderIsOpen, setDigitalFolderIsOpen] = useState(false);
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null); //seleccionado para editar, o carpeta digital

  const dispatch = useDispatch();
  const data = useSelector(getData);
  const isLoading = useSelector(getLoading);
  const digitalFolderIsLoading = useSelector(getDigitalFolderIsLoading);
  const userData = useSelector(getUserData);
  const colaborators = useSelector(getColaborators);


  useEffect(() => {
    dispatch(getConsentPublicationsAction());
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch, groupCode])

  const handleOpenDigitalFolder = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    const code = documents[0]["Código de empleado"];

    if (code === userData.code) {
      dispatch(openSnackbarAction({ msg: `No posee acceso a la carpeta digital de ${documents[0]["Apellidos y nombres"]}`, severity: "warning" }));
      return;
    }

    if (code) {
      setSelectedDocument(documents[0]);
      setDigitalFolderIsOpen(true);
    }
  };

  const handleCloseDigitalFolder = () => {
    setDigitalFolderIsOpen(false);
    setSelectedDocument(null);
  }

  const handleOpenDeleteDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length < 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar al menos un registro', severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: "¿Desea eliminar el consentimiento?",
      onConfirm: () => handleDelete(documents),
    }));
  }

  const handleOpenCreateDialog = () => {
    setSelectedDocument(null);
    setCreateDialogIsOpen(true);
  }

  const handleOpenEditDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    setSelectedDocument(documents[0]);
    setEditDialogIsOpen(true);
  }

  const handleCloseCreateEditDialog = () => {
    setCreateDialogIsOpen(false);
    setEditDialogIsOpen(false);
    setSelectedDocument(null);
  }

  const handleDelete = (documents) => {
    dispatch(deleteConsentPublicationAction({ internalCodes: documents.map(item => item.internalCode) }));
  }

  const state = {
    editDialogIsOpen,
    createDialogIsOpen,
    selectedDocument,
    digitalFolderIsOpen,
  }

  const handlers = {
    handleDelete,
    handleOpenDeleteDialog,
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleCloseCreateEditDialog,
    handleOpenDigitalFolder,
    handleCloseDigitalFolder,
  }

  return {
    colaborators,
    data,
    isLoading,
    digitalFolderIsLoading,
    state,
    handlers,
  }
}

export default useConsentPublication;