import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  InputLabel,
  Grid,
  Typography
} from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import { uploadDocumentsAction } from "@icarius-pages/digitalFolderAnalysis/actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DialogTransition from "@icarius-common/dialogTransition";
import { formatDateYYYYMMDDHyphen, getTomorrow } from "@icarius-utils/date";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import FileInput from "@icarius-common/fileUpload/fileInput";
import FileList from "@icarius-common/fileUpload/fileList";

const gridStyle = { height: 75, padding: "0px 10px" };

const UploadFilesDialog = (props) => {

  const { open, loading, handleClose, handleAfterFileUpload, instances } = props;

  const [files, setFiles] = useState(null);
  const [name, setName] = useState("");
  const [instance, setInstance] = useState("");
  const [type, setType] = useState("");
  const [expiration, setExpiration] = useState(null);
  const [visibility, setVisibility] = useState(true);
  const [failedDocuments, setFailedDocuments] = useState(null);

  const handleInstanceChange = (newInstance) => {
    setInstance(newInstance);
    setType("");
  }

  const dispatch = useDispatch();

  const handleSubmit = () => {
    setFailedDocuments(null);
    if (dataIsValid()) {
      dispatch(uploadDocumentsAction(files, name, instance, type, visibility ? "Y" : "N", expiration ? formatDateYYYYMMDDHyphen(expiration) : ''))
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(openSnackbarAction({ msg: getLocalizedString("documentUploadedOK"), severity: "success" }));
            handleAfterFileUpload();
            handleClose();
          } else {
            setFailedDocuments(resp.data.invalidFiles)
          }
        });
    } else {
      dispatch(openSnackbarAction({ msg: getLocalizedString("fieldRequeriment"), severity: "error" }));
    }
  };

  const dataIsValid = () => {
    if (name === "") return false;
    if (instance === "") return false;
    if (type === "") return false;
    if (name === "") return false;
    if (files === null) return false;

    return true;
  };

  const handleDeleteFileFromList = (file) => {
    const newFileArray = [...files];
    const indexToDelete = newFileArray.indexOf(file);
    if (indexToDelete > -1) {
      newFileArray.splice(indexToDelete, 1);
      setFiles(newFileArray.length === 0 ? null : newFileArray);
    }
  }

  if (loading) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"md"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {"Carga masiva de archivos en carpeta digital"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Grid container item xs={12}>
            <Grid container item alignItems="center" xs={12} style={{ padding: "0px 10px" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={visibility}
                    onChange={() => setVisibility(prev => !prev)}
                    value={visibility}
                    color="primary"
                  />
                }
                label={'Disponible para colaborador'}
                labelPlacement="start"
                style={{ marginLeft: 0 }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id="instance-label">{'Instancia'}</InputLabel>
                <Select
                  fullWidth
                  value={instance}
                  labelId="instance-label"
                  id="instance-select"
                  onChange={(e) => handleInstanceChange(e.target.value)}
                  margin={"none"}
                >
                  {
                    instances.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id="type-label">{'Tipo'}</InputLabel>
                <Select
                  fullWidth
                  value={type}
                  labelId="type-label"
                  id="type-select"
                  onChange={(e) => setType(e.target.value)}
                  margin={"none"}
                >
                  {
                    instances.find(item => item.key === instance)?.types.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                required
                value={name}
                onChange={e => setName(e.target.value.toString().slice(0, 100))}
                fullWidth
                style={{ marginBottom: 20 }}
                label={'Texto'}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <KeyboardDatePicker
                  style={{ marginTop: 0, marginBottom: 20, width: "100%" }}
                  label={getLocalizedString("annexFechaVencimiento")}
                  clearable
                  clearLabel={getLocalizedString("clear")}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  disablePast
                  minDate={getTomorrow()}
                  format="dd/MM/yyyy"
                  margin="normal"
                  invalidDateMessage=''
                  minDateMessage=''
                  maxDateMessage=''
                  value={expiration}
                  onChange={(e) => setExpiration(e)}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Typography style={{ marginTop: 5, marginBottom: 30 }} className="whiteText" variant="subtitle2">
              {'El texto que se ingresa será el nombre del archivo que se carga'}
            </Typography>
          </Grid>
          <FileInput
            acceptedFormats={['.jpg', '.bmp', '.png', '.jpeg', '.rar', '.zip', '.gz', '.tar', '.xls', '.xlsx', '.txt', '.rtf', '.doc', '.docx', '.pdf', '.html']}
            extraLabel={getLocalizedString("filesMustStartWithCode")}
            handleChange={(files) => setFiles(files)}
            preSubmitCallback={() => setFailedDocuments(null)}
            multiple
          />
          <FileList
            files={files}
            filesWithError={failedDocuments}
            handleClick={handleDeleteFileFromList}
            multiple
          />
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
          <ButtonDialogAction onClick={handleSubmit} isAccept text={getLocalizedString("uploadButtonText")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default UploadFilesDialog;
