import React, { useState, useMemo } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  Button,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { useDispatch, useSelector } from "react-redux";
import { createConsentPublicationAction, updateConsentPublicationAction } from "../../actions";
import { getConsentTypes, getBenefitConsent } from "../../selectors";
import { AddIcon, PersonIcon } from "@icarius-icons/index";
import UserSelectionDialog from "@icarius-common/userSelectionDialog";
import { getAppColor } from "src/app/selectors";
import ProfileImg from "@icarius-common/profileImg";
import GridSelectionDialog from "../gridSelectionDialog";

const gridStyle = { height: 75, padding: "0px 10px" };

const CreateEditDialog = (props) => {

  const {
    open,
    colaborators,
    loading,
    data,
    handleClose,
  } = props;

  const [userSelectionDialogIsOpen, setUserSelectionDialogIsOpen] = useState(false);
  const [gridSelectionDialogIsOpen, setGridSelectionDialogIsOpen] = useState(false);

  const dispatch = useDispatch();
  const color = useSelector(getAppColor);
  const consentTypes = useSelector(getConsentTypes);
  const benefitConsent = useSelector(getBenefitConsent);

  const employees = useMemo(() => {
    return colaborators.map(item => ({
      key: item['CODIGO DE EMPLEADO'],
      img: item['FOTOGRAFIA'] !== '-' ? item['FOTOGRAFIA'] : '',
      name: item['APELLIDO Y NOMBRE'],
    }))
  }, [colaborators]);

  const create = (dataToSend) => {
    dispatch(createConsentPublicationAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    dispatch(updateConsentPublicationAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const openDateValidationError = () => {
    dispatch(openSnackbarAction({ msg: 'La fecha de publicación debe ser igual o mayor que la fecha actual', severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit,
  } = useHandleForm(data, create, modify, openValidationError, openDateValidationError);

  const consentTypesToUse = useMemo(() => {
    if (!isCreate || !benefitConsent) return consentTypes;
    return consentTypes.filter((item) => item.key !== benefitConsent);
  }, [consentTypes, benefitConsent, isCreate])

  if (loading) return null;

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={DialogTransition}
        PaperComponent={PaperDraggable}
        maxWidth={"sm"}
        fullWidth={true}
      >
        <div className={"dialog-container"}>
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            {isCreate ? "Crear publicación" : "Editar publicación"}
            <DialogTitleDivider />
          </DialogTitle>
          <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
          <DialogContent style={{ paddingTop: 0 }}>
            <Grid container item xs={12}>
              <Grid container item alignItems="center" xs={12} style={gridStyle}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel required id={`label-type`}>{"Tipo de consentimiento"}</InputLabel>
                  <Select
                    disabled={!isCreate}
                    value={formData['Tipo de consentimiento']}
                    labelId={`label-type`}
                    id={`select-type`}
                    onChange={(e) => setFormValue(e.target.value, 'Tipo de consentimiento')}
                    margin={"none"}
                  >
                    {
                      consentTypesToUse.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid container item alignItems="center" xs={12} style={gridStyle}>
                <TextField
                  required
                  fullWidth
                  label={'Referencia de publicación'}
                  margin={"none"}
                  value={formData['Referencia de publicación']}
                  onChange={(e) => setFormValue(e.target.value, 'Referencia de publicación')}
                />
              </Grid>
              <Grid container item alignItems="center" xs={12} style={gridStyle}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <DatePicker
                    disabled={!isCreate && data.received}
                    fullWidth
                    required
                    label={'Fecha de publicación'}
                    cancelLabel={getLocalizedString("cancel")}
                    okLabel={getLocalizedString("ok")}
                    invalidDateMessage=""
                    minDateMessage={isCreate ? "La fecha debe ser igual o mayor que la fecha actual" : ""}
                    format="dd/MM/yyyy"
                    margin="none"
                    value={formData['Fecha de publicación']}
                    minDate={(isCreate || !data.received) ? new Date() : null}
                    onChange={(e) => setFormValue(e, 'Fecha de publicación')}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid container item alignItems='center' xs={12}>
                {
                  isCreate &&
                  <>
                    <Grid container item xs={12} justify='center' alignItems='center' style={{ height: 52 }}>
                      <Button
                        variant='contained'
                        className='whiteText'
                        onClick={() => setUserSelectionDialogIsOpen(true)}
                        startIcon={<PersonIcon htmlColor={color} />}
                      >
                        Seleccionar colaboradores
                      </Button>
                    </Grid>
                    <Grid container item xs={12} justify='center' alignItems='center' style={{ height: 52 }}>
                      <Button
                        variant='contained'
                        className='whiteText'
                        onClick={() => setGridSelectionDialogIsOpen(true)}
                        startIcon={<AddIcon htmlColor={color} />}
                      >
                        Lista de colaboradores
                      </Button>
                    </Grid>
                  </>
                }
                <Grid container xs={12} item style={{ maxHeight: 200 }}>
                  {
                    formData['Código de empleado']?.map((employeeCode, index) => {
                      const employeeSelected = employees?.find(item => item.key === employeeCode);
                      const userImage = employeeSelected?.img;

                      return (
                        <Grid key={index} container xs={12} item style={{ margin: 10 }}>
                          <Grid item xs={2}>
                            <ProfileImg
                              image={userImage !== '-' ? userImage : ''}
                              size={40}
                            />
                          </Grid>
                          <Grid container item xs={8} alignItems='center'>
                            <Typography variant="h6" className="whiteText" style={{ fontWeight: 100, fontSize: 16 }}>
                              {employeeSelected?.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      )
                    })
                  }
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
            <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
          </DialogActions>
        </div>
      </Dialog>
      {
        userSelectionDialogIsOpen &&
        <UserSelectionDialog
          open={userSelectionDialogIsOpen}
          initialData={formData['Código de empleado']} //array de strings con key del primero seleccionado, si no hay, [] o [""]
          users={employees}
          title={'Seleccionar colaboradores'}
          selectedUsersTitle={'Seleccionados'}
          handleSubmit={(data) => setFormValue(data, 'Código de empleado')}
          handleClose={() => setUserSelectionDialogIsOpen(false)}
        />
      }
      {
        gridSelectionDialogIsOpen &&
        <GridSelectionDialog
          open={gridSelectionDialogIsOpen}
          preselected={formData['Código de empleado']} //array de strings con key del primero seleccionado, si no hay, [] o [""]
          data={colaborators}
          handleClose={() => setGridSelectionDialogIsOpen(false)}
          handleConfirm={(data) => setFormValue(data, 'Código de empleado')}
        />
      }
    </>
  );
}

export default CreateEditDialog;
