import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getLocalizedString } from "@icarius-localization/strings";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getGenericFilesForDigitalFolderEndpoint,
  getFilesByPositionForDigitalFolderEndpoint,
  updateGenericFilesForDigitalFolderEndpoint,
  addFileForPositionForDigitalFolderEndpoint,
  updateFileForPositionForDigitalFolderEndpoint,
  deleteGenericFilesForDigitalFolderEndpoint,
  getColaboratorFilesForDigitalFolderEndpoint,
  updateColaboratorFilesForDigitalFolderEndpoint,
} from "@icarius-connection/api";

//GETS
export const getGenericFilesForDigitalFolderAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_GENERIC });
  try {
    let response = await getGenericFilesForDigitalFolderEndpoint();

    dispatch({
      type: actionTypes.GET_GENERIC_FULFILLED,
      payload: response.data.result,
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_GENERIC_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getColaboratorFilesForDigitalFolderAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_COLABORATOR_FILES });
  try {
    let response = await getColaboratorFilesForDigitalFolderEndpoint();

    dispatch({
      type: actionTypes.GET_COLABORATOR_FILES_FULFILLED,
      payload: response.data.result,
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_COLABORATOR_FILES_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getFilesByPositionForDigitalFolderAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_BY_POSITION });
  try {
    let response = await getFilesByPositionForDigitalFolderEndpoint();
    const { cargos, instanciasLaborales, tipos, result } = response.data;

    dispatch({
      type: actionTypes.GET_BY_POSITION_FULFILLED,
      payload: {
        cargos,
        instanciasLaborales,
        tipos,
        rowsByPosition: result,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_BY_POSITION_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

//UPDATES
export const updateGenericFilesForDigitalFolderAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_GENERIC });
  try {
    let response = await updateGenericFilesForDigitalFolderEndpoint(data);

    dispatch({
      type: actionTypes.UPDATE_GENERIC_FULFILLED,
      payload: response.data.result,
    });

    dispatch(openSnackbarAction({ msg: getLocalizedString("genericsUpdatedOK"), severity: "success" }));

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPDATE_GENERIC_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const updateColaboratorFilesForDigitalFolderAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_COLABORATOR_FILES });
  try {
    let response = await updateColaboratorFilesForDigitalFolderEndpoint(data);

    dispatch({
      type: actionTypes.UPDATE_COLABORATOR_FILES_FULFILLED,
      payload: response.data.result,
    });

    dispatch(openSnackbarAction({ msg: getLocalizedString("genericsUpdatedOK"), severity: "success" }));

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPDATE_COLABORATOR_FILES_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const updateFileForPositionForDigitalFolderAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_FOR_POSITION });
  try {
    let response = await updateFileForPositionForDigitalFolderEndpoint(data);

    dispatch({
      type: actionTypes.UPDATE_FOR_POSITION_FULFILLED,
      payload: response.data.result,
    });

    dispatch(openSnackbarAction({ msg: getLocalizedString("documentUpdatedOK"), severity: "success" }));

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPDATE_FOR_POSITION_REJECTED, payload: e });
    if (e.response?.data?.status === "DUPLICATED_DATA") {
      dispatch(openSnackbarAction({ msg: getLocalizedString("documentSamePositionInstanceError"), severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
  }
};

//ADD
export const addFileForPositionForDigitalFolderAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.ADD_FOR_POSITION });
  try {
    let response = await addFileForPositionForDigitalFolderEndpoint(data);

    dispatch({
      type: actionTypes.ADD_FOR_POSITION_FULFILLED,
      payload: response.data.result,
    });

    dispatch(openSnackbarAction({ msg: getLocalizedString("documentCreatedOK"), severity: "success" }));

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.ADD_FOR_POSITION_REJECTED, payload: e });
    if (e.response?.data?.status === "DUPLICATED_DATA") {
      dispatch(openSnackbarAction({ msg: getLocalizedString("documentSamePositionInstanceError"), severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
  }
};


//DELETE
export const deleteFileForPositionForDigitalFolderAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_FOR_POSITION });
  try {
    let response = await deleteGenericFilesForDigitalFolderEndpoint(data);

    dispatch({
      type: actionTypes.DELETE_FOR_POSITION_FULFILLED,
      payload: response.data.result,
    });

    dispatch(openSnackbarAction({ msg: getLocalizedString("documentDeletedOK"), severity: "success" }));

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_FOR_POSITION_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};
