import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getValidationData, getCalendarList, getDateFormat, getAutomaticProcessesEnabled } from "../../selectors";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getLocalizedString } from "@icarius-localization/strings";
import { formatDateYYYYMM } from "@icarius-utils/date";
import {
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import GridContent from "../dialogs/gridContent";
import { CLEAR_EMPLOYEES } from "../../actionTypes";
import useHandleForm from './useHandleForm';

const gridStyle = { height: 75, padding: "0px 10px" };

const ProcessForm = (props) => {

  const { data, handleClose, createProcess, modifyProcess } = props;
  const agGridRef = useRef(null);

  const dispatch = useDispatch();
  const automaticProcessesEnabled = useSelector(getAutomaticProcessesEnabled);
  const validationData = useSelector(getValidationData);
  const dateFormat = useSelector(getDateFormat)
  const calendarList = useSelector(getCalendarList);

  useEffect(() => {
    return () => {
      dispatch({ type: CLEAR_EMPLOYEES });
    }
  }, [dispatch])

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error" }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit,
    getCodeHasValidChar,
  } = useHandleForm(data, createProcess, modifyProcess, openValidationError, validationData, agGridRef, automaticProcessesEnabled, calendarList);

  const createCalendarList = () => {
    let filteredCalendar = [...(calendarList || [])];
    if (formData.period) {
      filteredCalendar = filteredCalendar.filter(item => item.period === formatDateYYYYMM(formData.period));
    }

    if (formData.sub === '-' || formData.sub === '') {
      filteredCalendar = filteredCalendar.filter(item => item.type === 'M');
    } else {
      filteredCalendar = filteredCalendar.filter(item => item.type === formData.sub);
    }

    return filteredCalendar;
  }

  return (
    <>
      <DialogContent style={{ paddingTop: 0 }}>
        <Grid container direction="row" justify="center" spacing={2}>
          <Grid item xs={12} sm={automaticProcessesEnabled ? 6 : 12} style={{...gridStyle, marginTop: 14 }}> {/* code */}
            <TextField
              disabled={!isCreate}
              fullWidth
              required
              value={formData.code}
              onChange={(e) => setFormValue(e.target.value, 'code')}
              inputProps={{ maxLength: 20 }}
              label={getLocalizedString("processCode")}
            />
            <Grid container alignItems="center" item xs={12} style={{ paddingTop: 5 }}>
              <Typography
                className={getCodeHasValidChar(formData.code) ? "whiteText" : "errorColor"}
                variant="caption"
                gutterBottom>
                {'Permitido letras, numeros y guión (-)'}
              </Typography>
            </Grid>
          </Grid>
          {
            automaticProcessesEnabled &&
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* proceso por defecto */}
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id="defaultProcess">{'Proceso por defecto'}</InputLabel>
                <Select
                  fullWidth
                  value={formData.defaultProcess}
                  labelId="defaultProcess"
                  id="defaultProcessId"
                  onChange={(e) => setFormValue(e.target.value, 'defaultProcess')}
                  margin={"none"}
                >
                  {
                    [{ key: "SI", value: "SI" }, { key: "NO", value: "NO" }].map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>              
            </Grid>
          }
          <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* tipo */}
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id="type">{getLocalizedString("processType")}</InputLabel>
              <Select
                fullWidth
                value={formData.type}
                labelId="type"
                id="typeId"
                onChange={(e) => setFormValue(e.target.value, 'type')}
                margin={"none"}
              >
                {
                  validationData.process_types.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.id}
                      value={item.id}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* subtipo */}
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id="processSubtype">{getLocalizedString("processSubtype")}</InputLabel>
              <Select
                fullWidth
                value={formData.sub}
                labelId="processSubtype"
                id="processSubtypeId"
                onChange={(e) => setFormValue(e.target.value, 'sub')}
                margin={"none"}
              >
                {
                  validationData.subprocess_types.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.id}
                      value={item.id}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* date from */}
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <DatePicker
                required
                fullWidth
                label={getLocalizedString("processDateFrom")}
                invalidDateMessage=''
                format="dd/MM/yyyy"
                margin="none"
                value={formData.dateFrom}
                onChange={(e) => setFormValue(e, 'dateFrom')}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* date to */}
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <DatePicker
                required
                fullWidth
                label={getLocalizedString("processDateTo")}
                invalidDateMessage=''
                format="dd/MM/yyyy"
                margin="none"
                minDate={formData.dateFrom}
                value={formData.dateTo}
                onChange={(e) => setFormValue(e, 'dateTo')}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* period */}
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <DatePicker
                required
                fullWidth
                label={getLocalizedString("processPeriod")}
                invalidDateMessage=''
                format="yyyy/MM"
                margin="none"
                views={["month", "year"]}
                value={formData.period}
                onChange={(e) => setFormValue(e, 'period')}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* payment date */}
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <DatePicker
                required
                fullWidth
                label={getLocalizedString("processPaymentDate")}
                invalidDateMessage=''
                format="dd/MM/yyyy"
                margin="none"
                value={formData.paymentDate}
                minDate={formData.dateFrom}
                onChange={(e) => setFormValue(e, 'paymentDate')}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* description */}
            <TextField
              fullWidth
              required
              label={getLocalizedString("processDescription")}
              value={formData.description}
              onChange={(e) => setFormValue(e.target.value, 'description')}
              inputProps={{ maxLength: 60 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}> {/* calendar */}
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="calendar">{'Calendario de tiempos'}</InputLabel>
              <Select
                fullWidth
                value={formData.calendar}
                labelId="calendar"
                id="calendarId"
                onChange={(e) => setFormValue(e.target.value, 'calendar')}
                margin={"none"}
              >
                <MenuItem className={"whiteText"} value={''}>
                  {'Ninguno'}
                </MenuItem>
                {
                  createCalendarList().map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}
                    >
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}> {/* calendar from */}
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <DatePicker
                label={'Fecha desde de tiempos'}
                disabled
                invalidDateMessage=''
                format="dd/MM/yyyy"
                margin="none"
                fullWidth
                value={formData.calendarFrom}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}> {/* calendar to */}
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <DatePicker
                label={'Fecha hasta de tiempos'}
                disabled
                invalidDateMessage=''
                format="dd/MM/yyyy"
                margin="none"
                fullWidth
                value={formData.calendarTo}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid container item xs={12}>
            {
              formData.type &&
              <Grid container direction="row">
                <Grid container item xs={12} style={{ paddingTop: 10 }}>
                  <Typography className="whiteText" variant="subtitle2">
                    {getLocalizedString("processGridLabel")}
                  </Typography>
                </Grid>
                <Grid container item xs={12} style={{ paddingTop: 10 }}>
                  <GridContent agGridRef={agGridRef} dateFormat={dateFormat} />
                </Grid>
              </Grid>
            }
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={() => handleClose(false)} text={getLocalizedString("disagree")} />
        <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString(!isCreate ? "save" : "create")} />
      </DialogActions>
    </>
  );
}

export default ProcessForm;
