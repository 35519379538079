import { useState, useEffect } from "react";
import { createDateFromDDMMYYYY, createDateFromYYYYMM, formatDateYYYYMM, formatDateYYYYMMDD } from "@icarius-utils/date";
import { useDispatch } from "react-redux";
import { getProcessesEmployeesAction } from "@icarius-pages/currentProcesses/actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback, validationData, agGridRef, automaticProcessesEnabled, calendarList) => {

  const dispatch = useDispatch();

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === 'code') return isCreate ? '' : data["Código de proceso"];
      if (fieldName === 'type') return isCreate ? '' : data["id_tliqpro"];
      if (fieldName === 'sub') return isCreate ? '' : data["id_subpro"];
      if (fieldName === 'description') return isCreate ? '' : data["Descripción"];
      if (fieldName === 'defaultProcess') return isCreate ? '' : data["Proceso por defecto"];
      if (fieldName === 'calendar') return isCreate ? '' : data["calendar"];
      if (fieldName === 'dateFrom') return isCreate ? null : createDateFromDDMMYYYY(data["Fecha desde"]);
      if (fieldName === 'dateTo') return isCreate ? null : createDateFromDDMMYYYY(data["Fecha hasta"]);
      if (fieldName === 'period') return isCreate ? null : createDateFromYYYYMM(data["Período"]);
      if (fieldName === 'paymentDate') return isCreate ? null : createDateFromDDMMYYYY(data["Fecha de pago"]);
      if (fieldName === 'calendarFrom') return data?.calendarFrom ? createDateFromDDMMYYYY(data["calendarFrom"]) : null;
      if (fieldName === 'calendarTo') return data?.calendarTo ? createDateFromDDMMYYYY(data["calendarTo"]) : null;
    }

    const fieldNames = [
      'code',
      'type',
      'sub',
      'dateFrom',
      'dateTo',
      'period',
      'paymentDate',
      'description',
      'defaultProcess',
      'calendar',
      'calendarFrom',
      'calendarTo',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  useEffect(() => {
    if (formData.type && formData.dateFrom && formData.dateTo) {
      dispatch(getProcessesEmployeesAction(formData.type, (!isCreate && formData.code) || null, formatDateYYYYMMDD(formData.dateFrom), formatDateYYYYMMDD(formData.dateTo)));
    }
  }, [formData.code, formData.type, formData.dateFrom, formData.dateTo, isCreate, dispatch])

  const getDataIsComplete = () => {
    const { code, type, sub, dateFrom, dateTo, period, paymentDate, description } = formData;
    return code && type && sub && dateFrom && dateTo && period && paymentDate && description;
  }

  const getCodeHasValidChar = () => {
    const codeRegex = /^[a-zA-Z]+[-A-Z0-9]*$/;

    if (!formData.code || formData.code === "" || !codeRegex.test(formData.code)) return false;
    return true;
  }

  const getCodeIsInUse = () => {
    if (!isCreate) return false;
    if (!formData.code || formData.code === "") return false;

    return validationData.used_process_codes.some(codeInUse => codeInUse === formData.code);
  }

  const submit = async () => {
    if (getCodeIsInUse()) {
      dispatch(openSnackbarAction({ msg: "El código de proceso ya existe para otro proceso de cálculo", severity: "error" }));
    } else {
      if (getDataIsComplete() && !getCodeIsInUse() && getCodeHasValidChar()) {

        const selectedEmployees = agGridRef.current.api.getSelectedRows().map(item => item["Código de empleado"]) || [];

        const dataToSend = {
          "codpro": formData.code,
          "tliqpro": formData.type,
          "subpro": formData.sub,
          "fdespro": formatDateYYYYMMDD(formData.dateFrom),
          "fhaspro": formatDateYYYYMMDD(formData.dateTo),
          "perpro": formatDateYYYYMM(formData.period),
          "fpagpro": formatDateYYYYMMDD(formData.paymentDate),
          "despro": formData.description,
          "employee_codes": selectedEmployees,
          "calendar": formData.calendar,
        };

        if (automaticProcessesEnabled) {
          dataToSend.defaultProcess = formData.defaultProcess === "SI";
        }

        if (!isCreate) {
          modifyCallback(dataToSend);
        } else {
          createCallback(dataToSend);
        }

      } else {
        invalidDataCallback();
      }
    }
  }

  const setFormValue = (value, fieldName) => {
    if (fieldName === 'dateTo') {
      setFormData({
        ...formData,
        [fieldName]: value,
      });
      return;
    }

    if (fieldName === 'type') {
      setFormData({
        ...formData,
        [fieldName]: value,
      });
      return;
    }

    if (fieldName === 'calendar') {
      let newCalendarFrom = null;
      let newCalendarTo = null;

      if (value) {
        const calendar = calendarList?.find(item => item.key === value);
        if (calendar) {
          newCalendarFrom = createDateFromDDMMYYYY(calendar.from);
          newCalendarTo = createDateFromDDMMYYYY(calendar.to);
        }
      }

      setFormData({
        ...formData,
        [fieldName]: value,
        calendar: value,
        calendarFrom: newCalendarFrom,
        calendarTo: newCalendarTo,
      });
      return;
    }

    if (fieldName === 'sub' || fieldName === 'period') {
      setFormData({
        ...formData,
        [fieldName]: value,
        calendar: '',
        calendarFrom: null,
        calendarTo: null,
      });
      return;
    }

    if (fieldName === 'dateFrom') {
      if (value) {
        //si se cambia la fecha desde, setear la fecha fin con el ultimo dia de ese mes
        const newDateTo = new Date(value.getFullYear(), value.getMonth() + 1, 0);
        const newPaymentDate = new Date(value.getFullYear(), value.getMonth() + 1, 0);
        const newPeriod = new Date(value.getFullYear(), value.getMonth(), 1);

        setFormData({
          ...formData,
          [fieldName]: value,
          dateTo: newDateTo,
          paymentDate: newPaymentDate,
          period: newPeriod,
          calendar: '',
          calendarFrom: null,
          calendarTo: null,
        });
        return;
      } else {
        //si no hay fecha desde, devolver la fin tal cual está
        //si no hay fecha desde, devolver el periodo tal cual está
        //si no hay fecha desde, devolver el calendar tal cual está
        //si no hay fecha desde, devolver el calendarFrom tal cual está
        //si no hay fecha desde, devolver el calendarTo tal cual está
        //si no hay fecha desde, devolver la fecha de pago tal cual está
        setFormData({
          ...formData,
          [fieldName]: value,
        });
      }
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return {
    isCreate,
    formData,
    setFormValue,
    submit,
    getCodeHasValidChar,
  };
}

export default useHandleForm;
