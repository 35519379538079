import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTransition from "@icarius-common/dialogTransition";
import { getExitTypes } from "../../selectors";
import useHandleForm from "./useHandleSimulationForm";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { createDateFromDDMMYYYY } from "@icarius-utils/date";

const gridStyle = { height: 75, padding: "0px" };

const SimulationDialog = (props) => {

  const { open, startDate, endDate, title, handleConfirm, handleClose } = props;

  const dispatch = useDispatch();
  const exitTypes = useSelector(getExitTypes);

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const { formData, setFormValue, submit } = useHandleForm(createDateFromDDMMYYYY(endDate), handleConfirm, openValidationError);

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      open={open}
      scroll={"paper"}
      fullWidth
      maxWidth={"sm"}>
      <DialogTitle>
        {title}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent style={{ paddingTop: 0 }}>
        <Grid container item xs={12} direction='row'>
          <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* codigo */}
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <DatePicker
                required
                fullWidth
                label={'Fecha de egreso'}
                cancelLabel={getLocalizedString("cancel")}
                okLabel={getLocalizedString("ok")}
                invalidDateMessage=''
                format="dd/MM/yyyy"
                margin="normal"
                value={formData.feceg}
                onChange={(e) => setFormValue(e, "feceg")}
                minDate={createDateFromDDMMYYYY(startDate)}
                maxDate={createDateFromDDMMYYYY(endDate)}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* Motivo */}
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-motive`}>{"Motivo de egreso"}</InputLabel>
              <Select
                value={formData.motive}
                labelId={`label-motive`}
                id={`select-motive`}
                onChange={(e) => setFormValue(e.target.value, "motive")}
                margin={"none"}
              >
                {
                  exitTypes && exitTypes.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
        <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
      </DialogActions>
    </Dialog>
  );
}

export default SimulationDialog;
