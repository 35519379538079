import React from "react";
import { Typography } from "@material-ui/core";
import { getAppColor } from "src/app/selectors";
import { useSelector } from "react-redux";

const SectionTitle = ({ title }) => {

    const color = useSelector(getAppColor);

    return (
        <div style={{ borderBottom: `1px solid ${color}`, marginBottom: 10 }}>
            <Typography style={{ fontSize: "1.2rem" }} className="whiteText">{title}</Typography>
        </div>
    )
}

export default SectionTitle;
