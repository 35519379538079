import React from "react";
import { useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { getLoading } from "../selectors";
import { Grid } from "@material-ui/core";
import CommonPage from "@icarius-common/commonPage";
import paths from "@icarius-localization/paths";
import GenericsContainer from "./generics/genericsContainer";
import ByPositionContainer from "./byPosition/byPositionContainer";
import UploadedByCollaboratorsThemselvesContainer from "./uploadedByCollaboratorsThemselves/uploadedByCollaboratorsThemselvesContainer";

const DigitalFolderSettings = () => {

  const isLoading = useSelector(getLoading);

  return (
    <CommonPage
      title={getLocalizedString("digitalFolderSettingsPageTitle")}
      isLoading={isLoading}
      isNotGridPage
      columnDefPage={paths.digitalFolderSettings}
    >
      <Grid container style={{ width: "100%" }}>
        <Grid style={{ padding: 25 }} item xs={12} md={6}>
          <GenericsContainer />
        </Grid>
        <Grid style={{ padding: 25 }} item xs={12} md={6}>
          <UploadedByCollaboratorsThemselvesContainer />
        </Grid>
        <Grid style={{ padding: 25 }} item xs={12}>
          <ByPositionContainer />
        </Grid>
      </Grid>
    </CommonPage>
  );
}

export default DigitalFolderSettings;
