import React, { useState } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
  Typography,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTransition from "@icarius-common/dialogTransition";

const ApproveDialog = (props) => {

  const [isPhaseOne, setIsPhaseOne] = useState(true);

  const { open, data, handleClose, handleApprove } = props;

  const submit = () => {
    if (isPhaseOne) {
      setIsPhaseOne(false);
    } else {
      handleApprove();
      handleClose();
    }
  }

  const phaseOneContent = (
    <Typography className="whiteText" style={{ fontSize: '1.1rem' }}>
      {'¿Está seguro que desea aprobar el proceso de cálculo y pasarlo al histórico?'}
    </Typography>
  )

  const phaseTwoContent = (
    <>
      <Typography className="whiteText" style={{ marginBottom: 16, fontSize: '1.4rem', fontWeight: 600 }}>
        {'ATENCIÓN:'}
      </Typography>
      <Typography className="whiteText">
        {'Aprobar un proceso y pasarlo al histórico significa que ya no podrá realizar recálculos ni alterar sus valores.'}
      </Typography>
      <Typography className="whiteText">
        {'Se entendrá que usted ya ha emitido todos los reportes relacionados al proceso, que ha realizado todos los pagos respectivos (Tanto a los colaboradores como a terceros: Bancos, instituciones de recaudación previsional, etc) y que también lo ha contabilizado.'}
      </Typography>
      <Typography className="whiteText" style={{ marginTop: 16 }}>
        {'La probación del proceso guardará todos los datos en los históricos para posteriores consultas y actualizará los acumuladores que son fundamentales para los procesos de cálculo siguientes. Un proceso en estado histórico solo le permitirá: Consultar los resultados del proceso, acceder al tablero de indicadores del mismo y emitir recibos o comprobantes, pero NO permtirá realizar recálculos.'}
      </Typography>
      <Typography className="whiteText" style={{ marginTop: 16, fontSize: '1.4rem', fontWeight: 600 }}>
        {'¿Desea aprobar el proceso y pasarlo a histórico?'}
      </Typography>
    </>
  )

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      open={open}
      scroll={"paper"}
      maxWidth={"md"}
      fullWidth
    >
      <DialogTitle>
        {`Aprobación del proceso: ${data['Descripción']}`}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent style={{ paddingTop: 0 }}>
        {isPhaseOne ? phaseOneContent : phaseTwoContent}
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
        <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
      </DialogActions>
    </Dialog>
  );
}

export default ApproveDialog;
