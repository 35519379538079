import { useState } from "react";

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = ["code", "name", "type"];
    
    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })      
    }, {});
  }

  const [ formData, setFormData ] = useState(createInitialData());

  const dataIsValid = () => {
    const { code, type, name } = formData;

    if(!code || code === "") return false;
    if(!name || name === "") return false;
    if(!type || type === "") return false;
    return true;
  }

  const submit = () => {
    if(dataIsValid()){
      let dataToSend = { ...formData };

      if(!isCreate){
        dataToSend = { ...dataToSend, internalCode: data.internalCode };
      }

      isCreate ? createCallback(dataToSend) : modifyCallback(dataToSend);
      return true;
    }else{
      invalidDataCallback();
      return false;
    }
  }

  const formatValue = (value, fieldName) => {
    if(fieldName === "code"){
      return value.length > 0 ? value.replace(/\s/g, "").toUpperCase() : "";
    }
    
    return value;
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: formatValue(value, fieldName)
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
