import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getContractorsAction } from "../actions";
import { getLoading, getData, getTypes, getUserFieldsDefinition } from "../selectors";
import { getAppColor } from "src/app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import { Grid } from "@material-ui/core";
import DataItem from "./data/dataItem";
import { RESET_STATE } from "../actionTypes";
import ABMLayout from "@icarius-common/abmComponents/abmLayout";
import paths from "@icarius-localization/paths";

const ContractorSettings = ({ history }) => {

  const [createIsOpen, setCreateIsOpen] = useState(false);

  const dispatch = useDispatch();

  const color = useSelector(getAppColor);
  const data = useSelector(getData);
  const types = useSelector(getTypes);
  const userFieldsDefinition = useSelector(getUserFieldsDefinition);
  const isLoading = useSelector(getLoading);

  useEffect(() => {
    dispatch(getContractorsAction());
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch])

  const getDataIsEmpty = () => {
    return !data || data.length === 0;
  }

  const handleGoBack = () => {
    history.push(paths.thirdPartySettings);
  }

  return (
    <ABMLayout
      title={getLocalizedString("contractorSettings")}
      isLoading={isLoading}
      showCreateButton={!createIsOpen}
      createButtonTitle={getLocalizedString("newContractor")}
      createButtonOnClick={() => setCreateIsOpen(true)}
      showNoData={getDataIsEmpty()}
      noDataTitle={getLocalizedString("noContractors")}
      noDataCreateTitle={getLocalizedString("newContractor")}
      noDataOnClick={() => setCreateIsOpen(true)}
      showGoBack
      handleGoBack={handleGoBack}
    >
      {
        createIsOpen &&
        <Grid container item xs={12} style={{ margin: "10px 0px" }}>
          <DataItem
            color={color}
            data={null}
            types={types}
            userFieldsDefinition={userFieldsDefinition}
            closeNew={() => setCreateIsOpen(false)}
          />
        </Grid>
      }
      {
        data && data.map((item) => {
          return (
            <Grid container item xs={12} style={{ margin: "10px 0px" }} key={item.internalCode}>
              <DataItem
                data={item}
                types={types}
                color={color}
                userFieldsDefinition={userFieldsDefinition}
              />
            </Grid>
          )
        })
      }
    </ABMLayout>
  )
}

export default ContractorSettings;