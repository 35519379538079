import React from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import CreateEditDialog from "./createEditDialog/index";
import { AnnexesPage } from "@icarius-pages/annexesPage";
import useConsentPublication from "./useConsentPublication";
import { Folder } from "@icarius-icons";

const ConsentPublication = ({ history }) => {

  const {
    colaborators,
    data,
    isLoading,
    digitalFolderIsLoading,
    state,
    handlers,
  } = useConsentPublication();

  const createButton = () => (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={handlers.handleOpenCreateDialog}
      type={"add"}
    />
  )

  const modifyButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(gridRef)}
      type={"edit"}
    />
  )

  const deleteButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />
  )

  const digitalFolderItem = (gridRef) => (
    <CustomIconButton
      title={'Carpeta digital'}
      onClick={() => handlers.handleOpenDigitalFolder(gridRef)}
    >
      <Folder />
    </CustomIconButton>
  )

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={() => history.push(paths.consentsScreenSelector)}
      type={"goBack"}
    />
  )

  return (
    <>
      {
        state.digitalFolderIsOpen &&
        <AnnexesPage
          employeeCode={state.selectedDocument["Código de empleado"]}
          employeeName={state.selectedDocument["Apellidos y nombres"]}
          handleClose={handlers.handleCloseDigitalFolder}
        />
      }
      <div style={{ display: state.digitalFolderIsOpen ? "none" : "block" }}>
        <CommonPage
          isLoading={isLoading || digitalFolderIsLoading}
          title={'Publicación de consentimientos'}
          gridTitle={'Publicación de consentimientos'}
          columnDefPage={paths.consentPublication}
          rowData={data}
          menuItems={[goBackButton, createButton, modifyButton, deleteButton, digitalFolderItem]}
          hasExpand
          hasHelp
        >
          {
            (state.createDialogIsOpen || state.editDialogIsOpen) &&
            <CreateEditDialog
              open={(state.createDialogIsOpen || state.editDialogIsOpen)}
              loading={isLoading}
              data={state.selectedDocument}
              colaborators={colaborators}
              handleClose={handlers.handleCloseCreateEditDialog}
            />
          }
        </CommonPage>
      </div>
    </>
  )
}

export default ConsentPublication;