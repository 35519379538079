import { useState } from "react";
import { useDispatch } from "react-redux";
import { CLEAR_EMPLOYEES } from "../../actionTypes";
import { getCurrentProcessesAction, executeProcessAction, getProcessesEmployeesAction } from "../../actions";
import { getLocalizedString } from "@icarius-localization/strings";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const useExecuteCurrentProcesses = () => {

  const dispatch = useDispatch();

  const [simulationDialogIsOpen, setSimulationDialogIsOpen] = useState(false);
  const [executeDialogIsOpen, setExecuteDialogIsOpen] = useState(false);
  const [executeString, setExecuteString] = useState(null);
  const [executeData, setExecuteData] = useState(null);
  const [isFull, setIsFull] = useState(false);

  const handleOpenExecuteDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();

    //Si no hay uno seleccionado, warning
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedOneRow"), severity: "warning" }));
      return;
    }

    //traer los empleados del tipo de proceso de la fila seleccionada, y obtener la cantidad de los marcados para el proceso
    dispatch(getProcessesEmployeesAction(selectedRows[0]["id_tliqpro"], selectedRows[0]["Código de proceso"]))
      .then(res => {
        if (res.status === 200) {
          const partialAmount = res.data.result.filter(row => row.is_marked_for_process).length;
          const total = res.data.result.length;

          const partialString = getLocalizedString("processExecutePartialConfirm").replace("{amount}", partialAmount);
          const totalString = getLocalizedString("processExecuteTotalConfirm").replace("{amount}", total);

          setIsFull(res.data.partial === false && partialAmount === res.data.result.length);

          if (partialAmount === 0) {
            dispatch(openSnackbarAction({
              msg: 'No es posible ejecutar el proceso de cálculo porque no existen colaboradores seleccionados',
              severity: "error"
            }));
          } else {
            setExecuteString(partialAmount > 0 && partialAmount !== res.data.result.length ? partialString : totalString);
            setExecuteData({
              executeCode: selectedRows[0]["Código de proceso"],
              type: selectedRows[0]["id_tliqpro"],
              startDate: selectedRows[0]["Fecha desde"],
              endDate: selectedRows[0]["Fecha hasta"],
            });
            setExecuteDialogIsOpen(true);
          }
        }
      })
  }

  const handleCloseExecuteDialog = () => {
    setExecuteDialogIsOpen(false);
    setExecuteString(null);
    setSimulationDialogIsOpen(false);
    setExecuteData(null);
    setIsFull(false);
    dispatch({ type: CLEAR_EMPLOYEES });
  }

  const handleConfirmExecute = () => {
    if (executeData.type === "SIM") {
      setExecuteDialogIsOpen(false);
      setExecuteString(null);
      setSimulationDialogIsOpen(true);
    } else {
      handleExecute();
    }
  }

  const handleExecute = (motive, feceg) => {
    dispatch(executeProcessAction(executeData.executeCode, motive, feceg, isFull))
      .then(() => {
        handleCloseExecuteDialog();
        dispatch(getCurrentProcessesAction());
      })
  }

  const executeProcessData = {
    simulationDialogIsOpen,
    executeDialogIsOpen,
    executeString,
    executeData,
  }

  const executeProcessFunctions = {
    handleOpenExecuteDialog,
    handleCloseExecuteDialog,
    handleConfirmExecute,
    handleExecute,
  }

  return {
    executeProcessData,
    executeProcessFunctions,
  }
}

export default useExecuteCurrentProcesses;
