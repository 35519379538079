import NAME from "./constants";

export const getLoading = store => store[NAME].isLoading;
export const getRows = store => store[NAME].rows;
export const getThirdPartyFiles = store => store[NAME].thirdPartyFiles;
export const getGroups = store => store[NAME].groups;
export const getExitTypes = store => store[NAME].exitTypes;
export const getDateFormat = store => store[NAME].dateFormat;
export const getValidationData = store => store[NAME].validationData;
export const getCalendarList = store => store[NAME].calendarList;
export const getEmployees = store => store[NAME].employees;
export const getTemplates = store => store[NAME].templates;
export const getAutomaticProcessesEnabled = store => store[NAME].automaticProcessesEnabled;
export const getIsLoadingAccountingEntries = store => store[NAME].isLoadingAccountingEntries;
export const getAccountingEntriesData = store => store[NAME].accountingEntriesData;
export const getApprovationParameters = store => store[NAME].approvationParameters;