import { useState } from "react";
import { createDateFromDDMMYYYY, formatDateYYYYMMDD } from "@icarius-utils/date";
import * as yup from 'yup';

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback, invalidPublicationDateCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === 'Fecha de publicación') {
        return isCreate ? new Date() : createDateFromDDMMYYYY(data[fieldName]);
      }

      if (fieldName === 'Código de empleado') {
        return isCreate ? [] : [data[fieldName]];
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      'Tipo de consentimiento',
      'Fecha de publicación',
      'Referencia de publicación',
      'Código de empleado',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    const schema = yup.object().shape({
      'Código de empleado': yup.array().required(),
      'Fecha de publicación': yup.date().required(),
      'Tipo de consentimiento': yup.string().required(),
      'Referencia de publicación': yup.string().required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const publicationDateIsValid = () => {
    let today = new Date();
    today.setHours(0, 0, 0, 0);

    if (isCreate || data.received) return true;
    if (isCreate && formData['Fecha de publicación'] < today) return false;
    return true;
  }

  const submit = async () => {
    if (await dataIsValid()) {
      if (publicationDateIsValid()) {
        const dataToSend = {
          "type": formData['Tipo de consentimiento'],
          "ref": formData['Referencia de publicación'],
          "applicationDate": formatDateYYYYMMDD(formData['Fecha de publicación']),
          "codemp": isCreate ? formData['Código de empleado'] : formData['Código de empleado'][0],
        };

        isCreate ? createCallback(dataToSend) : modifyCallback({ ...dataToSend, internalCode: data.internalCode });
        return true;
      } else {
        invalidPublicationDateCallback();
        return false;
      }
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
