import NAME from "./constants";

export const GET_ROWS = NAME + "/GET_ROWS";
export const GET_ROWS_FULFILLED = NAME + "/GET_ROWS_FULFILLED";
export const GET_ROWS_REJECTED = NAME + "/GET_ROWS_REJECTED";

export const GET_EMPLOYEES = NAME + "/GET_EMPLOYEES";
export const GET_EMPLOYEES_FULFILLED = NAME + "/GET_EMPLOYEES_FULFILLED";
export const GET_EMPLOYEES_REJECTED = NAME + "/GET_EMPLOYEES_REJECTED";

export const CREATE_PROCESS = NAME + "/CREATE_PROCESS";
export const CREATE_PROCESS_FULFILLED = NAME + "/CREATE_PROCESS_FULFILLED";
export const CREATE_PROCESS_REJECTED = NAME + "/CREATE_PROCESS_REJECTED";

export const MODIFY_PROCESS = NAME + "/MODIFY_PROCESS";
export const MODIFY_PROCESS_FULFILLED = NAME + "/MODIFY_PROCESS_FULFILLED";
export const MODIFY_PROCESS_REJECTED = NAME + "/MODIFY_PROCESS_REJECTED";

export const DELETE_PROCESS = NAME + "/DELETE_PROCESS";
export const DELETE_PROCESS_FULFILLED = NAME + "/DELETE_PROCESS_FULFILLED";
export const DELETE_PROCESS_REJECTED = NAME + "/DELETE_PROCESS_REJECTED";

export const EXECUTE = NAME + "/EXECUTE";
export const EXECUTE_FULFILLED = NAME + "/EXECUTE_FULFILLED";
export const EXECUTE_REJECTED = NAME + "/EXECUTE_REJECTED";

export const CLEAR_STATE = NAME + "/CLEAR_STATE";

export const CLEAR_EMPLOYEES = NAME + "/CLEAR_EMPLOYEES";

export const CALC_PROCESS_TRACEABILITY = NAME + "/CALC_PROCESS_TRACEABILITY";
export const CALC_PROCESS_TRACEABILITY_FULFILLED = NAME + "/CALC_PROCESS_TRACEABILITY_FULFILLED";
export const CALC_PROCESS_TRACEABILITY_REJECTED = NAME + "/CALC_PROCESS_TRACEABILITY_REJECTED";

export const GENERATE = NAME + "/GENERATE";
export const GENERATE_FULFILLED = NAME + "/GENERATE_FULFILLED";
export const GENERATE_REJECTED = NAME + "/GENERATE_REJECTED";

export const GET_ACCOUNTING_ENTRIES_ROWS = NAME + "/GET_ACCOUNTING_ENTRIES_ROWS";
export const GET_ACCOUNTING_ENTRIES_ROWS_FULFILLED = NAME + "/GET_ACCOUNTING_ENTRIES_ROWS_FULFILLED";
export const GET_ACCOUNTING_ENTRIES_ROWS_REJECTED = NAME + "/GET_ACCOUNTING_ENTRIES_ROWS_REJECTED";

export const DOWNLOAD = NAME + "/DOWNLOAD";
export const DOWNLOAD_FULFILLED = NAME + "/DOWNLOAD_FULFILLED";
export const DOWNLOAD_REJECTED = NAME + "/DOWNLOAD_REJECTED";

export const APPROVE_PROCESS = NAME + "/APPROVE_PROCESS";
export const APPROVE_PROCESS_FULFILLED = NAME + "/APPROVE_PROCESS_FULFILLED";
export const APPROVE_PROCESS_REJECTED = NAME + "/APPROVE_PROCESS_REJECTED";

