import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { getColaboratorFilesForDigitalFolderAction, updateColaboratorFilesForDigitalFolderAction } from "../../actions";
import { getColaboratorFiles } from "../../selectors";
import UploadedByCollaboratorsThemselvesSection from "./uploadedByCollaboratorsThemselvesSection";
import SectionTitle from "../sectionTitle";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { Grid } from "@material-ui/core";

const useHandleMandatoryKeys = (initialValue) => {
    const [mandatoryKeys, setMandatoryKeys] = useState({});

    const changeMandatoryKey = (key, value) => {
        const newMandatoryKeys = { ...mandatoryKeys };
        newMandatoryKeys[key] = value;
        setMandatoryKeys(newMandatoryKeys);
    }

    //con el initialValues armar el objeto con los campos siendo las key de los files y el value si es obligatorio o no
    useEffect(() => {
        const auxKeys = {};
        initialValue.forEach(instance => instance.values.forEach(file => auxKeys[file.keyTipoDocumento] = file.active));
        setMandatoryKeys(auxKeys);
    }, [initialValue])

    return { mandatoryKeys, changeMandatoryKey };
}

const UploadedByCollaboratorsThemselvesContainer = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getColaboratorFilesForDigitalFolderAction())
    }, [dispatch]);

    const colaboratorFiles = useSelector(getColaboratorFiles);
    const { mandatoryKeys, changeMandatoryKey } = useHandleMandatoryKeys(colaboratorFiles);

    const handleSubmit = () => {
        const fileData = { data: formatFileData() };
        dispatch(updateColaboratorFilesForDigitalFolderAction(fileData));
    }

    const formatFileData = () => {
        const fileData = [];

        colaboratorFiles.forEach(instance => {
            instance.values.forEach(file => {
                fileData.push({
                    keyInstanciaLaboral: file.keyInstanciaLaboral,
                    keyTipoDocumento: file.keyTipoDocumento,
                    active: mandatoryKeys[file.keyTipoDocumento] || false,
                })
            })
        });

        return fileData;
    }

    if (colaboratorFiles.length < 1) return null;

    return (
        <>
            <SectionTitle title={getLocalizedString("documentsThatCanBeUploadedByCollaboratorsThemselves")} />
            {
                colaboratorFiles.map(instance => (
                    <UploadedByCollaboratorsThemselvesSection
                        key={instance.key}
                        instance={instance}
                        mandatoryKeys={mandatoryKeys}
                        handleClick={changeMandatoryKey}
                    />
                ))
            }
            <Grid container justify="flex-end">
                <div style={{ marginRight: 30, marginTop: 10 }}>
                    <ButtonDialogAction onClick={handleSubmit} isAccept text={getLocalizedString("save")} />
                </div>
            </Grid>
        </>
    )
}

export default UploadedByCollaboratorsThemselvesContainer;
