import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DialogTransition from "@icarius-common/dialogTransition";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { getPermissionList, getTypeList } from "@icarius-pages/digitalWarehouse/selectors";
import { createDigitalWarehouseItemAction, modifyDigitalWarehouseItemAction } from "@icarius-pages/digitalWarehouse/actions";
import * as yup from 'yup';
import { createDateFromDDMMYYYY } from "@icarius-utils/date";
import FileInput from "@icarius-common/fileUpload/fileInput";
import FileList from "@icarius-common/fileUpload/fileList";

const gridStyle = { height: 75, padding: "0px 10px" };

const UploadFilesDialog = (props) => {

  const { open, loading, isEdit, data, groupCode, handleClose } = props;

  const createDateFromMonth = () => {
    let monthToUse = data['month'];
    if (monthToUse.length === 1) {
      monthToUse = `0${monthToUse}`;
    }
    return createDateFromDDMMYYYY(`01/${monthToUse}/2020`)
  }

  const [failedDocuments, setFailedDocuments] = useState(null);
  const [files, setFiles] = useState(null);
  const [hasSetFile, setHasSetFile] = useState(false);

  const [type, setType] = useState((isEdit && data) ? data["type"] : "");
  const [description, setDescription] = useState((isEdit && data) ? data["description"] : "");
  const [permission, setPermission] = useState((isEdit && data) ? data["permissions"] : "L");

  const [folder, setFolder] = useState(data ? data["folder"] : "");
  const [sfolder, setSfolder] = useState(data ? data["sfolder"] : "");
  const [year, setYear] = useState(data?.year ? createDateFromDDMMYYYY(`01/12/${data["year"]}`) : null);
  const [month, setMonth] = useState(data?.month ? createDateFromMonth() : null);

  const formData = {
    files,
    type,
    description,
    folder,
    sfolder,
    permission,
    year,
    month
  }

  useEffect(() => {
    Boolean(files) && setHasSetFile(true);
  }, [files])

  const dispatch = useDispatch();
  const typeList = useSelector(getTypeList);
  const permissionList = useSelector(getPermissionList);

  const hasFileIsValid = () => {
    if (Boolean(isEdit)) {
      if (hasSetFile) {
        return Boolean(files);
      }
      return true; // si no seteo archivo y es edit (Boolean(data)), esta bien que no haya archivo
    }

    return Boolean(files); // si es create, tiene que tener archivo
  }

  const handleSubmit = async () => {
    // ver si manda failed documents
    setFailedDocuments(null);
    if (await dataIsValid() && hasFileIsValid()) {
      let dataToSend = {
        file: files?.length ? files[0] : null,
        type,
        description,
        folder,
        sfolder,
        permission,
        year: year ? year.getFullYear() : '',
        month: month ? month.getMonth() + 1 : '',
        groupCode,
      }

      if (isEdit) {
        dataToSend.internalCode = data.internalCode;
      }

      dispatch(isEdit ? modifyDigitalWarehouseItemAction(dataToSend) : createDigitalWarehouseItemAction(dataToSend))
        .then((resp) => {
          if (resp?.status === 200) {
            handleClose();
          } else {
            if (resp?.data?.invalidFiles) {
              setFailedDocuments(resp.data.invalidFiles)
            }
          }
        });
    } else {
      dispatch(openSnackbarAction({ msg: 'Ingrese los campos obligatorios', severity: "error" }));
    }
  };

  const dataIsValid = async () => {
    const schema = yup.object().shape({
      type: yup.string().required(),
      description: yup.string().required(),
      permission: yup.string().required(),
      folder: yup.string().required(),
      sfolder: yup.string().required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const handleDeleteFileFromList = (file) => {
    const newFileArray = [...files];
    const indexToDelete = newFileArray.indexOf(file);
    if (indexToDelete > -1) {
      newFileArray.splice(indexToDelete, 1);
      setFiles(newFileArray.length === 0 ? null : newFileArray);
    }
  }

  if (loading) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"md"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {isEdit ? "Editar archivo" : "Crear archivo"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Grid container item xs={12}>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                required
                value={folder}
                onChange={e => setFolder(e.target.value)}
                fullWidth
                label={'Carpeta'}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                required
                value={sfolder}
                onChange={e => setSfolder(e.target.value)}
                fullWidth
                label={'Subcarpeta'}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={Boolean(groupCode) ? 6 : 12} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id="type-label">{'Tipo de archivo'}</InputLabel>
                <Select
                  fullWidth
                  value={type}
                  labelId="type-label"
                  id="type-select"
                  onChange={(e) => setType(e.target.value)}
                  margin={"none"}
                >
                  {
                    typeList.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            {
              Boolean(groupCode) &&
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel required id="permission-label">{'Permisos'}</InputLabel>
                  <Select
                    fullWidth
                    value={permission}
                    labelId="permission-label"
                    id="permission-select"
                    onChange={(e) => setPermission(e.target.value)}
                    margin={"none"}
                  >
                    {
                      permissionList.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}
                        >
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            }
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <TextField
                required
                value={description}
                onChange={e => setDescription(e.target.value)}
                fullWidth
                label={'Descripción'}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  style={{ marginTop: 0, marginBottom: 0, width: "100%" }}
                  label={'Año'}
                  clearable
                  clearLabel={getLocalizedString("clear")}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  invalidDateMessage=""
                  format="yyyy"
                  margin="normal"
                  value={year}
                  onChange={(e) => setYear(e)}
                  views={["year"]}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  style={{ marginTop: 0, marginBottom: 0, width: "100%" }}
                  label={'Mes'}
                  clearable
                  clearLabel={getLocalizedString("clear")}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  invalidDateMessage=""
                  format="MM"
                  margin="normal"
                  value={month}
                  onChange={(e) => setMonth(e)}
                  views={["month"]}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <FileInput
            acceptedFormats={['.jpg', '.bmp', '.png', '.jpeg', '.rar', '.zip', '.gz', '.tar', '.xls', '.xlsx', '.txt', '.rtf', '.doc', '.docx', '.pdf', '.html', '.ppt', '.pptx']}
            handleChange={(files) => setFiles(files)}
            preSubmitCallback={() => setFailedDocuments(null)}
            multiple
          />
          <FileList
            files={files}
            filesWithError={failedDocuments}
            handleClick={handleDeleteFileFromList}
            multiple
          />
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
          <ButtonDialogAction onClick={handleSubmit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default UploadFilesDialog;
