import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { approveProcessAction, getCurrentProcessesAction } from "@icarius-pages/currentProcesses/actions";
import { getApprovationParameters } from "@icarius-pages/currentProcesses/selectors";

const useApproveProcess = () => {

  const dispatch = useDispatch();
  const approvationParameters = useSelector(getApprovationParameters);

  const [paramDialogIsOpen, setParamDialogIsOpen] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [selectedProcess, setSelectedProcess] = useState();

  const handleOpenDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedOneRow"), severity: "warning" }));
      return;
    }

    if (!selectedRows[0].canPublicate) {
      dispatch(openSnackbarAction({
        msg: "El tipo de proceso no puede aprobarse porque es un tipo de proceso especial o de simulación",
        severity: "warning"
      }));
      return;
    }

    setSelectedProcess(selectedRows[0]);
    setDialogIsOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogIsOpen(false);
  }

  const handleCloseParamDialog = () => {
    setParamDialogIsOpen(false);
  }

  const handleIfCanAcceptOrHasParameters = () => {
    if (approvationParameters?.length) {
      setParamDialogIsOpen(true);
      return;
    }

    handleApprove();
  }

  const handleApprove = (param) => {
    if (selectedProcess) {
      dispatch(approveProcessAction({
        code: selectedProcess["Código de proceso"],
        parameters: param || [],
      })).then(() => {
        handleCloseDialog();
        handleCloseParamDialog();
        dispatch(getCurrentProcessesAction())
      });
    }
  }

  const approvingData = {
    approvationParameters,
    paramDialogIsOpen,
    dialogIsOpen,
    selectedProcess,
  };

  const approvingFunctions = {
    handleOpenDialog,
    handleCloseDialog,
    handleCloseParamDialog,
    handleIfCanAcceptOrHasParameters,
    handleApprove,
  };

  return {
    approvingData,
    approvingFunctions,
  }
}

export default useApproveProcess;
