import NAME from "./constants";

export const GET_GENERIC = NAME + "/GET_GENERIC";
export const GET_GENERIC_FULFILLED = NAME + "/GET_GENERIC_FULFILLED";
export const GET_GENERIC_REJECTED = NAME + "/GET_GENERIC_REJECTED";

export const GET_BY_POSITION = NAME + "/GET_BY_POSITION";
export const GET_BY_POSITION_FULFILLED = NAME + "/GET_BY_POSITION_FULFILLED";
export const GET_BY_POSITION_REJECTED = NAME + "/GET_BY_POSITION_REJECTED";

export const GET_COLABORATOR_FILES = NAME + "/GET_COLABORATOR_FILES";
export const GET_COLABORATOR_FILES_FULFILLED = NAME + "/GET_COLABORATOR_FILES_FULFILLED";
export const GET_COLABORATOR_FILES_REJECTED = NAME + "/GET_COLABORATOR_FILES_REJECTED";

export const UPDATE_GENERIC = NAME + "/UPDATE_GENERIC";
export const UPDATE_GENERIC_FULFILLED = NAME + "/UPDATE_GENERIC_FULFILLED";
export const UPDATE_GENERIC_REJECTED = NAME + "/UPDATE_GENERIC_REJECTED";

export const UPDATE_COLABORATOR_FILES = NAME + "/UPDATE_COLABORATOR_FILES";
export const UPDATE_COLABORATOR_FILES_FULFILLED = NAME + "/UPDATE_COLABORATOR_FILES_FULFILLED";
export const UPDATE_COLABORATOR_FILES_REJECTED = NAME + "/UPDATE_COLABORATOR_FILES_REJECTED";

export const ADD_FOR_POSITION = NAME + "/ADD_FOR_POSITION";
export const ADD_FOR_POSITION_FULFILLED = NAME + "/ADD_FOR_POSITION_FULFILLED";
export const ADD_FOR_POSITION_REJECTED = NAME + "/ADD_FOR_POSITION_REJECTED";

export const UPDATE_FOR_POSITION = NAME + "/UPDATE_FOR_POSITION";
export const UPDATE_FOR_POSITION_FULFILLED = NAME + "/UPDATE_FOR_POSITION_FULFILLED";
export const UPDATE_FOR_POSITION_REJECTED = NAME + "/UPDATE_FOR_POSITION_REJECTED";

export const DELETE_FOR_POSITION = NAME + "/DELETE_FOR_POSITION";
export const DELETE_FOR_POSITION_FULFILLED = NAME + "/DELETE_FOR_POSITION_FULFILLED";
export const DELETE_FOR_POSITION_REJECTED = NAME + "/DELETE_FOR_POSITION_REJECTED";
