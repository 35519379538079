import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { generateThirdPartyFileAction } from "../../actions";
import { getThirdPartyFiles, getGroups } from "../../selectors";

const useThirdPartyFileGeneration = () => {

  const dispatch = useDispatch();
  const thirdPartyFiles = useSelector(getThirdPartyFiles);
  const groups = useSelector(getGroups);

  const [thirdPartySimulationDialogIsOpen, setThirdPartySimulationDialogIsOpen] = useState(false);
  const [simulationData, setSimulationData] = useState(null); // motiv feceg
  const [thirdPartyCode, setThirdPartyCode] = useState(null);
  const [paramsToUse, setParamsToUse] = useState(null);
  const [paramDialogIsOpen, setParamDialogIsOpen] = useState(false);
  const [thirdPartyFileDialogIsOpen, setThirdPartyFileDialogIsOpen] = useState(false);
  const [selectedProcess, setSelectedProcess] = useState(null);

  const handleStartThirdPartyGenerationProcess = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();

    //Si no hay uno seleccionado, warning
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedOneRow"), severity: "warning" }));
      return;
    }

    setSelectedProcess(selectedRows[0]);

    if (selectedRows[0].id_tliqpro === "SIM") {
      setThirdPartySimulationDialogIsOpen(true);
    }else{
      setThirdPartyFileDialogIsOpen(true);
    }
  }

  const handleCancelThirdPartySimulation = () => {
    setSelectedProcess(null);
    setThirdPartySimulationDialogIsOpen(false);
  }

  const handleSubmitSimulationDialog = (motive, feceg) => {
    setSimulationData({ motive, feceg });
    setThirdPartySimulationDialogIsOpen(false);
    setThirdPartyFileDialogIsOpen(true);
  }

  const handleCloseGenerateThirdPartyFileDialog = () => {
    setThirdPartyFileDialogIsOpen(false);
    setSelectedProcess(null);
    setSimulationData(null);
  }

  const handleSubmitThirdPartyFile = (thirdPartyKey) => {
    setThirdPartyCode(thirdPartyKey);
    const thirdPartyFile = thirdPartyFiles.find(item => item.key === thirdPartyKey);

    if (thirdPartyFile?.parameters?.length) {
      //abrir modal de setparam
      setParamDialogIsOpen(true);
      setParamsToUse(thirdPartyFile.parameters);
      return;
    }

    // si no tiene parametros
    generateThirdPartyFile(null, thirdPartyKey);
  }

  const handleCloseParamDialog = () => {
    setParamDialogIsOpen(false);
    setParamsToUse(null);
  }

  const generateThirdPartyFile = (param, thirdPartyKey) => {
    dispatch(generateThirdPartyFileAction({
      process: selectedProcess["Código de proceso"],
      thirdPartyFileCode: thirdPartyKey || thirdPartyCode,
      parameters: param || [],
      ...simulationData,
    })).then(() => {
      handleCloseGenerateThirdPartyFileDialog();
    })
  }

  const thirdPartyGenerationData = {
    thirdPartyFiles,
    groups,
    selectedProcess,
    paramsToUse,
    paramDialogIsOpen,
    thirdPartyFileDialogIsOpen,
    thirdPartySimulationDialogIsOpen,
  }

  const thirdPartyGenerationFunctions = {
    handleStartThirdPartyGenerationProcess,
    handleCancelThirdPartySimulation,
    handleSubmitSimulationDialog,
    handleCloseGenerateThirdPartyFileDialog,
    handleSubmitThirdPartyFile,
    generateThirdPartyFile,
    handleCloseParamDialog,
  }

  return {
    thirdPartyGenerationData,
    thirdPartyGenerationFunctions,
  }
}

export default useThirdPartyFileGeneration;
