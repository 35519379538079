import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Typography,
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTransition from "@icarius-common/dialogTransition";

const ConfirmationDialog = (props) => {

  const { open, title, text, handleConfirm, handleClose } = props;

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      open={open}
      scroll={"paper"}
      maxWidth={"sm"}>
      <DialogTitle>
        {title}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent style={{paddingTop: 0}}>
        <Typography className="whiteText" variant="h6" gutterBottom>
          {text}
        </Typography>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
        <ButtonDialogAction onClick={handleConfirm} isAccept text={getLocalizedString("accept")} />
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
