import NAME from "./constants";

export const DELETE_REQUEST = NAME + "/DELETE_REQUEST";
export const DELETE_REQUEST_REJECTED = NAME + "/DELETE_REQUEST_REJECTED";
export const DELETE_REQUEST_FULFILLED = NAME + "/DELETE_REQUEST_FULFILLED";
export const GET_DELEGATION_ROWS = NAME + "/GET_DELEGATION_ROWS";
export const GET_DELEGATION_ROWS_FULFILLED = NAME + "/GET_DELEGATION_ROWS_FULFILLED";
export const GET_DELEGATION_ROWS_REJECTED = NAME + "/GET_DELEGATION_ROWS_REJECTED";
export const EXPORT_ROWS_VIA_SERVER = NAME + "/EXPORT_ROWS_VIA_SERVER";
export const EXPORT_ROWS_VIA_SERVER_FULFILLED = NAME + "/EXPORT_ROWS_VIA_SERVER_FULFILLED";
export const EXPORT_ROWS_VIA_SERVER_REJECTED = NAME + "/EXPORT_ROWS_VIA_SERVER_REJECTED";
export const GET_MANAGERS_EMPLOYERS = NAME + "/GET_MANAGERS_EMPLOYERS";
export const GET_MANAGERS_EMPLOYERS_FULFILLED = NAME + "/GET_MANAGERS_EMPLOYERS_FULFILLED";
export const GET_MANAGERS_EMPLOYERS_REJECTED = NAME + "/GET_MANAGERS_EMPLOYERS_REJECTED";
export const SET_DELEGATION = NAME + "/SET_DELEGATION";
export const SET_DELEGATION_FULFILLED = NAME + "/SET_DELEGATION_FULFILLED";
export const SET_DELEGATION_REJECTED = NAME + "/SET_DELEGATION_REJECTED";
