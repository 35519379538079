import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import SectionTitle from "../sectionTitle";
import { Grid, Typography } from "@material-ui/core";
import DeleteDialog from "./deleteDialog";
import CreateEditDialog from "./createEditDialog";
import { getFilesByPositionForDigitalFolderAction } from "../../actions";
import { getCargos, getInstanciasLaborales, getRowsByPosition, getTipos } from "../../selectors";
import CommonPage from "@icarius-common/commonPage";
import paths from "@icarius-localization/paths";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const ByPositionContainer = () => {

    const [createEditDialogIsOpen, setCreateEditDialogIsOpen] = useState(false);
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null); //seleccionado para editar o borrar

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getFilesByPositionForDigitalFolderAction())
    }, [dispatch]);

    const cargos = useSelector(getCargos);
    const tipos = useSelector(getTipos);
    const instances = useSelector(getInstanciasLaborales);
    const rowsByPosition = useSelector(getRowsByPosition);

    const handleOpenDeleteDialog = (gridRef) => {
        let documents = gridRef.api.getSelectedRows();

        if (documents.length === 0) {
            dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
            return;
        }

        setSelectedDocument(documents[0]);
        setDeleteDialogIsOpen(true);
    }

    const handleCloseDeleteDialog = () => {
        setSelectedDocument(null);
        setDeleteDialogIsOpen(false);
    }

    const handleOpenCreateDialog = () => {
        setCreateEditDialogIsOpen(true);
    }

    const handleOpenEditDialog = (gridRef) => {
        let documents = gridRef.api.getSelectedRows();

        if (documents.length === 0) {
            dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
            return;
        }

        setSelectedDocument(documents[0]);
        setCreateEditDialogIsOpen(true);
    }

    const handleCloseCreateEditDialog = () => {
        setSelectedDocument(null);
        setCreateEditDialogIsOpen(false);
    }

    const createButton = () => (
        <CustomIconButton
            title={getLocalizedString("createNewDocument")}
            onClick={handleOpenCreateDialog}
            type={"add"}
        />
    )

    const modifyButton = (gridRef) => (
        <CustomIconButton
            title={getLocalizedString("edit")}
            onClick={() => handleOpenEditDialog(gridRef)}
            type={"edit"}
        />
    )

    const deleteButton = (gridRef) => (
        <CustomIconButton
            title={getLocalizedString("delete")}
            onClick={() => handleOpenDeleteDialog(gridRef)}
            type={"delete"}
        />
    )

    if (cargos.length < 1 || tipos.length < 1 || instances.length < 1) return null;

    return (
        <>
            <SectionTitle title={getLocalizedString("byPosition")} />
            {
                rowsByPosition.LENGTH < 1 ?
                    <Grid container justify="center" item xs={12} style={{ marginTop: 20 }}>
                        <Typography className="whiteText" style={{ fontSize: "1.5rem" }}>
                            {getLocalizedString("noDocumentsForPosition")}
                        </Typography>
                    </Grid>
                    :
                    <CommonPage
                        menuItems={[createButton, modifyButton, deleteButton]}
                        rowData={rowsByPosition}
                        gridTitle={getLocalizedString("byPosition")}
                        columnDefPage={paths.digitalFolderSettings}
                        hasExpand
                        hasHelp
                    >
                        {
                            deleteDialogIsOpen && selectedDocument &&
                            <DeleteDialog
                                open={deleteDialogIsOpen}
                                document={selectedDocument}
                                handleClose={handleCloseDeleteDialog}
                            />
                        }
                        {
                            createEditDialogIsOpen &&
                            <CreateEditDialog
                                open={createEditDialogIsOpen}
                                isEdit={Boolean(selectedDocument)}
                                cargos={cargos}
                                tipos={tipos}
                                instances={instances}
                                document={selectedDocument}
                                handleClose={handleCloseCreateEditDialog}
                            />
                        }
                    </CommonPage>
            }
        </>
    )
}

export default ByPositionContainer;
