import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDelegationsRowsFromServer, getManagersEmployersFromServer } from "../actions";
import { getDelegationsRows, getManagersEmployers, getLoading, getAllEmployees } from "../selectors";
import { getUserData } from "src/app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import { Grid } from "@material-ui/core";
import DataItem from "./dataItem";
import ABMLayout from "@icarius-common/abmComponents/abmLayout";

const DelegateAttentions = () => {

  const [createIsOpen, setCreateIsOpen] = useState(false);

  const dispatch = useDispatch();
  const userData = useSelector(getUserData);
  const delegationsRows = useSelector(getDelegationsRows);
  const managersEmployers = useSelector(getManagersEmployers);
  const allEmployees = useSelector(getAllEmployees);
  const isLoading = useSelector(getLoading);

  useEffect(() => {
    dispatch(getDelegationsRowsFromServer())
      .then(() => dispatch(getManagersEmployersFromServer()));
  }, [dispatch])

  const hasActiveDelegation = useMemo(() => {
    let resp = false;
    if (!delegationsRows) return false;

    delegationsRows.forEach(delegation => {
      if (delegation.code_emp === userData.code)
        resp = true;
    });

    return resp;
  }, [delegationsRows, userData])

  const managersEmployersWithoutUser = useMemo(() => {
    return managersEmployers.filter(employee => employee.code !== userData.code)
  }, [managersEmployers, userData])

  const hasNoDelegation = delegationsRows.length === 0 || managersEmployers.length === 0;

  return (
    <ABMLayout
      isLoading={isLoading}
      title={getLocalizedString("delegationsTitle")}
      showCreateButton={!hasActiveDelegation && !isLoading && !createIsOpen}
      createButtonTitle={getLocalizedString("newDelegation")}
      createButtonOnClick={() => setCreateIsOpen(true)}
      showNoData={hasNoDelegation}
      noDataTitle={getLocalizedString("noDelegationsRows")}
      noDataCreateTitle={getLocalizedString("newDelegation")}
      noDataOnClick={() => setCreateIsOpen(true)}
    >
      {
        createIsOpen &&
        <Grid container item xs={12} style={{ margin: "10px 0px" }}>
          <DataItem
            data={null}
            user={userData.code}
            managersEmployers={managersEmployers}
            allEmployees={allEmployees}
            managersEmployersWithoutUser={managersEmployersWithoutUser}
            closeNew={() => setCreateIsOpen(false)}
          />
        </Grid>
      }
      {
        !isLoading && !hasNoDelegation ?
          delegationsRows.map((delegation) => {
            return (
              <Grid container key={delegation.code} item xs={12} style={{ margin: "10px 0px" }}>
                <DataItem
                  data={delegation}
                  user={userData.code}
                  managersEmployers={managersEmployers}
                  allEmployees={allEmployees}
                  managersEmployersWithoutUser={managersEmployersWithoutUser}
                />
              </Grid>
            )
          })
          : null
      }
    </ABMLayout>
  );
}

export default DelegateAttentions;
