import React from "react";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { CloseIcon } from "@icarius-icons";
import { Dialog, DialogTitle, DialogContent, Grid } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import PaperDraggable from "@icarius-common/paperDraggable";
import BarChart from "./BarChart"; 
import DialogTransition from "@icarius-common/dialogTransition";

const GraphsDialog = (props) => {

  const { open, data, color, theme, handleClose } = props;

  const dispatch = useDispatch();

  if(!data){
    handleClose();
    dispatch(openSnackbarAction({ msg: getLocalizedString("noDataToChart"), severity: "error", duration: 10000 }));
    return null;
  }

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth={true}
      maxWidth={"sm"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {getLocalizedString("charts")}
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>        
        <Grid container alignItems="center" justify="center">
          <Grid item xs={12} sm={6}>
            <BarChart
              dataType={data.type}
              color={color}
              theme={theme.theme}
              type={"pie"}
              data={data}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default GraphsDialog;
