import React from "react";
import { useDispatch } from "react-redux";
import { CloseIcon } from "@icarius-icons";
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { deleteFileForPositionForDigitalFolderAction } from "../../actions";
import DialogTransition from "@icarius-common/dialogTransition";

const DeleteDialog = (props) => {

  const dispatch = useDispatch();
  const { open, document, handleClose } = props;

  const handleAgree = () => {
    dispatch(deleteFileForPositionForDigitalFolderAction({
      position: document.keyCargo,
      laborInstance: document.keyInstanciaLaboral,
      documentType: document.keyTipoDocumento,
    }))
    handleClose();
  }

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"md"}
      open={open}
      scroll={"paper"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {getLocalizedString("atention")}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <Typography className="whiteText" variant="h6" gutterBottom>
          {getLocalizedString("documentDeleteQuestion")}
        </Typography>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
        <ButtonDialogAction isAccept onClick={handleAgree} text={getLocalizedString("agree")} />
      </DialogActions>
    </Dialog>
  );
}

export default DeleteDialog;
