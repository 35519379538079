import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIsExportingGrid } from "src/app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import SetParamDialog from "@icarius-common/setParamDialog";
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CheckIcon from '@material-ui/icons/Check';
import { getCurrentProcessesAction, downloadDesktopR8Action } from "../actions";
import { getRows, getDateFormat, getLoading, getAutomaticProcessesEnabled } from "../selectors";
import ProcessFormContainer from "./form/processFormContainer";
import ConfirmationDialog from "./dialogs/confirmationDialog";
import SimulationDialog from "./dialogs/simulationDialog";
import CalcProcessTraceabilityDialog from "./dialogs/calcProcessTraceabilityDialog";
import GenerateThirdPartyFileDialog from "./dialogs/generateThirdPartyFileDialog";
import AccountingEntriesDialog from "./dialogs/accountingEntriesDialog";
import ApproveDialog from "./dialogs/approveDialog";
import useExecuteCurrentProcesses from "./hooks/useExecuteCurrentProcesses";
import useThirdPartyFileGeneration from "./hooks/useThirdPartyFileGeneration";
import useCurrentProcessesCRUD from "./hooks/useCurrentProcessesCRUD";
import useCalcProcessTraceability from "./hooks/useCalcProcessTraceability";
import useApproveProcess from "./hooks/useApproveProcess";
import useAccountingEntries from "./hooks/useAccountingEntries";

const CurrentProcesses = ({ handleOpenEmployeesPage }) => {

  const dispatch = useDispatch();

  const data = useSelector(getRows);
  const dateFormat = useSelector(getDateFormat);
  const isLoading = useSelector(getLoading);
  const isExportinGrid = useSelector(getIsExportingGrid);
  const automaticProcessesEnabled = useSelector(getAutomaticProcessesEnabled);

  useEffect(() => {
    dispatch(getCurrentProcessesAction());
  }, [dispatch])

  const {
    approvingData,
    approvingFunctions,
  } = useApproveProcess();

  const {
    thirdPartyGenerationData,
    thirdPartyGenerationFunctions,
  } = useThirdPartyFileGeneration();

  const {
    executeProcessData,
    executeProcessFunctions,
  } = useExecuteCurrentProcesses();

  const {
    crudData,
    crudFunctions,
  } = useCurrentProcessesCRUD();

  const {
    calcProcessName,
    calcProcessTraceabilityDialogIsOpen,
    calcProcessTraceabilityDialogData,
    handleOpenCalcProcessTraceabilityDialog,
    handleCloseCalcProcessTraceabilityDialog
  } = useCalcProcessTraceability();

  const {
    accountingEntriesData,
    accountingEntriesFunctions,
  } = useAccountingEntries();

  const downloadR8Item = () =>
    <CustomIconButton
      title={'Descargar R8 versión escritorio'}
      onClick={() => dispatch(downloadDesktopR8Action())}
      type={"download"}
    />

  const accountingEntriesItem = (gridRef) =>
    <CustomIconButton
      title={'Asientos contables'}
      onClick={() => accountingEntriesFunctions.handleOpenDialog(gridRef)}
    >
      <AccountBalanceIcon />
    </CustomIconButton>

  const approveItem = (gridRef) =>
    <CustomIconButton
      title={'Aprobación del proceso de cálculo'}
      onClick={() => approvingFunctions.handleOpenDialog(gridRef)}
    >
      <CheckIcon />
    </CustomIconButton>

  const executeItem = (gridRef) =>
    <CustomIconButton
      title={getLocalizedString("processCalculate")}
      onClick={() => executeProcessFunctions.handleOpenExecuteDialog(gridRef)}
      type={"execute"}
    />

  const generateThirdPartyFileItem = (gridRef) =>
    <CustomIconButton
      title={'Acciones especiales'}
      onClick={() => thirdPartyGenerationFunctions.handleStartThirdPartyGenerationProcess(gridRef)}
    >
      <AssignmentIcon />
    </CustomIconButton>

  const addItem = () =>
    <CustomIconButton
      title={getLocalizedString("processNew")}
      onClick={() => crudFunctions.handleOpenCreateDialog()}
      type={"add"}
    />

  const deleteItem = (gridRef) =>
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => crudFunctions.handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />

  const editItem = (gridRef) =>
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => crudFunctions.handleOpenEditDialog(gridRef)}
      type={"edit"}
    />

  const calcProcessTraceability = (gridRef) =>
    <CustomIconButton
      title={getLocalizedString("calcProcessTraceability")}
      onClick={() => handleOpenCalcProcessTraceabilityDialog(gridRef)}
      type={"search"}
    />

  const handleRowClick = (row) => {
    if (row.data.id_estado !== "P") return;
    handleOpenEmployeesPage(row.data);
  }

  const simulationData = {
    open: executeProcessData.simulationDialogIsOpen || thirdPartyGenerationData.thirdPartySimulationDialogIsOpen,
    startDate: executeProcessData.executeData?.startDate || thirdPartyGenerationData.selectedProcess?.["Fecha desde"],
    endDate: executeProcessData.executeData?.endDate || thirdPartyGenerationData.selectedProcess?.["Fecha hasta"],
    title: 'Datos para la simulación de finiquitos',
    handleConfirm: executeProcessData.simulationDialogIsOpen ? executeProcessFunctions.handleExecute : thirdPartyGenerationFunctions.handleSubmitSimulationDialog,
    handleClose: executeProcessData.simulationDialogIsOpen ? executeProcessFunctions.handleCloseExecuteDialog : thirdPartyGenerationFunctions.handleCancelThirdPartySimulation,
  }

  return (
    <>
      {
        crudData.createEditDialogIsOpen ?
          <ProcessFormContainer
            isLoading={isLoading}
            data={crudData.selectedProcess}
            handleClose={crudFunctions.handleCloseEditDialog}
          />
          :
          <CommonPage
            columnDefPage={automaticProcessesEnabled ? 'currentProcessesAutomatic' : paths.currentProcesses}
            title={getLocalizedString("currentProcessesTitle")}
            gridTitle={getLocalizedString("currentProcessesTitle")}
            menuItems={[executeItem, addItem, editItem, deleteItem, calcProcessTraceability, generateThirdPartyFileItem, accountingEntriesItem, approveItem, downloadR8Item]}
            rowData={data}
            dateFormat={dateFormat}
            isLoading={isExportinGrid || isLoading || accountingEntriesData.isLoading}
            handleRowClick={handleRowClick}
            hasExpand
            hasHelp
            codeFieldName={'Código de proceso'}
          >
            {
              crudData.deleteDialogIsOpen && !isLoading &&
              <ConfirmationDialog
                open={crudData.deleteDialogIsOpen}
                title={getLocalizedString("atention")}
                text={getLocalizedString("processDeleteConfirm")}
                handleConfirm={crudFunctions.handleDelete}
                handleClose={crudFunctions.handleCloseDeleteDialog}
              />
            }
            {
              executeProcessData.executeDialogIsOpen && !isLoading &&
              <ConfirmationDialog
                open={executeProcessData.executeDialogIsOpen}
                title={getLocalizedString("atention")}
                text={executeProcessData.executeString}
                handleConfirm={executeProcessFunctions.handleConfirmExecute}
                handleClose={executeProcessFunctions.handleCloseExecuteDialog}
              />
            }
            {
              thirdPartyGenerationData.thirdPartyFileDialogIsOpen && !isLoading &&
              <GenerateThirdPartyFileDialog
                open={thirdPartyGenerationData.thirdPartyFileDialogIsOpen}
                thirdPartyFiles={thirdPartyGenerationData.thirdPartyFiles}
                groups={thirdPartyGenerationData.groups}
                handleConfirm={thirdPartyGenerationFunctions.handleSubmitThirdPartyFile}
                handleClose={thirdPartyGenerationFunctions.handleCloseGenerateThirdPartyFileDialog}
              />
            }
            {
              thirdPartyGenerationData.paramDialogIsOpen &&
              <SetParamDialog
                open={thirdPartyGenerationData.paramDialogIsOpen}
                params={thirdPartyGenerationData.paramsToUse}
                handleClose={thirdPartyGenerationFunctions.handleCloseParamDialog}
                handleAgree={thirdPartyGenerationFunctions.generateThirdPartyFile}
              />
            }
            {
              (simulationData.open) && !isLoading &&
              <SimulationDialog
                open={simulationData.open}
                startDate={simulationData.startDate}
                endDate={simulationData.endDate}
                title={simulationData.title}
                handleConfirm={simulationData.handleConfirm}
                handleClose={simulationData.handleClose}
              />
            }
            {
              calcProcessTraceabilityDialogIsOpen && !isLoading &&
              <CalcProcessTraceabilityDialog
                open={calcProcessTraceabilityDialogIsOpen}
                data={calcProcessTraceabilityDialogData}
                handleClose={handleCloseCalcProcessTraceabilityDialog}
                calcProcessName={calcProcessName}
              />
            }
            {
              accountingEntriesData.dialogIsOpen &&
              <AccountingEntriesDialog
                open={accountingEntriesData.dialogIsOpen}
                data={accountingEntriesData.arrayData}
                handleClose={accountingEntriesFunctions.handleCloseDialog}
              />
            }
            {
              approvingData.dialogIsOpen &&
              <ApproveDialog
                open={approvingData.dialogIsOpen}
                data={approvingData.selectedProcess}
                handleClose={approvingFunctions.handleCloseDialog}
                handleApprove={approvingFunctions.handleIfCanAcceptOrHasParameters}
              />
            }
            {
              approvingData.paramDialogIsOpen &&
              <SetParamDialog
                open={approvingData.paramDialogIsOpen}
                params={approvingData.approvationParameters}
                handleClose={approvingFunctions.handleCloseParamDialog}
                handleAgree={approvingFunctions.handleApprove}
              />
            }
          </CommonPage>
      }
    </>
  );
}

export default CurrentProcesses;
