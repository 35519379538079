import { useState } from "react";
import * as yup from 'yup';
import { formatDateYYYYMMDD } from "@icarius-utils/date";

const useHandleForm = (startDate, modifyCallback, invalidDataCallback) => {

  const createInitialData = () => {

    const fieldNames = ["motive", "feceg"];

    const getInitialFieldData = (fieldName) => {
      if (fieldName === "feceg") {
        return startDate;
      }
      return "";
    }

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {

    const schema = yup.object().shape({
      motive: yup.string().required(),
      feceg: yup.date().required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      modifyCallback(formData.motive, formatDateYYYYMMDD(formData.feceg));
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }


  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value
    })
  }

  return { formData, setFormValue, submit };
}

export default useHandleForm;
