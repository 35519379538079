import { useState } from "react";
import { checkIfEmail } from "@icarius-utils/types";
import { createDateFromDDMMYYYY, formatDateYYYYMMDD } from "@icarius-utils/date";

const useHandleForm = (data, userFieldsDefinition, createCallback, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {

      // si es campo de usuario, setear valor segun el type
      if (userFieldsDefinition?.some((item) => item.name === fieldName)) {
        const fieldConfig = userFieldsDefinition?.find((item) => item.name === fieldName);
        const fieldValue = data?.userFields?.[fieldName];

        if (fieldConfig.type === "date") {
          if (!fieldValue) return null;
          return createDateFromDDMMYYYY(fieldValue);
        }

        if (fieldConfig.type === "integer") {
          if (fieldValue === null || fieldValue === undefined || fieldValue === "" || fieldValue === 0) return "";
          return (Math.round(fieldValue * 100) / 100).toFixed(0);
        }

        if (fieldConfig.type === "float") {
          if (fieldValue === null || fieldValue === undefined || fieldValue === "" || fieldValue === 0) return "";
          return (Math.round(fieldValue * 100) / 100).toFixed(2);
        }

        if (!fieldValue) return "";
        return fieldValue;
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      "code",
      "name",
      "type",
      "address",
      "contactName",
      "contactMail",
      "contactPhone",
      ...(userFieldsDefinition || [])?.map((item) => item.name),
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());
  const [fieldsWithError, setFieldsWithError] = useState([]);

  const dataIsValid = () => {

    const {
      code,
      name,
      type,
      address,
      contactMail,
    } = formData;

    if (!code || code === "") return false;
    if (!name || name === "") return false;
    if (!type || type === "") return false;
    if (!address || address === "") return false;

    // mail es opcional pero validar formato en caso de que este cargado
    if (Boolean(contactMail) && !checkIfEmail(contactMail)) return false;

    return userFieldAreValid();
  }

  const userFieldAreValid = () => {
    if (!userFieldsDefinition) return true;

    let isValid = true;
    const auxFieldsWithErrors = [];

    (userFieldsDefinition || []).forEach((userField) => {
      let fieldValue = formData[userField.name];

      // si no tiene valor
      if ((!fieldValue && fieldValue !== 0) || fieldValue?.length === 0 || /^\s+$/.test(fieldValue)) {
        if (userField.required) {
          auxFieldsWithErrors.push(userField.name)
          isValid = false;
        }
      } else {
        //si tiene valor, pregunto por min y max segun el type
        switch (userField.type) {
          case "text":
            if (userField.max && fieldValue.length > userField.max) {
              auxFieldsWithErrors.push(userField.name)
              isValid = false;
            }

            break;
          case "integer":
          case "float":
            if (userField.max && fieldValue > userField.max) {
              auxFieldsWithErrors.push(userField.name)
              isValid = false;
            }

            if (userField.min !== null && fieldValue < userField.min) {
              auxFieldsWithErrors.push(userField.name)
              isValid = false;
            }
            break;

          case "date":
            if (!(fieldValue instanceof Date && !isNaN(fieldValue))) {
              auxFieldsWithErrors.push(userField.name)
              isValid = false;
            } else {
              if (userField.min) {
                if (fieldValue < new Date(formatDateYYYYMMDD(userField.min))) {
                  auxFieldsWithErrors.push(userField.name)
                  isValid = false;
                }
              }

              if (userField.max) {
                if (fieldValue > new Date(formatDateYYYYMMDD(userField.max))) {
                  auxFieldsWithErrors.push(userField.name)
                  isValid = false;
                }
              }
            }
            break;
          case "options":
            const optionOk = userField?.options?.find((item) => item.id === fieldValue);
            if (!optionOk) {
              auxFieldsWithErrors.push(userField.name)
              isValid = false;
            }
            break;
          default: break;
        }
      }
    });

    setFieldsWithError(auxFieldsWithErrors);
    return isValid;
  };

  const getEmptyValue = (type) => {
    switch (type) {
      case "text": return "";
      case "integer": return 0;
      case "float": return 0;
      case "options": return "";
      case "date": return null;
      default: return "";
    }
  }

  const submit = () => {
    if (dataIsValid()) {
      let hasUserDefinedFields = false;
      let dataToSend = { ...formData };
      let userFields = {};

      if (!isCreate) {
        dataToSend = {
          ...dataToSend,
          internalCode: data.internalCode,
        };
      }

      (userFieldsDefinition || []).forEach((item) => {
        if (item.is_user_field) {
          hasUserDefinedFields = true;
          userFields[item.name] = (formData[item.name] || formData[item.name] === 0) ? formData[item.name] : getEmptyValue(item.type);

          // si es fecha formatearlo
          if (item.type === "date" && userFields[item.name]) {
            userFields[item.name] = formatDateYYYYMMDD(userFields[item.name]);
          }
        }
      });

      //si no hay user fields, el objeto directamente no se agrega
      if (hasUserDefinedFields) {
        dataToSend.userFields = userFields;
      }

      isCreate ? createCallback(dataToSend) : modifyCallback(dataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const formatValue = (value, fieldName) => {
    if (!value) return value;

    if (fieldName === "code") {
      return value.length > 0 ? value.replace(/\s/g, "") : "";
    }

    const fieldDefinition = userFieldsDefinition?.find((item) => item.name === fieldName);
    if (!fieldDefinition) return value;

    if (fieldDefinition.type === "float") {
      return (Math.round(value * 100) / 100).toFixed(2);
    }

    if (fieldDefinition.type === "integer") {
      return (Math.round(value * 100) / 100).toFixed(0);
    }

    return value;
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: formatValue(value, fieldName)
    })
  }

  return {
    isCreate,
    formData,
    fieldsWithError,
    setFormValue,
    submit,
  };
}

export default useHandleForm;
