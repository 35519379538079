import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDigitalFolderInstancesFromServer,
  getDigitalFolderDataFromServer,
} from "../actions";
import {
  getRows,
  getDateFormat,
  getLoading,
  getInstances
} from "../selectors";
import { CLEAR_STATE } from "../actionTypes";
import { getAppColor, getIsExportingGrid, getUserData } from "src/app/selectors";
import { getTheme } from "@icarius-pages/login/selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import CommonPage from "@icarius-common/commonPage";
import paths from "@icarius-localization/paths";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import GraphsDialog from "./graphsDialog";
import AnnexesPage from "@icarius-pages/annexesPage/components/index";
import { getLoading as getAnnotationsAnnexesPageLoading } from "@icarius-pages/annexesPage/selectors";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import UploadFilesDialog from "./uploadFilesDialog/index";

const DigitalFolderAnalysis = () => {

  const [selectedInstance, setSelectedInstance] = useState("-"); //"-" es todas las instancias
  const [selectedAvailable, setSelectedAvailable] = useState(true); //true es "Y" es archivos existentes
  const [graphDialogIsOpen, setGraphDialogIsOpen] = useState(false);
  const [uploadDialogIsOpen, setUploadDialogIsOpen] = useState(false);
  const [dataForCharts, setDataForCharts] = useState(null);
  const [annexesEmployee, setAnnexesEmployee] = useState(null);

  const dispatch = useDispatch();
  const theme = useSelector(getTheme);
  const color = useSelector(getAppColor);
  const data = useSelector(getRows);
  const instances = useSelector(getInstances)
  const dateFormat = useSelector(getDateFormat);
  const isLoading = useSelector(getLoading);
  const isExportinGrid = useSelector(getIsExportingGrid);
  const gettingAnnexes = useSelector(getAnnotationsAnnexesPageLoading);
  const userData = useSelector(getUserData);

  useEffect(() => {
    dispatch(getDigitalFolderInstancesFromServer())
      .then(() => dispatch(getDigitalFolderDataFromServer("Y")));

    return () => {
      dispatch({ type: CLEAR_STATE });
    }
  }, [dispatch])

  const dispatchInstanceError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("digitalFolderInstanceReq"), severity: "error", duration: 5000 }));
  }

  const handleSwitchClick = () => {
    const isAvailable = selectedAvailable;
    setSelectedAvailable(prev => !prev);

    if (isAvailable) { //si va a quedar en no available...
      if (selectedInstance === "-" || selectedInstance === "OT") { //...y esta en una instancia no valida...        
        dispatchInstanceError();
        return;
      }
    } else {
      //si va a quedar en available, que la instancia sea todas (o "-")
      setSelectedInstance("-");
    }

    dispatch(getDigitalFolderDataFromServer(!isAvailable ? "Y" : "N", !isAvailable ? null : selectedInstance));
  };

  const handleInstanceChange = (newInstance) => {
    setSelectedInstance(newInstance);

    if (!selectedAvailable && newInstance === "-") {
      dispatchInstanceError();
      return;
    }

    if (selectedAvailable && newInstance === "-") {
      dispatch(getDigitalFolderDataFromServer("Y"));
      return;
    }

    dispatch(getDigitalFolderDataFromServer(selectedAvailable ? "Y" : "N", newInstance));
  }

  const handleOpenGraphs = (gridRef) => {
    setGraphDialogIsOpen(true);
    setDataForCharts(createDataForLoansChart(gridRef));
  };

  const createDataForLoansChart = (gridRef) => {
    let datosOK = true;
    let filteredRows = [];
    let docData = {
      data: {},
      type: "docs",
      title: getLocalizedString(selectedAvailable ? "digitalFolderTipodedocumentooarchivo" : "digitalFolderTipodedocumentooarchivoFaltante"),
    };

    gridRef.api.forEachNodeAfterFilter((row) => {
      if (!(row && row.data)) {
        datosOK = false;
      } else {
        filteredRows.push(row.data);
      }
    });

    if (!datosOK || filteredRows.length === 0) return null;

    const count = filteredRows.reduce((count, doc) => {
      count[doc["TIPO DE DOCUMENTO O ARCHIVO"]] = (count[doc["TIPO DE DOCUMENTO O ARCHIVO"]] || 0) + 1;
      return count;
    }, {})

    docData.data = count;
    return docData;
  };

  const handleCloseAnnotationsAnnexesPage = () => {
    let availableToUse = selectedAvailable ? "Y" : "N";

    if (!selectedAvailable && selectedInstance === "-") {
      setSelectedAvailable("Y");
      availableToUse = "Y";
    }

    dispatch(getDigitalFolderDataFromServer(availableToUse, selectedInstance === "-" ? null : selectedInstance))
      .then(() => setAnnexesEmployee(null));
  };

  const handleRowClick = (employeeRow) => {
    if (employeeRow.data["CODIGO DE EMPLEADO"]) {
      if (employeeRow.data["CODIGO DE EMPLEADO"] === userData.code) {
        dispatch(openSnackbarAction({ msg: `No posee acceso a la carpeta digital de ${employeeRow.data['APELLIDO Y NOMBRE']}`, severity: "warning" }));
        return;
      }
      setAnnexesEmployee(employeeRow.data);
    }
  };

  const handleAfterFileUpload = () => {
    setSelectedInstance("-");
    setSelectedAvailable("Y");

    dispatch(getDigitalFolderInstancesFromServer())
      .then(() => dispatch(getDigitalFolderDataFromServer("Y")));
  }

  const chartsItem = (gridRef) => !isLoading && data.length > 0 && selectedInstance !== "-" &&
    <CustomIconButton
      title={getLocalizedString("charts")}
      onClick={() => handleOpenGraphs(gridRef)}
      type={"graphics"}
    />

  const uploadItem = () => (
    <CustomIconButton
      title={'Carga masiva de archivos'}
      onClick={() => setUploadDialogIsOpen(true)}
      type={"upload"}
    />
  )

  const instancesItem = () => instances.length > 0 &&
    <FormControl style={{ marginLeft: 10, marginRight: 20 }}>
      <InputLabel>{getLocalizedString("digitalFolderSelectInstance")}</InputLabel>
      <Select onChange={(e) => handleInstanceChange(e.target.value)} value={selectedInstance} style={{ minWidth: 150 }}>
        <MenuItem className={"whiteText"} value={"-"}>
          {getLocalizedString("digitalFolderEveryInstance")}
        </MenuItem>
        {
          instances.filter(instance => instance.key !== "OT").map(instance => (
            <MenuItem
              className={"whiteText"}
              value={instance.key}
              key={instance.key}
            >
              {instance.value}
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>

  const showAnnexesPage = Boolean(annexesEmployee);

  return (
    <>
      {
        showAnnexesPage &&
        <AnnexesPage
          employeeCode={annexesEmployee['CODIGO DE EMPLEADO']}
          employeeName={annexesEmployee['APELLIDO Y NOMBRE']}
          handleClose={handleCloseAnnotationsAnnexesPage}
        />
      }
      <div style={{ display: showAnnexesPage ? "none" : "block" }}>
        <CommonPage
          menuItems={[instancesItem, chartsItem, uploadItem]}
          rowData={data}
          title={getLocalizedString("digitalFolderAnalysisTitle")}
          gridTitle={getLocalizedString("digitalFolderAnalysisTitle")}
          dateFormat={dateFormat}
          columnDefPage={selectedAvailable ? paths.digitalFolderAnalysis : paths.digitalFolderAnalysisNotAvailable}
          isLoading={isExportinGrid || gettingAnnexes || isLoading}
          hasExpand
          hasHelp
          hasSwitch
          handleSwitchClick={handleSwitchClick}
          switchValue={selectedAvailable}
          switchLabelOff={getLocalizedString("digitalFolderNotAvailable")}
          switchLabelOn={getLocalizedString("digitalFolderAvailable")}
          handleRowClick={handleRowClick}
          codeFieldName={'CODIGO DE EMPLEADO'}
        >
          {
            graphDialogIsOpen &&
            <GraphsDialog
              open={graphDialogIsOpen && data.length > 0}
              data={dataForCharts}
              color={color}
              theme={theme}
              handleClose={() => setGraphDialogIsOpen(false)}
            />
          }
          {
            uploadDialogIsOpen &&
            <UploadFilesDialog
              open={uploadDialogIsOpen}
              handleClose={() => setUploadDialogIsOpen(false)}
              handleAfterFileUpload={handleAfterFileUpload}
              loading={isLoading}
              instances={instances}
            />
          }
        </CommonPage>
      </div>
    </>
  );
}

export default DigitalFolderAnalysis;
