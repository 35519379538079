import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { getColumnDefByPage } from "@icarius-table/columnDefs";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { useSelector } from "react-redux";
import { getEmployees } from "../../selectors";
import { numberFormatterDecimalsCustom } from "@icarius-table/number";

const GridContent = ({ dateFormat, agGridRef }) => {
  const data = useSelector(getEmployees);

  const rowDataChanged = () => {
    //preseleccionar
    agGridRef.current.api.forEachNodeAfterFilter(node => {
      if (node.data.is_marked_for_process) {
        node.setSelected(true);
      }
    });
  }

  const getColumnDef = () => {
    // Primero me fijo si ya tengo columnas asignadas
    const columnDefs = getColumnDefByPage(paths.currentProcessesEmployees);

    (data[0]?.userFields || []).forEach((field) => {
      const hasDecimals = Object.hasOwn(field, "decimals");
      columnDefs.push({
        headerName: field.key,
        field: field.key,
        filter: "agSetColumnFilter",
        valueFormatter: (p) => hasDecimals ? numberFormatterDecimalsCustom(p, field.decimals) : null,
      });
    });

    return columnDefs;
  }

  const gridData = data.map((item) => {
    const itemData = { ...item };
    (item?.userFields || []).forEach((field) => {
      itemData[field.key] = field.value;
    })

    return itemData;
  });

  return (
    <CommonPage
      ownGridRef={agGridRef}
      gridTitle={getLocalizedString("tasks")}
      ownColumnDef={getColumnDef()}
      rowData={gridData}
      dateFormat={dateFormat}
      gridHeight={'70vh'}
      ignoreProcessesbyPath
      onRowDataChanged={rowDataChanged}
      taskControl={true}
    />
  );
}

export default React.forwardRef((props, ref) => <GridContent agGridRef={ref} {...props} />);