import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccountingEntriesAction } from "../../actions";
import { getLocalizedString } from "@icarius-localization/strings";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getAccountingEntriesData, getIsLoadingAccountingEntries } from "@icarius-pages/currentProcesses/selectors";

const useAccountingEntries = () => {

  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoadingAccountingEntries);
  const arrayData = useSelector(getAccountingEntriesData);

  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const handleOpenDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedOneRow"), severity: "warning" }));
      return;
    }

    dispatch(getAccountingEntriesAction(selectedRows[0]["Código de proceso"]))
      .then((resp) => {
        if (resp.data.status === "OK") {
          setDialogIsOpen(true);
        }
      });
  };

  const handleCloseDialog = () => {
    setDialogIsOpen(false);
  }

  const accountingEntriesData = { dialogIsOpen, arrayData, isLoading };
  const accountingEntriesFunctions = { handleOpenDialog, handleCloseDialog };

  return {
    accountingEntriesData,
    accountingEntriesFunctions,
  }
}

export default useAccountingEntries;
