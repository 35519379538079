import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import {
  getDigitalFolderAnalysisAPI,
  getDigitalFolderInstancesAPI,
  massiveUploadDigitalFolderEndpoint
} from "@icarius-connection/api";

const errorHandler = (status, error, dispatch) => {
  let errorString = "";
  switch (status) {
    case "VALIDATION_ERROR":
      if (error === "INVALID_CODES_TO_ROLE") {
        errorString = getLocalizedString("filesMustStartWithCode");
      }
      if (error === "INVALID_EXTENSION") {
        errorString = getLocalizedString("filesMustBePdf");
      }
      break;
    default:
      errorString = getLocalizedErrorString("defaultException");
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error", duration: null }));
}

export const getDigitalFolderDataFromServer = (isAvailable, instance) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_ROWS });
  try {

    let query = `?avaible=${isAvailable}` + (instance ? `&instance=${instance}` : "");
    let response = await getDigitalFolderAnalysisAPI(query);

    let rows = response.data && response.data.result;
    let dateFormat = response.data && response.data.date_format;

    dispatch({
      type: actionTypes.GET_ROWS_FULFILLED,
      payload: { rows, dateFormat }
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_ROWS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getDigitalFolderInstancesFromServer = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_INSTANCES });
  try {
    let response = await getDigitalFolderInstancesAPI();

    let instances = response.data && response.data.instances;

    dispatch({
      type: actionTypes.GET_INSTANCES_FULFILLED,
      payload: { instances }
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_INSTANCES_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const uploadDocumentsAction = (files, name, instance, type, colab_visibility, expiration) => async (dispatch) => {
  dispatch({ type: actionTypes.UPLOAD_DOCUMENT });
  try {
    let formData = new FormData();
    files.forEach(file => {
      formData.append('FILES', file);
    })
    formData.append('name', name);
    formData.append('instance', instance);
    formData.append('type', type);
    formData.append('colab_visibility', colab_visibility);
    formData.append('expiration', expiration);

    let response = await massiveUploadDigitalFolderEndpoint(formData);

    let status = response?.data?.status;
    let error = response?.data?.error;

    if (status !== "OK") {
      errorHandler(status, error, dispatch);
      dispatch({ type: actionTypes.UPLOAD_DOCUMENT_REJECTED });
      return;
    }

    dispatch({ type: actionTypes.UPLOAD_DOCUMENT_FULFILLED });
    return response;
  } catch (e) {
    if (e.response.data.error === "INVALID_CODES") {
      dispatch(openSnackbarAction({ msg: 'Hay archivos con código incorrecto', severity: "error" }));
      dispatch({
        type: actionTypes.UPLOAD_DOCUMENT_REJECTED,
      });
      return e.response;
    }

    if (e.response.data.error === "INVALID_EXTENSION") {
      dispatch(openSnackbarAction({ msg: 'Hay archivos con extensión incorrecta', severity: "error" }));
      dispatch({
        type: actionTypes.UPLOAD_DOCUMENT_REJECTED,
      });
      return e.response;
    }

    dispatch({ type: actionTypes.UPLOAD_DOCUMENT_REJECTED });
    return e.response;
  }
};