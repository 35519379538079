import React, { useState, useMemo } from "react";
import { Card, Tooltip, Grid, IconButton } from "@material-ui/core";
import { DeleteIcon } from "@icarius-icons";
import { createDateFromDDMMYYYY } from "@icarius-utils/date";
import { getLocalizedString } from "@icarius-localization/strings";
import DataContainer from "@icarius-common/abmComponents/dataContainer";
import { useDispatch, useSelector } from "react-redux";
import { getTheme } from "@icarius-pages/login/selectors";
import { getAppColor } from "src/app/selectors";
import DelegationForm from "./form";
import { deleteDelegationAction } from "../actions";
import Details from "./details";
import { openDialogAction } from "@icarius-common/dialog/actions";

const DataItem = (props) => {

  const {
    user,
    allEmployees,
    managersEmployersWithoutUser,
    data,
    closeNew,
  } = props;
  
  const { theme } = useSelector(getTheme);
  const color = useSelector(getAppColor);

  const dispatch = useDispatch();

  const isCreate = !Boolean(data);
  const [isOpen, setIsOpen] = useState(isCreate); //si viene sin data, que este abierto por defecto

  const empOrigin = useMemo(() => allEmployees && data && allEmployees.find(item => item.code === data.code_emp), [allEmployees, data]);
  const empDest = useMemo(() => allEmployees && data && allEmployees.find(item => item.code === data.delegation_code), [allEmployees, data]);

  const getIsCompleted = () => {
    if (isCreate) return false;
    const endDate = createDateFromDDMMYYYY(data.end_date);
    const today = new Date();
    return today > endDate;
  }

  const handleToggleOpenClose = () => {
    Boolean(data) && setIsOpen(prev => !prev);
  }

  const getBackgroundColor = () => {
    if (isCreate) return color;
    if (getIsCompleted()) return theme === "dark" ? "grey" : "#B1B1B1";
    return "";
  }

  const handleItemClick = () => {
    if (isCreate) return;
    if (user === data?.code_emp) {
      handleToggleOpenClose();
    }
  }

  const handleDeletePublication = () => {
    if (isCreate) {
      closeNew();
    } else {
      dispatch(deleteDelegationAction(data.code));
    }
  }

  const handleOpenDeleteDialog = () => {
    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: getLocalizedString("confirmDeleteDelegation"),
      onConfirm: handleDeletePublication,
    }));
  }

  if (!isCreate && !empOrigin && !empDest) return null;

  return (
    <>
      <Card style={{ width: "100%", borderRadius: 10, background: getBackgroundColor(), color: isCreate && 'white' }}>
        <Grid container item xs={12} direction="row" style={{ padding: "10px 20px" }}>
          <Grid container item xs={11} style={{ cursor: Boolean(data) ? "pointer" : "" }} onClick={handleItemClick}>
            {
              isCreate ?
                <DataContainer lg={12} sm={12} md={12} text={"Creación de delegación"} />
                :
                <>
                  <DataContainer sm={12} md={3} lg={3} text={data.start_date + " - " + data.end_date} />
                  <DataContainer sm={12} md={6} lg={7} text={empOrigin.name + " delega atenciones a " + empDest.name} />
                  <DataContainer sm={12} md={3} lg={2} text={user === data.code_emp ? getLocalizedString("delegationSent") : getLocalizedString("delegationReceived")} />
                </>
            }
          </Grid>
          {
            ((!isCreate && data?.delete) || isCreate) &&
            <Grid container direction="row" item xs={1} justify="center">
              <Tooltip title={getLocalizedString("delete")}>
                <IconButton size={"small"} disableRipple onClick={handleOpenDeleteDialog}>
                  <DeleteIcon style={{ fill: "var(--icons)" }} fontSize="small" />
                </IconButton>
              </Tooltip>
            </Grid>
          }
        </Grid>
      </Card>
      {
        isOpen && isCreate &&
        <DelegationForm
          managersEmployers={managersEmployersWithoutUser}
          handleClose={isCreate ? closeNew : handleToggleOpenClose}
        />
      }
      {
        isOpen && !isCreate &&
        <Details
          data={data}
          empDest={empDest}
          empOrigin={empOrigin}
        />
      }
    </>
  );
}

export default DataItem;
