import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { CloseIcon } from "@icarius-icons";
import { Dialog, DialogTitle, DialogContent, Grid, DialogActions, MenuItem, Select, InputLabel, FormControl } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { addFileForPositionForDigitalFolderAction, updateFileForPositionForDigitalFolderAction } from "../../actions";
import DialogTransition from "@icarius-common/dialogTransition";

const CreateEditDialog = (props) => {

  const { open, isEdit, cargos, tipos, instances, document, handleClose } = props;

  const [ positionSelected, setPositionSelected ] = useState(isEdit ? document.keyCargo : "");
  const [ instanceSelected, setInstanceSelected ] = useState(isEdit ? document.keyInstanciaLaboral : "");
  const [ documentSelected, setDocumentSelected ] = useState(isEdit ? document.keyTipoDocumento : "");

  const dispatch = useDispatch();

  const documentArray = useMemo(() => { //armado del array de documentos segun la instancia seleccionada
    if(instanceSelected === "") return [];
    return tipos.find(type => type.key === instanceSelected).values;
  }, [tipos, instanceSelected])

  const dataIsValid = () => {
    if(positionSelected === "" || instanceSelected === "" || documentSelected === "") return false;
    return true;
  }

  const handleSubmit = () => {
    if(!dataIsValid()) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("digitalFolderValidation"), severity: "error" }));
      return;
    }

    let data = {
      position: positionSelected,
      laborInstance: instanceSelected,
      documentType: documentSelected,
    };

    if(isEdit) data.code = document.code;

    const action = isEdit ? updateFileForPositionForDigitalFolderAction : addFileForPositionForDigitalFolderAction;
    dispatch(action(data));
    handleClose();
  } 

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      fullWidth
      maxWidth={"sm"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {getLocalizedString(isEdit ? "editDocument": "createNewDocument")}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <Grid container justify="flex-start" alignItems="center" item xs={12}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel id="documentLabel" required={true}>{getLocalizedString("documentPosition")}</InputLabel>
            <Select
              fullWidth              
              labelId="documentLabel"
              value={positionSelected}
              margin={"none"}
              onChange={(e) => setPositionSelected(e.target.value)}
            >
            {
              cargos.map(position => {
                if(position.Descr === "") return null; //ocultar el item que viene sin descripcion (ya que no se puede mandar ese)
                return <MenuItem
                  className={"whiteText"}
                  key={position.FldValue}
                  value={position.FldValue}>
                  {position.Descr}
                </MenuItem>
              })
            }
            </Select>
          </FormControl>
        </Grid>
        <Grid container justify="flex-start" alignItems="center" item xs={12} style={{ paddingTop: 20 }}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel id="instanceLabel" required={true}>{getLocalizedString("documentInstance")}</InputLabel>
            <Select
              fullWidth 
              labelId="instanceLabel"
              label={getLocalizedString("documentInstance")}
              value={instanceSelected}
              margin={"none"}
              onChange={(e) => {
                setInstanceSelected(e.target.value);
                setDocumentSelected("")
              }}
            >
            {
              instances.map(instance => (
                <MenuItem
                  className={"whiteText"}
                  key={instance.FldValue}
                  value={instance.FldValue}>
                  {instance.Descr}
                </MenuItem>
              ))
            }
            </Select>
          </FormControl>
        </Grid>
        <Grid container justify="flex-start" alignItems="center" item xs={12} style={{ paddingTop: 20 }}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel id="mandatoryLabel" required={true}>{getLocalizedString("documentMandatory")}</InputLabel>
            <Select
              disabled={instanceSelected === ""}
              fullWidth
              labelId="mandatoryLabel"
              label={getLocalizedString("documentMandatory")}
              value={documentSelected}
              margin={"none"}
              onChange={(e) => setDocumentSelected(e.target.value)}
            >
            {
              documentArray.map(doc => (
                <MenuItem
                  className={"whiteText"}
                  key={doc.FldValue}
                  value={doc.FldValue}>
                  {doc.Descr}
                </MenuItem>
              ))
            }
            </Select>
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />   
        <ButtonDialogAction type="submit" onClick={handleSubmit} isAccept text={getLocalizedString(isEdit ? "save" : "create")} />             
      </DialogActions>
    </Dialog>
  )
}

export default CreateEditDialog;
